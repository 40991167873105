import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {
	// CardActionArea,
	CardActions,
	Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChangeCharacter from '../modals/ChangeCharacter';
import DeleteFolder from '../modals/DeleteFolder';
import { Draggable } from 'react-beautiful-dnd';
import placeholder from '../../assets/images/placeholder.png';
import * as constants from '../../helpers/constants';
import { Box } from '@mui/material';
import orangeFolder from '../../assets/images/orange-folder.svg'

export default function ComicFolderCard({
	folder,
	folderId,
	epizodeIndex,
	onCardHandleClick,
	index,
	setCardsToRenderFunc,
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	// const [changeCharacterOpen, setChangeCharacterOpen] = useState(false);
	const [deleteFolderOpen, setDeleteFolderOpen] = useState(false);
	const [cardChangeModalFlag, setCardChangeModalFlag] = useState(false);
	const open = Boolean(anchorEl);
	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setCardChangeModalFlag((current) => !current);
	}, [folder]);

	let draggableId;
	if (epizodeIndex) {
		draggableId = epizodeIndex * 10 + folder?.id;
	} else {
		draggableId = folder?.id;
	}
	return (
		<Draggable
			draggableId={'dragable-' + index}
			index={index}
			key={draggableId}
		>
			{(provided) => (
				<Card
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					sx={{ p: 0, width: '140px', background: 'none', boxShadow: 'none'}}
				>
					<div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						{/* {cardChangeModalFlag && (
							<ChangeCharacter
								setCardsToRenderFunc={setCardsToRenderFunc}
								open={changeCharacterOpen}
								setOpen={setChangeCharacterOpen}
								character={character}
								characterId={characterId}
								epizodeIndex={epizodeIndex}
							/>
						)}
						{!cardChangeModalFlag && (
							<ChangeCharacter
								setCardsToRenderFunc={setCardsToRenderFunc}
								open={changeCharacterOpen}
								setOpen={setChangeCharacterOpen}
								character={character}
								characterId={characterId}
								epizodeIndex={epizodeIndex}
							/>
						)} */}
						<DeleteFolder
							setCardsToRenderFunc={setCardsToRenderFunc}
							open={deleteFolderOpen}
							setOpen={setDeleteFolderOpen}
							folder={folder}
							epizodeIndex={epizodeIndex}
						/> 

						{/* <CardActionArea> */}
						
						<CardMedia
							component='img'
							sx={{
								height: 100,
								width: 100,
							}}
							image={orangeFolder}
							alt={'Orange folder'}
							onClick={() => onCardHandleClick()}
							onError={(e) => {
								e.target.src = placeholder;
							}}
						/>
					

						<Menu
							anchorEl={anchorEl}
							id='account-menu'
							open={open}
							onClose={handleClose}
							onClick={handleClose}
							transformOrigin={{
								horizontal: 'right',
								vertical: 'top',
							}}
							anchorOrigin={{
								horizontal: 'right',
								vertical: 'bottom',
							}}
						>
							<MenuItem
								// onClick={() => setChangeCharacterOpen(true)}
							>
								<Typography textAlign='center'>
									Change title
								</Typography>
							</MenuItem>
							<MenuItem
								onClick={() => setDeleteFolderOpen(true)}
							>
								<Typography textAlign='center'>
									Delete
								</Typography>
							</MenuItem>
						</Menu>

						<CardActions sx={{marginTop: '-12px'}}>
							<Typography
								//gutterBottom
								variant='h5'
								component='div'
								onClick={() => onCardHandleClick()}
								sx={{
									textAlign: 'left',
									width: '100%',
									fontSize: '16px',
									paddingTop: '0px',
									paddingLeft: "2px",
									maxWidth: 'calc(100% - 18px)',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
								}}
							>
								{folder?.title}
							</Typography>
						</CardActions>
						{/* </CardActionArea> */}
						<Tooltip sx={{marginTop: '-10px'}}>
							<IconButton
								onClick={handleOpen}
								size='small'
								sx={{
									ml: 2,
									position: 'absolute',
									right: '0px',
									bottom: '0px',
								}}
								aria-controls={
									open ? 'account-menu' : undefined
								}
								aria-haspopup='true'
								aria-expanded={open ? 'true' : undefined}
							>
								<Avatar
									sx={{
										width: 24,
										height: 24,
										bgcolor: 'transparent',
									}}
								>
									<MoreVertIcon style={{ color: '#fff' }} />
								</Avatar>
							</IconButton>
						</Tooltip>
					</div>
				</Card>
			)}
		</Draggable>
	);
}
