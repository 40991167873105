export const DARK_GREY = '#2F3944'
export const ORANGE = '#FF6633'
export const TEAL = '#11D3AF'
export const DARK_TEAL = '#26BDA1'
export const PURPLE = '#6637C4'
export const PRIMARY = '#11D3AF'
export const PRIMARY_DARK = '#26BDA1'
export const SECONDARY = '#6637C4'
export const TEXT = '#FFFFFF'
export const EMAIL_REGEXP = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BASE_WS_URL = process.env.REACT_APP_BASE_WS_URL
export const IMAGE_URL = process.env.REACT_APP_BASE_URL + 'api/projects/imagery/'
export const PROMPTLIST = ['Comedy', 'Drama', 'Dark Comedy / Dramedy', 'Procedural or True Crime', 'Episodic show', 'Traditional Three-Act','Horror / Thriller','Podcast', 'Sitcom', 'Nolan-style Non-linear', 'Cameron-style Epic', 'Roadtrip', 'Documentary', 'TV Movie', 'Custom', 'Lindauer: Army of Dread']
export const IMAGESTYLESARRAY = ['Anamorphic Cinema Film Lens','Comic Book Illustration', 'Neal Adams Illustration', 'Steve Ditko Illustration', 'Jack Kirby Illustration', 'Storyboard Sketch, Black & White, Less Detail', 'Classic Disney Animation', 'Pixar Animation', '1977 Star Wars / 1979 Alien', 'Cyberpunk meets Dune', 'Retro Sci-Fi Pulp Novel', 'The Atomic Age', '1980s Cartoons', 'Modern Anime', 'Fantasy Novel Cover Art by Darrel K Sweet', 'Charcoal Sketch','Black & White Analog Film Camera','Candid Instagram Influencer-style Photo', 'Pixel Art','Character Sheet with various full-body positions and angles','Custom']