import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {
	// CardActionArea,
	CardActions,
	Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import DeletePage from '../modals/DeletePage';
import { Draggable } from 'react-beautiful-dnd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChangePage from '../modals/ChangePage';
import * as constants from '../../helpers/constants';
import { Box } from '@mui/material';

export default function ComicPageCard({
	image,
	page,
	imageIndex,
	epizodeIndex,
	onCardHandleClick,
	index,
	setCardsToRenderFunc,
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [changePageOpen, setChangePageOpen] = useState(false);
	const [deletePageOpen, setDeletePageOpen] = useState(false);
	const [cardChangeModalFlag, setCardChangeModalFlag] = useState(false);
	const open = Boolean(anchorEl);
	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setCardChangeModalFlag(!cardChangeModalFlag);
	}, [page]);

	let draggableId;
	if (epizodeIndex) {
		draggableId = epizodeIndex * 10 + page?.id;
	} else {
		draggableId = page?.id;
	}
	return (
		<Draggable draggableId={'' + index} index={index} key={draggableId}>
			{(provided) => (
				<Card
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					sx={{ p: 0, maxWidth: '140px' }}
				>
					<div style={{ position: 'relative' }}>
						<DeletePage
							page={page}
							setCardsToRenderFunc={setCardsToRenderFunc}
							open={deletePageOpen}
							setOpen={setDeletePageOpen}
							imageIndex={imageIndex}
							epizodeIndex={epizodeIndex}
						/>
						{cardChangeModalFlag && (
							<ChangePage
								page={page}
								setCardsToRenderFunc={setCardsToRenderFunc}
								open={changePageOpen}
								setOpen={setChangePageOpen}
								imageIndex={imageIndex}
								epizodeIndex={epizodeIndex}
								setCardChangeModalFlag={() => setCardChangeModalFlag(prev => !prev)}
							/>
						)}
						{!cardChangeModalFlag && (
							<ChangePage
								page={page}
								setCardsToRenderFunc={setCardsToRenderFunc}
								open={changePageOpen}
								setOpen={setChangePageOpen}
								imageIndex={imageIndex}
								epizodeIndex={epizodeIndex}
								setCardChangeModalFlag={() => setCardChangeModalFlag(prev => !prev)}
							/>
						)}
						{/* <CardActionArea> */}
						{(page?.image !== 'default') && (
							<Box
							onClick={onCardHandleClick}
							sx={(theme) => ({
								position: 'relative',
								height: 140,
								width: 140,
								background: `linear-gradient(to bottom left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
								cursor: 'pointer',
								overflow: 'hidden',
							})}
						>
							<CardMedia
								component='div'
								sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								zIndex: 2,
								}}
								image={
									constants.IMAGE_URL +
									image +
									'?time=' +
									page?.lastImageUpdate
								}
								alt='#'
								onClick={() => onCardHandleClick()}
							/>
							<Typography
								sx={{
									position: 'absolute',
									top: '30%',
									//left: '35%',
									left: '0',
									right: '0',
									textAlign: 'center',
									color: 'black',
									fontSize: '40px',
									textTransform: 'uppercase',
									color: 'white',
									zIndex: 1,
								}}
							>
									{page?.title?.split(/\s/).slice(0, 3).map(word => word[0]).join('')}
							</Typography>
						</Box>
						
						)}
						{(page?.image === 'default') && (
							<Box onClick={() => onCardHandleClick()} sx={{
								position: 'relative',
							}}>
								<CardMedia
									component='div'
									sx={(theme) => ({
										height: 140,
										width: 140,
										background: `linear-gradient(to bottom left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
									})}
									alt={page?.alt}
									onClick={() => onCardHandleClick()}
								
								/>	
								<Typography
									sx={{
										position: 'absolute',
										top: '30%',
										//left: '35%',
										left: '0',
										right: '0',
										textAlign: 'center',
										color: 'black',
										fontSize: '40px',
										textTransform: 'uppercase',
										color: 'white'
									}}
								>
										{page?.title?.split(/\s/).slice(0, 3).map(word => word[0]).join('')}
								</Typography>
							</Box>
						)}

						<CardActions>
							<Tooltip
								title={page?.title || `Page ${imageIndex + 1}`}
								placement='bottom-start'
							>
								<Typography
									//gutterBottom
									variant='h5'
									component='div'
									onClick={() => onCardHandleClick()}
									sx={{
										textAlign: 'left',
										width: '100%',
										fontSize: '16px',
										paddingTop: '0px',
										paddingLeft: "2px",
										maxWidth: 'calc(100% - 18px)',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}
								>
									{page?.title || `Page ${imageIndex + 1}`}
								</Typography>
							</Tooltip>
						</CardActions>
						<Menu
							anchorEl={anchorEl}
							id='account-menu'
							open={open}
							onClose={handleClose}
							onClick={handleClose}
							transformOrigin={{
								horizontal: 'right',
								vertical: 'top',
							}}
							anchorOrigin={{
								horizontal: 'right',
								vertical: 'bottom',
							}}
						>
							<MenuItem onClick={() => setChangePageOpen(true)}>
								<Typography textAlign='center'>Edit</Typography>
							</MenuItem>
							<MenuItem onClick={() => setDeletePageOpen(true)}>
								<Typography textAlign='center'>
									Delete
								</Typography>
							</MenuItem>
						</Menu>
						{/* </CardActionArea> */}
						<Tooltip>
							<IconButton
								onClick={handleOpen}
								size='small'
								sx={{
									ml: 2,
									position: 'absolute',
									right: '0px',
									bottom: '0px',
								}}
								aria-controls={
									open ? 'account-menu' : undefined
								}
								aria-haspopup='true'
								aria-expanded={open ? 'true' : undefined}
							>
								<Avatar
									sx={{
										width: 24,
										height: 24,
										bgcolor: 'transparent',
									}}
								>
									<MoreVertIcon style={{ color: '#fff' }} />
								</Avatar>
							</IconButton>
						</Tooltip>
					</div>
				</Card>
			)}
		</Draggable>
	);
}
