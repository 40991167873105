import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert'
import axios from 'axios';
import { addLoadingFlag, removeLoadingFlag } from '../../../store/slices/loadingSlice';
import * as constants from '../../../helpers/constants'
import { logout } from '../../../store/slices/authSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '100%',
    sm: '400px'
  },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddNewEpisode({open,setOpen,setEpisodesToRender}) {
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const alert = useAlert();
  const navigate = useNavigate()
  const token = useSelector((state) => state.auth.token);
  const projectId = () => location.pathname.slice(9,location.pathname.length) ;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    if (data.get('title')) {
      dispatch(addLoadingFlag('set-episode'))
      await axios.post(constants.BASE_URL + 'api/projects/set-episode/' + projectId(), {
        title: data.get('title'),
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      } )
      .then(function (response) {
        dispatch(removeLoadingFlag('set-episode'))
        setEpisodesToRender()
      })
      .catch(function (error) {
        dispatch(removeLoadingFlag('set-episode'))
        if (error.response?.data === 'Non existing user.') {
          navigate('login')
          dispatch(logout())
        }
      });
      handleClose()
      alert.show('New episode was successfully added!',{type: 'success'})
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={style}>
        <Typography sx={{ m: 0, p: 2 }}>
          ADD NEW EPISODE
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
          <TextField
            margin="normal"
            required
            fullWidth
            name="title"
            label="Enter episode title"
            id="title"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, color: '#fff' }}
          >
            Create
          </Button>
        </Box>
      </Modal>
    </div>
  );
}