import React from 'react'
import { JellyfishSpinner } from 'react-spinners-kit'
import CssBaseline from '@mui/material/CssBaseline';
import * as constants from '../../helpers/constants'
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

export default function ModalLoader(loading) {
  const theme = useTheme();

  return (
    <>
    <CssBaseline/>
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: 'calc(100% - 80px)',
        width: 'calc(100% - 16px)',
        position:'fixed',
        left: '8px',
        top: '70px',
        zIndex: '99999',
        background: '#121212'}}>
        <JellyfishSpinner size={200} color={theme.palette.primary.main} loading={loading} />
      </div>
    </>
  )
}
