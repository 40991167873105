import React, { useEffect, useRef, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, TextField, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useSelector ,
   useDispatch
   } from 'react-redux';
import axios from 'axios';
import * as constants from '../../helpers/constants'
import Loader from '../../components/Loader';
import Button from '@mui/material/Button';
import { addLoadingFlag, removeLoadingFlag } from '../../store/slices/loadingSlice';
import { useAlert } from 'react-alert';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout, setTokens } from '../../store/slices/authSlice';
import CardMedia from '@mui/material/CardMedia';
import imageCompression from 'browser-image-compression';
import CircularProgress from '@mui/material/CircularProgress';
import TabChangeModal from '../../components/modals/TabChangeModal';
import StopIcon from '@mui/icons-material/Stop';
import ArrowLeft from '@mui/icons-material/ArrowCircleLeft';
import AutoFillTokensModal from '../../components/modals/AutoFillTokensModal';

const fieldContainerStyles = {
	display: 'flex',
  gap: '30px',
  width: '100%'
};

const ProjectInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loading);
  const projectId = location.pathname.slice(14, location.pathname.length);
  const alert = useAlert();
  const [project, setProject] = useState()
  const [imageFile, setImageFile] = useState();
	const [imageInput, setImageInput] = useState();
	const [titleInput, setTitleInput] = useState();
  const [description,setDescription] = useState();
  const [tagline, setTagline] = useState();
  const [descriptionAdjust, setDescriptionAdjust] = useState();
  const [rules, setRules] = useState();
	const [wasImageChanged, setWasImageChanged] = useState(false);
  const doesCardHaveUnsavedData = useRef(false)
  const [openModal, setOpenModal] = useState(false)
  const [openPageModal, setOpenPageModal] = useState(false)
  const currentSocket = useRef()
  const formRef = useRef()
  const currentWebSocketObject = useRef();
	const currentGenerationSetFunc = useRef()
	const [currentGenerationFieldKey,setCurrentGenerationFieldKey] = useState()
	const [isGenerationButtonAllowed, setIsGenerationButtonAllowed] = useState(true);
  const [generationLoading, setGenerationLoading] = useState(false);
  const [tokensFillModal, setTokensFillModal] = useState(false);
  const hideTokensFillModal = useSelector((state) => state.auth.hideTokensFillModal);

  useEffect(()=>{
    getProject()
  },[projectId]) 

  function checkIfCardHasUnsavedData () {
		if (!project) return

		let isEqualFlag = true

		if (titleInput !== project.title) isEqualFlag = false;
		if (description !== project.description) isEqualFlag = false;
		if (tagline !== project.tagline) isEqualFlag = false;
		if (descriptionAdjust !== project.descriptionAdjust) isEqualFlag = false;
		if (rules !== project.rules) isEqualFlag = false;
		if (imageFile || imageInput) isEqualFlag = false;

    console.log(isEqualFlag)
		if (isEqualFlag) {
			doesCardHaveUnsavedData.current = false
		} else {
			doesCardHaveUnsavedData.current = true
		}
	}

	checkIfCardHasUnsavedData()

  useEffect( () => {
		webSocketConnect();
	
		return () => {
			if (currentWebSocketObject.current) {
				currentWebSocketObject.current?.close();
				currentSocket.current = null;
				currentWebSocketObject.current = null
			}
		
		};
	 }, []); 

	 async function webSocketConnect () {
		return new Promise((resolve, reject) => {
			const socket = new WebSocket(constants.BASE_WS_URL); // WebSocket server address
			socket.onopen = () => {
				console.log('WebSocket connection established');
			};

			socket.onmessage = (event) => {
				console.log('Received message:', event.data);
				let data = event.data
				try {
					data = JSON.parse(event.data); // Parse the JSON string into an object
				} catch (error) {
					console.error('Error parsing JSON:', error);
				}
				console.log('Received message obj:', data);

				if (data?.type === 'clientId') {
					console.log('clientID set')
					currentSocket.current = data.clientId
          currentWebSocketObject.current= socket
					resolve(socket)
				}

				if (data?.type === 'content') {
					console.log('got new images')
					currentGenerationSetFunc.current(prev => prev + data.content)
				}
				if (data?.type === 'message' && data?.message === 'first generation') {
          setGenerationLoading(false)
          currentGenerationSetFunc.current('')
				}
				if (data?.type === 'message' && data?.message === 'last generation') {
					alert.show(`Text was successfully generated`, {
						type: 'success',
					});
          setGenerationLoading(false)
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
          getUserTokens()
				}
			
            // Handle incoming messages from the WebSocket server
			};

			socket.onerror = (error) => {
				console.error('WebSocket error:', error);
				// Handle WebSocket connection errors
			};

			socket.onclose = () => {
				console.log('WebSocket connection closed');
				currentSocket.current = null
				setIsGenerationButtonAllowed(true)
				setCurrentGenerationFieldKey('');
				// Handle WebSocket connection closure
			};

		});
	}

  const getUserTokens = () => {
		axios
		.get(
		  constants.BASE_URL + 'api/auth/get-user-info',
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  console.log(response.data)
		  dispatch(setTokens(response.data?.tokens))
		}).catch(function (error) {
		  console.log(error);
		});
	  }

  const getProject = () => {
    axios
    .get(constants.BASE_URL + 'api/projects/' + projectId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async function (response) {
      setProject(response.data)
      setTitleInput(response.data.title)
      setDescription(response.data.description)
      setTagline(response.data.tagline)
      setRules(response.data.rules)
      setDescriptionAdjust(response.data.descriptionAdjust)
      console.log(response.data)
    })
    .catch(function (error) {
      if (error.response?.data === 'User Does Not Exist') {
        navigate('login');
        dispatch(logout());
      }
      if (error.response?.status === 404) {
        navigate('gallery');
      }
    });
  }

  const handleGenerateFieldValue = async (fieldToFillKey, setFieldFunc, adjustFlag) => {
    if (!currentSocket.current) {
			try {
				const socket = await webSocketConnect();
				if (socket.readyState === WebSocket.OPEN) {
					generationFunction();
				}
			} catch (error) {
				console.error('Error connecting to WebSocket:', error);
			}
		} else {
			generationFunction();
		}
    
    async function generationFunction () {
      const fields = {
        title: titleInput,
        'description/treatment': description
        }

      const prevValue = fields[fieldToFillKey]

      delete fields[fieldToFillKey]

      let isAnotherFieldsNotEmpty = true

      for (const [key, value] of Object.entries(fields)) {
        if (typeof value !== 'undefined' && value.trim() !== '') {
          isAnotherFieldsNotEmpty = false
        } else {
          delete fields[key]
        }
      }

      if (isAnotherFieldsNotEmpty) {
        alert.show(`To generate this field you should fill ${Object.keys(fields)[0]} field`, {
          type: 'error',
        });
      } else {
        setIsGenerationButtonAllowed(false)
        setGenerationLoading(true)
        const data = {
          fieldToFillKey,
          supportFieldsObj : fields,
          clientId: currentSocket.current
        }
        if (prevValue) {
          data.prevValue = prevValue
        }
        if (adjustFlag) {
          data.descriptionAdjust = descriptionAdjust
        }
        console.log(data)
        currentGenerationSetFunc.current = setFieldFunc;
			  setCurrentGenerationFieldKey(fieldToFillKey);
        axios
        .post(
          constants.BASE_URL + 'api/ai/generate-project-field',
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          // setFieldFunc(response.data)
          // dispatch(removeLoadingFlag('generate-field'));
          // console.log(response.data);
          // alert.show(
          //   `Text was successfully generated`,
          //   { type: 'success' }
          // );
        })
        .catch(function (error) {
          if (error.response?.data === 'error with openAi') {
						alert.show(
						  `Something went wrong with AI server`,
						  { type: 'error' }
						);
					}  else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
            if (localStorage.getItem('role')=== 'corporate user') {
              alert.show(
                `Something wrong with subscription, please note your corporate admin`,
                { type: 'error' }
              );
            } else {
              alert.show(
                `Subsctiption is required for generation`,
                { type: 'error' }
              );
            }
						
          } else if (error.response?.data === 'Not enought tokens') {
            if (localStorage.getItem('role')=== 'corporate user') {
              alert.show(
                `Something wrong with token count, please note your corporate admin`,
                { type: 'error' }
              );
            } else {
              if (!hideTokensFillModal){
                setTokensFillModal(true)
                }
              alert.show(
                `You don't have enough tokens to perform this action`,
                { type: 'error' }
              );
            }
					
					} else {
						alert.show(
							`Something went wrong with text generation`,
							{ type: 'error' }
						);
						console.log(error);
					}
          setGenerationLoading(false)
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
        });
      }
    }
  }
  const handleGenerateTagValue = async () => {
    if (!currentSocket.current) {
			try {
				const socket = await webSocketConnect();
				if (socket.readyState === WebSocket.OPEN) {
					generationFunction();
				}
			} catch (error) {
				console.error('Error connecting to WebSocket:', error);
			}
		} else {
			generationFunction();
		}
    
    async function generationFunction () {
      const data = {
        clientId: currentSocket.current
      }

      if (description?.length !== 0) data.description = description
      if (titleInput?.length !== 0) data.title = titleInput

      console.log(data)

      if (description?.length === 0 || titleInput?.length === 0) {
        alert.show(`To generate this field you should fill at least one of the other fields`, {
          type: 'error',
        });
      } else {
        setIsGenerationButtonAllowed(false)
        currentGenerationSetFunc.current = setTagline;
			  setCurrentGenerationFieldKey('tagline');
        setGenerationLoading(true)
        axios
        .post(
          constants.BASE_URL + 'api/ai/generate-project-tagline',
          {data},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          // setTagline(response.data)
          // dispatch(removeLoadingFlag('generate-field'));
          // alert.show(
          //   `Text was successfully generated`,
          //   { type: 'success' }
          // );
        })
        .catch(function (error) {
          if (error.response?.data === 'error with openAi') {
						alert.show(
						  `Something went wrong with AI server`,
						  { type: 'error' }
						);
					} else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
            if (localStorage.getItem('role')=== 'corporate user') {
              alert.show(
                `Something wrong with subscription, please note your corporate admin`,
                { type: 'error' }
              );
            } else {
              alert.show(
                `Subsctiption is required for generation`,
                { type: 'error' }
              );
            }
						
          } else if (error.response?.data === 'Not enought tokens') {
            if (localStorage.getItem('role')=== 'corporate user') {
              alert.show(
                `Something wrong with token count, please note your corporate admin`,
                { type: 'error' }
              );
            } else {
              if (!hideTokensFillModal){
                setTokensFillModal(true)
                }
              alert.show(
                `You don't have enough tokens to perform this action`,
                { type: 'error' }
              );
            }
					
					} else {
						alert.show(
							`Something went wrong with text generation`,
							{ type: 'error' }
						);
						console.log(error);
					}
          setGenerationLoading(false)
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
        });
      }
    }
  }
  const handleGenerateSettingsAndCharacters = () => {
    if (!loading && !generationLoading) {
      dispatch(addLoadingFlag('quick-start'));
      axios
        .post(
          constants.BASE_URL + 'api/projects/create-settings-characters',
          {text: description, project_id: projectId},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          getUserTokens()
          dispatch(removeLoadingFlag('quick-start'));
          console.log(response.data)
          navigate('/project/' + projectId + '?page=characters&episodes=0');
        })
        .catch(function (error) {
          if (error.response?.data === 'Text incorrect for quick start') {
            alert.show(
              `Please make sure that text in Treatment/Summary field contain information about characters and settings`,
              { type: 'error' }
            );
          } else if (error.response?.data === 'error with openAi') {
            alert.show(
              `Something went wrong with AI server`,
              { type: 'error' }
            );
          } else {
            alert.show(
              `Something went wrong with text generation`,
              { type: 'error' }
            );
          }
          dispatch(removeLoadingFlag('quick-start'));
        });
    }
    }
    
  const handleFileChange = (event) => {
		console.log(event);
		const file = event.target.files[0];
		console.log(file);
		if (file) {
			if (file.size > 1024 * 1024 * 4) {
				console.log('error');
				setImageFile('');
				setImageInput('');
				// 4MB in bytes
				alert.show(`Image is too large. Maximum allowed size is 4MB.`, {
					type: 'error',
				});
			} else {
				// Proceed with image upload
				setImageFile(event.target.value);
				setImageInput('');
				alert.show(`Image was successfully uploaded`, {
					type: 'success',
				});
			}
		}
	};

  const handleSaveChanges = async (e) => {
    if (loading || generationLoading) {
      return
    }

		// e.preventDefault();
		const data =  new FormData(e)
		if (data.get('image')?.size === 0) data.delete('image');
		if (
			!wasImageChanged ||
			constants.IMAGE_URL + project?.image === data.get('imageURL')
		) {
      data.delete('imageURL');
    }
		
      
		if (data.get('title')) {
			if (await checkImageSize(imageInput)) {
				dispatch(addLoadingFlag('change-projects'));
				if (data.get('image')) {
					const file = data.get('image')
					data.delete('image');
					try {
						const options = {
							maxSizeMB: 0.90
						}
						const compressedFile = await imageCompression(file, options);
						data.append('image',compressedFile)
						await request()

					} catch (error) {
						alert.show(
							`Something went wrong with image compression.`,
							{ type: 'error' }
						);
					}
				} else {
					await request()
				}
				
			}
		}
		async function request () {
			await axios
					.put(
						constants.BASE_URL + 'api/projects/' + projectId,
						data,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(async function (response) {
						alert.show(
							`Project '${
								titleInput 
							}' was successfully changed!`,
							{ type: 'success' }
						);
            getProject()
						dispatch(removeLoadingFlag('change-projects'));
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('change-projects'));
						if (error.response?.data === 'Non existing user.') {
							navigate('login');
							dispatch(logout());
						}
					
							alert.show(
								`Something went wrong, could not add illustrations.`,
								{ type: 'error' }
							);
						
					});
		}
	};
  
	function sendMessageToServer(message) {
		console.log('ready state',currentWebSocketObject.current)
		if (currentWebSocketObject.current.readyState === WebSocket.OPEN) {
			currentWebSocketObject.current.send(message);
			console.log('Message sent to server:', message);
		} else {
			console.error('WebSocket connection is not open or established.');
		}
	}

	function stopGeneration () {
		sendMessageToServer('stop generation')
	}


  const checkImageSize = (url) => {
		return fetch(url)
			.then((response) => {
				if (response.headers.get('content-length') > 1024 * 1024 * 4) {
					alert.show(
						`Image is too large. Maximum allowed size is 4MB.`,
						{
							type: 'error',
						}
					);
					setImageFile('');
					setImageInput('');
					return false;
				} else {
					// Proceed with using the image
					setImageFile('');
					setImageInput(url);

					return true;
				}
			})
			.catch((error) => {
				alert.show(
					`Something wrong with image`,
					{ type: 'error' }
				);
				console.error('Error fetching the image:', error);
				return false;
			});
	};

    if (loading) {
      return <Loader />
    }
    return (
        <Container component="main" maxWidth='lg'>
          <CssBaseline />
          <TabChangeModal submitText={'SAVE AND BUILD'} open={openModal} setOpen={setOpenModal} onSubmit={async() => {
              await handleSaveChanges(formRef.current)
              handleGenerateSettingsAndCharacters()
          }}/>
          <AutoFillTokensModal open={tokensFillModal} setOpen={setTokensFillModal}/>
          <TabChangeModal open={openPageModal} setOpen={setOpenPageModal} onSubmit={() => navigate('/project/' + projectId + '?page=characters&episodes=0')}/>
          {!loading && <>
             
              <Box
              ref={formRef}
                sx={{
                  marginTop: {
                    xs: 8,
                    md: 8,
                  },
                  marginBottom: 1,
                  display: {
                    xs: 'flex',
                  },
                  alignItems: 'center',
                  justifyContent: {
                    md: 'space-between',
                    xs: 'space-around',
                  },
                  flexDirection: 'column',
                  flexWrap: {
                    xs: 'wrap',
                  },
                  gridTemplateColumns: '1fr 1fr',
                  gap: '30px 0',
                  position: 'relative',
                  paddingBottom: '100px',
                }}
                component='form' submit={()=> console.log('submit not active')}noValidate
              >
              
              {project && 
                <Box
                onClick={() => {
                  if (doesCardHaveUnsavedData.current) {
                    setOpenPageModal(true)
                  } else {
                    navigate('/project/' + projectId + '?page=characters&episodes=0')
                  }
                }}
                  sx={{
                    position: 'relative',
                    height: 400,
                    width: 300,
                    background: 'linear-gradient(to bottom left,  #11D3AF, #6637C4)',
                    cursor: 'pointer',
                    overflow: 'hidden',
                  }}
                >
                  <CardMedia
                    component='div'
                    sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: 2,
                    }}
                    image={constants.IMAGE_URL + project.image}
                    alt={'Project avatar'}
                    onClick={() => {
                      if (doesCardHaveUnsavedData.current) {
                        setOpenPageModal(true)
                      } else {
                        navigate('/project/' + projectId + '?page=characters&episodes=0')
                      }
                    }}
                  />
                  <Typography
                    sx={{
                      position: 'absolute',
                      top: '40%',
                      //left: '35%',
                      left: '0',
                      right: '0',
                      textAlign: 'center',
                      color: 'black',
                      fontSize: '40px',
                      textTransform: 'uppercase',
                      color: 'white',
                      zIndex: 1,
                    }}
                  >
                     	{project?.title?.split(/\s/).slice(0, 3).map(word => word[0]).join('')}
                  </Typography>
                </Box>}
              {project && (<Box sx={{
                  display: 'flex',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}>
                   <Button
              onClick={() => {
                navigate('/project/' + projectId + '?page=characters&episodes=0');
              }}
              sx={{
                color: 'white',
                borderRadius: '8px',
                height: '30px',
                marginTop: '-20px',
                marginBottom: '-10px',
                textDecoration: 'underline',
              }}
            >
              <ArrowLeft fontSize='large' color='primary'/> {'Back to Project Page'}
            </Button>
                  <Box sx={fieldContainerStyles}>
              <TextField
              margin='normal'
              required
              fullWidth
              id='title'
              label='Project Title'
              name='title'
              autoFocus
              InputLabelProps={{ shrink: true }}
              value={titleInput}
              onChange={(e) => setTitleInput(e.target.value)}
              disabled={currentGenerationFieldKey === 'title' ? true : false}
            />
            <Button
								onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleGenerateFieldValue('title',setTitleInput)}
								variant='contained'
								component='label'
								sx={{
									height: '56px',
									marginTop: '16px',
									marginBottom: '8px',
									fontSize: '36px',
								}}
								disabled={!isGenerationButtonAllowed ? !(!generationLoading && currentGenerationFieldKey === 'title') : false}
								>
									{(generationLoading && currentGenerationFieldKey === 'title') ? (<CircularProgress size={25}/>) : ((!generationLoading && currentGenerationFieldKey === 'title') ? <StopIcon /> : ('🤖'))}
							</Button>
            </Box>
             
            <Box sx={fieldContainerStyles}>
							
              <TextField
              margin='normal'
              fullWidth
              id='tagline'
              label='Project Tagline'
              placeholder='A catchy one-liner for your project!'
              name='tagline'
              disabled={currentGenerationFieldKey === 'tagline' ? true : false}
              //autoFocus
              InputLabelProps={{ shrink: true }}
              value={tagline}
              onChange={(e) => {
                setTagline(e.target.value);
              }}
            />
            <Button
								onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleGenerateTagValue()}
								variant='contained'
								component='label'
								sx={{
									height: '56px',
									marginTop: '16px',
									marginBottom: '8px',
									fontSize: '36px',
								}}
								disabled={!isGenerationButtonAllowed ? !(!generationLoading && currentGenerationFieldKey === 'tagline') : false}
								>
									{(generationLoading && currentGenerationFieldKey === 'tagline') ? (<CircularProgress size={25}/>) : ((!generationLoading && currentGenerationFieldKey === 'tagline') ? <StopIcon /> : ('🤖'))}
							</Button>
            </Box>
            
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <TextField
                margin='normal'
                fullWidth
                name='imageURL'
                label='Enter image link'
                id='image'
                disabled={currentGenerationFieldKey === 'imageUrl' ? true : false}
                helperText='To delete image type: default'
                InputLabelProps={{ shrink: true }}
                value={imageInput}
                onChange={(e) => {
                  setImageInput(e.target.value);
                  setImageFile('');
                  setWasImageChanged(true);
                }}
              />
              <Typography sx={{ m: 0, p: 2 }}>OR</Typography>
              <Button
                variant='contained'
                component='label'
                sx={{
                  height: '56px',
                  marginTop: '16px',
                  marginBottom: '8px',
                }}
                disabled={!isGenerationButtonAllowed}
              >
                Upload
                <input
                  hidden
                  value={imageFile}
                  name='image'
                  accept='image/*'
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                  type='file'
                />
              </Button>
            </div>
            <Box sx={fieldContainerStyles}>
            <TextField
              margin='normal'
              fullWidth
              multiline
              minRows={4}
              maxRows={12}
              InputLabelProps={{ shrink: true }}
              name='description'
              disabled={currentGenerationFieldKey === 'description/treatment' ? true : false}
              label="STORYBUILDER AI"
              placeholder="Our AI will take whatever you write here and use it to build out your project for you. Write whatever comes to mind about your story here. Who are the main characters, the heroes, the villains? What are they doing or what are they pursuing? What are the major plot points of the story? What themes are at work? What genre is it? What other movies, books, TV shows, etc is it like?"
              id='description'
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
             <Button
								// onClick={() => handleFillFieldWithAI('skills')}
								variant='contained'
								component='label'
                onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleGenerateFieldValue('description/treatment',setDescription, true)}
								sx={{
									height: '56px',
									marginTop: '16px',
									marginBottom: '8px',
									fontSize: '36px',
								}}
								disabled={!isGenerationButtonAllowed ? !(!generationLoading && currentGenerationFieldKey === 'description/treatment') : false}
								>
									{(generationLoading && currentGenerationFieldKey === 'description/treatment') ? (<CircularProgress size={25}/>) : ((!generationLoading && currentGenerationFieldKey === 'description/treatment') ? <StopIcon /> : ('🤖'))}
							</Button>
              </Box>
              <Box sx={fieldContainerStyles}> 
                <TextField
                  margin='normal'
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={12}
                  InputLabelProps={{ shrink: true }}
                  name='descriptionAdjust'
                  label='How would you like the 🤖 AI to adjust your Storybuilder draft?'
                  placeholder='Tell the AI it needs to include a specific character or setting. Tell it you want something different to happen during the story, or you want it to take place during a different time or place.'
                  id='descriptionAdjust'
                  value={descriptionAdjust}
                  onChange={(e) => {
                    setDescriptionAdjust(e.target.value);
                  }}
                />
                </Box>

                <Box sx={{width: '100%'}}>
                  <Button
                    variant='contained'
                    component='label'
                    fullWidth
                    disabled={!isGenerationButtonAllowed}
                    onClick={() => {
                      if (doesCardHaveUnsavedData.current) {
                        setOpenModal(true)
                      } else {
                        handleGenerateSettingsAndCharacters()
                      }
                    }}
                    sx={{ mt: 1, mb: 2, color: '#fff' }}
                  >
                    STORYBUILDER AI: Build Your Project from the Above
                  </Button>
                  <Button
                  fullWidth
                  onClick={() => handleSaveChanges(formRef.current)}
                  variant='contained'
                  sx={{ mt: 3, color: '#fff' }}
                  disabled={!isGenerationButtonAllowed}
                >
                  Save
                </Button>
              </Box>

              <Box sx={fieldContainerStyles}> 
              <TextField
              margin='normal'
              fullWidth
              multiline
              minRows={4}
              maxRows={12}
              InputLabelProps={{ shrink: true }}
              name='rules'
              label="What are the RULES of your project?"
              placeholder="*ADVANCED EXPERT SETTING* Ignore this until later, as it can exercise fine control over everything that the AI generates for you. These are rules or styles the AI will keep in mind whenever it is generating content for this project. Do you need your Openings handled a specific way? Do you want the writing style to mimic a specific writing style? Set things like that here."
              id='rules'
              value={rules}
              onChange={(e) => {
                setRules(e.target.value);
              }}
              disabled={currentGenerationFieldKey === 'rules' ? true : false}
              />
               {/* <Button
								// onClick={() => handleFillFieldWithAI('skills')}
								variant='contained'
								component='label'
                onClick={() => handleGenerateFieldValue('rules',setRules)}
								sx={{
									height: '56px',
									marginTop: '16px',
									marginBottom: '8px',
									fontSize: '36px',
								}}
							>
								🤖
							</Button>  */}
              </Box>
           
            
            </Box>)}
             
            </Box>
          </>}
      </Container>
  );
};

export default ProjectInfo;

