import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import useQuery from '../../../helpers/hooks/useQuery';
import ComicPageCardGrid from '../../ComicPageCardGrid';
import ComicCharacterCardGrid from '../../ComicCharacterCardGrid';
import ComicStoryCardGrid from '../../ComicStoryCardGrid';
import ComicSettingCardGrid from '../../ComicSettingCardGrid';
import ComicImageCardGrid from '../../ComicImageCardGrid';
import * as constants from '../../../helpers/constants'
import { SortableContainer } from 'react-sortable-hoc';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '100%',
    sm: '80%'
  },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export default function AllCardsView({open,setOpen,cardsToRender,setCardsToRender}) {
  const handleClose = () => setOpen(false);
  const query = useQuery();
  const pageQueryValue = query.get("page")

  function handleDragEnd ({ oldIndex, newIndex }) {
    const reorderedItems = [...cardsToRender];
    const [removed] = reorderedItems.splice(oldIndex, 1);
    reorderedItems.splice(newIndex, 0, removed);
    console.log(reorderedItems)
    setCardsToRender(reorderedItems)
    // dispatch(changeProjectsOrder(reorderedItems));
  }

  const SortableGrid = SortableContainer(({ items }) => (
    <Box
      sx={{
        marginTop: 2,
        marginBottom: 2,
        alignItems: 'start',
        justifyContent: 'start',
        gap: '20px',
        display: 'flex',
        overflow: 'scroll',
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxHeight: '90vh',
        '&::-webkit-scrollbar': {
          width: '2px',
          height: 0,
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '6px',
          backgroundColor: constants.ORANGE,
        },
      }}
    >
      {pageQueryValue === 'pages' && items.map((item,index) => <ComicPageCardGrid key={index} index={index} onCardHandleClick={() => console.log(index)} imageIndex={item.imageIndex} page={item.page} epizodeIndex={item.epizodeIndex ? item.epizodeIndex : undefined}/>)} 
      {pageQueryValue === 'characters' && items.map((item,index) => <ComicCharacterCardGrid key={index} index={index} onCardHandleClick={() => console.log(index)} characterId={item.characterId} character={item.character} epizodeIndex={item.epizodeIndex ? item.epizodeIndex : undefined}/>)}
      {pageQueryValue === 'setting' && items.map((item,index) => <ComicSettingCardGrid  key={index} index={index} onCardHandleClick={() => console.log(index)} settingItemId={item.settingItemId} settingItem={item.settingItem} epizodeIndex={item.epizodeIndex ? item.epizodeIndex : undefined}/>)}
      {pageQueryValue === 'story' && items.map((item,index) => <ComicStoryCardGrid  key={index} index={index} onCardHandleClick={() => console.log(index)} storyId={item.story.id} story={item.story} epizodeIndex={item.epizodeIndex ? item.epizodeIndex : undefined}/>)}
      {pageQueryValue === 'images' && items.map((item,index) => <ComicImageCardGrid  key={index} index={index} onCardHandleClick={() => console.log(index)} storyId={item.image.id} image={item.image} epizodeIndex={item.epizodeIndex ? item.epizodeIndex : undefined}/>)}
    </Box>
  ));


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <Box component="form" onSubmit={handleClose} noValidate sx={style}>
        <Typography sx={{ m: 0, p: 2 }}>
          All cards
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 20,
            top: {
              sm: '5%',
              xs: '5%',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <SortableGrid
            items={cardsToRender}
            onSortEnd={handleDragEnd}
            distance={0}
            axis="xy" // Enable both X and Y axis sorting
          />
        </Box>
      </Modal>
    </div>
  );
}