import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {
	// CardActionArea,
	CardActions,
	Typography,
	Box
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChangeImage from '../modals/ChangeImage';
import DeleteImage from '../modals/DeleteImage';
import { Draggable } from 'react-beautiful-dnd';

import * as constants from '../../helpers/constants';

export default function ComicImageCard({
	image,
	epizodeIndex,
	onCardHandleClick,
	index,
	setCardsToRenderFunc,
	setting,
	characters,
	stories,
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [changeCharacterOpen, setChangeCharacterOpen] = useState(false);
	const [deleteCharacterOpen, setDeleteCharacterOpen] = useState(false);
	const [cardChangeModalFlag, setCardChangeModalFlag] = useState(false);
	const open = Boolean(anchorEl);
	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setCardChangeModalFlag((current) => !current);
	}, [image]);

	let draggableId;
	if (epizodeIndex) {
		draggableId = epizodeIndex * 10 + image?.id;
	} else {
		draggableId = image?.id;
	}

	console.log(image)
	return (
		<Draggable
			draggableId={'dragable-' + index}
			index={index}
			key={draggableId}
		>
			{(provided) => (
				<Card
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					sx={{ p: 0, maxWidth: '140px' }}
				>
					<div style={{ position: 'relative' }}>
						{cardChangeModalFlag && (
							<ChangeImage
								setCardsToRenderFunc={setCardsToRenderFunc}
								open={changeCharacterOpen}
								setOpen={setChangeCharacterOpen}
								image={image}
								imageId={image?.id}
								epizodeIndex={epizodeIndex}
								setting={setting}
								characters={characters}
								stories={stories}
							/>
						)}
						{!cardChangeModalFlag && (
							<ChangeImage
								setCardsToRenderFunc={setCardsToRenderFunc}
								open={changeCharacterOpen}
								setOpen={setChangeCharacterOpen}
								image={image}
								imageId={image?.id}
								epizodeIndex={epizodeIndex}
								setting={setting}
								characters={characters}
								stories={stories}
							/>
						)}
						<DeleteImage
							setCardsToRenderFunc={setCardsToRenderFunc}
							open={deleteCharacterOpen}
							setOpen={setDeleteCharacterOpen}
							image={image}
							imageId={image?.id}
							epizodeIndex={epizodeIndex}
						/>

						{/* <CardActionArea> */}
						{cardChangeModalFlag && (
							<Box
							onClick={onCardHandleClick}
							sx={(theme) => ({

								position: 'relative',
								height: 140,
								width: 140,
								background: `linear-gradient(to bottom left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
								cursor: 'pointer',
								overflow: 'hidden',
							})}
						>
							<CardMedia
								component='div'
								sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								zIndex: 2,
								}}
								image={constants.IMAGE_URL + image?.image + '?time=' + image?.lastImageUpdate}
								alt={'generated image'}
								onClick={() => onCardHandleClick()}
							/>
							<Typography
								sx={{
									position: 'absolute',
									top: '30%',
									//left: '35%',
									left: '0',
									right: '0',
									textAlign: 'center',
									color: 'black',
									fontSize: '40px',
									textTransform: 'uppercase',
									color: 'white',
									zIndex: 1,
								}}
							>
									{image?.title?.split(/\s/).slice(0, 3).map(word => word[0]).join('')}
							</Typography>
						</Box>
						)}
						{!cardChangeModalFlag && (
							<Box
							onClick={onCardHandleClick}
							sx={(theme) => ({
								position: 'relative',
								height: 140,
								width: 140,
								background: `linear-gradient(to bottom left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
								cursor: 'pointer',
								overflow: 'hidden',
							})}
						>
							<CardMedia
								component='div'
								sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								}}
								image={constants.IMAGE_URL + image?.image + '?time=' + image?.lastImageUpdate}
								alt={'generated image'}
								onClick={() => onCardHandleClick()}
							/>
						</Box>
						)}

						<Menu
							anchorEl={anchorEl}
							id='account-menu'
							open={open}
							onClose={handleClose}
							onClick={handleClose}
							transformOrigin={{
								horizontal: 'right',
								vertical: 'top',
							}}
							anchorOrigin={{
								horizontal: 'right',
								vertical: 'bottom',
							}}
						>
							<MenuItem
								onClick={() => setChangeCharacterOpen(true)}
							>
								<Typography textAlign='center'>
									Change image
								</Typography>
							</MenuItem>
							<MenuItem
								onClick={() => setDeleteCharacterOpen(true)}
							>
								<Typography textAlign='center'>
									Delete
								</Typography>
							</MenuItem>
						</Menu>

						<CardActions>
							<Typography
								//gutterBottom
								variant='h5'
								component='div'
								onClick={() => onCardHandleClick()}
								sx={{
									textAlign: 'left',
									width: '100%',
									fontSize: '16px',
									paddingTop: '0px',
									paddingLeft: "2px",
									maxWidth: 'calc(100% - 18px)',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
								}}
							>
								{image?.title}
							</Typography>
						</CardActions>
						{/* </CardActionArea> */}
						<Tooltip>
							<IconButton
								onClick={handleOpen}
								size='small'
								sx={{
									ml: 2,
									position: 'absolute',
									right: '0px',
									bottom: '0px',
								}}
								aria-controls={
									open ? 'account-menu' : undefined
								}
								aria-haspopup='true'
								aria-expanded={open ? 'true' : undefined}
							>
								<Avatar
									sx={{
										width: 24,
										height: 24,
										bgcolor: 'transparent',
									}}
								>
									<MoreVertIcon style={{ color: '#fff' }} />
								</Avatar>
							</IconButton>
						</Tooltip>
					</div>
				</Card>
			)}
		</Draggable>
	);
}
