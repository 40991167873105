import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {  Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import * as constants from '../../../helpers/constants';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch } from 'react-redux';
import { setGideTokensFillModal } from '../../../store/slices/authSlice';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '100%',
		sm: '80vw',
	},

	bgcolor: 'background.paper',
	border: '2px solid #000',
	outline: 'none',
	boxShadow: 24,
	p: 4,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	gap: '30px'
};

export default function AutoFillTokensModal({open,setOpen}) {
	const token = useSelector((state) => state.auth.token);
	const [autoFillTokensFlag,setAutoFillTokensFlag] = useState(false);
	const dispatch = useDispatch();

	async function changeShowModalFlag () {
		dispatch(setGideTokensFillModal(true))
        await axios
          .post(constants.BASE_URL + 'api/auth/change-user-tokens-auto-fill-show-modal-flag', {
            flagValue: true
          }, {
            headers: {
            'Authorization': `Bearer ${token}`
            },
          } ).then(function (response) {
			localStorage.setItem('hideTokensFillModal', true)
          }).catch(function (error) {
            console.log(error);
          });
      }   

	  async function changeFlag (flagValue) {
        await axios
          .post(constants.BASE_URL + 'api/auth/change-user-tokens-auto-fill-flag', {
            flagValue
          }, {
            headers: {
            'Authorization': `Bearer ${token}`
            },
          } ).then(function (response) {
          }).catch(function (error) {
            console.log(error);
          });
      }    

	function onHandleClose () {
		setOpen(false);
	}

	return (
			<Modal
				open={open}
				onClose={()=> {
					if(autoFillTokensFlag) {
						changeShowModalFlag()
					}
					onHandleClose()
				}}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					component='div'
					sx={style}
				>
					<IconButton
						aria-label="close"
						onClick={()=> {
							if(autoFillTokensFlag) {
								changeShowModalFlag()
							}
							onHandleClose()
						}}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
					<CloseIcon />
					</IconButton>
					<Typography sx={{fontSize: '20px'}}>Auto charge money for extra tokens when its needed, you can always change this in your "user-info" page</Typography>
					<Box sx={{
						display: 'flex', gap: '30px'
					}}>
						<Button
							variant='contained'
							component='label'
							sx={{
								color: 'white',
								marginTop: '16px',
								marginBottom: '8px',
								width: '102px'
							}}
							onClick={()=> {
								if(autoFillTokensFlag) {
									changeShowModalFlag()
								}
								changeFlag(true)
								onHandleClose()
							}}
						>
							Accept
						</Button>
						<Button
							variant='contained'
							component='label'
							sx={{
								color: 'white',
								marginTop: '16px',
								marginBottom: '8px',
								width: '102px'
							}}
							onClick={()=> {
								if(autoFillTokensFlag) {
									changeShowModalFlag()
								}
								onHandleClose()
							}}
						>
							Reject
						</Button>
					</Box>
					<Box
						sx={
						{
							display: 'flex',
							alignItems: 'center'
						}
						}
					>
						<Typography sx={{fontSize: '14px'}}>Do not show this again</Typography>
						<Checkbox  
						checked={autoFillTokensFlag}
						onChange={(e) => {
							setAutoFillTokensFlag(e.target.checked)
						}}
						inputProps={{ 'aria-label': 'controlled' }}
						/>
						<Typography >
						
						</Typography>
					</Box>
				</Box>
			</Modal>
	);
}
