import React, { useEffect, useState } from 'react';
import { Button, Box, TextField, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import {  Typography, Drawer } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import * as constants from '../../../helpers/constants';
import useQuery from '../../../helpers/hooks/useQuery';
import ChatIcon from '@mui/icons-material/Chat';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import { useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { setTokens } from '../../../store/slices/authSlice';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '100%',
		sm: '80vw',
	},
	height: {
		xs: '100%',
		sm: '80vh',
	},
	bgcolor: 'background.paper',
	border: '2px solid #000',
	borderRadius: '2%',
	outline: 'none',
	boxShadow: 24,
	p: 4,
	display: 'flex',
};

export default function ChatModal({currentProject,setTokensFillModal}) {
	const [open,setOpen] = useState(false)
	const dispatch = useDispatch();
	const location = useLocation();
	const alert = useAlert();
	const query = useQuery();
	const navigate = useNavigate();
	const token = useSelector((state) => state.auth.token);
	const userId = localStorage.getItem('userId');
	const [hoveredIndex, setHoveredIndex] = useState(null);
	const [chats,setChats] = useState([])
	const [currentChat,setCurrentChat] = useState(null)
	const [fieldValue, setFieldValue] = useState('')
	const [messages, setMessages] = useState([])
	const messagesContainer = useRef()
	const theme = useTheme();
	const currentSocket = useRef()
	const currentWebSocketObject = useRef();
	const [isGenerationButtonAllowed, setIsGenerationButtonAllowed] = useState(true);
	const [loading, setLoading] = useState(false);
	const hideTokensFillModal = useSelector((state) => state.auth.hideTokensFillModal);

	useEffect(()=> {
		if(currentChat && messages.length > 1) {
			const container = messagesContainer.current 
			container.scrollTop = messagesContainer.current?.scrollHeight;
		}
	},[messages])

	useEffect(()=>{
		if (userId && currentProject.title) {
			// dispatch(addLoadingFlag('get-chats'));
			axios
			.get(
			  constants.BASE_URL + 'api/chat/get-chats/' + userId,
			  {
				headers: {
				  Authorization: `Bearer ${token}`,
				},
			  }
			).then(function (response) {
				
					// Filter chats based on currentProject.title
					const filteredChats = response.data.filter(chats => 
						chats.title && chats.title.startsWith(currentProject.title)
					);
			
			//   dispatch(removeLoadingFlag('get-chats'));
			  setChats(filteredChats)
			  console.log(filteredChats)
			}).catch(function (error) {
			  console.log(error);
			//   dispatch(removeLoadingFlag('get-prompts'));
			});
		}
		
	},[userId, currentProject.title])

	const getUserTokens = () => {
		axios
		.get(
		  constants.BASE_URL + 'api/auth/get-user-info',
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  console.log(response.data)
		  dispatch(setTokens(response.data?.tokens))
		}).catch(function (error) {
		  console.log(error);
		});
	  }

	async function addChat () {
		const getTitleCount = (title) => {
			const titleRegex = new RegExp(`^${title}( \\d+)?$`);
			let count = 0;
			let firstFindFlag = false
			chats.forEach(chat => {
				if (chat.title && titleRegex.test(chat.title)) {
					count++;
					if(!firstFindFlag) {
						count++;
						firstFindFlag = true
					}
				}
			});
			return count;
		};

		const newTitleNumberFuncResult =  getTitleCount(currentProject?.title)
		const newTitle = newTitleNumberFuncResult > 0 ? currentProject?.title + ' ' + newTitleNumberFuncResult : currentProject?.title
		dispatch(addLoadingFlag('add-chat'));
		await axios
		  .post(constants.BASE_URL + 'api/chat/add-chat', {
			userId,
			title: newTitle
		  }, {
			headers: {
			  'Authorization': `Bearer ${token}`
			},
		  } ).then(function (response) {
			dispatch(removeLoadingFlag('add-chat'));
			setChats(prev => [...prev,response.data.chat]);
		  }).catch(function (error) {
			console.log(error);
			dispatch(removeLoadingFlag('add-chat'));
		  });
	}

	async function deleteChat (chatId) {
		dispatch(addLoadingFlag('delete-chat'));
		await axios
		  .delete(constants.BASE_URL + 'api/chat/delete-chat/' + chatId + '/' + userId, {
			headers: {
			  'Authorization': `Bearer ${token}`
			},
		  }).then(function (response) {
			dispatch(removeLoadingFlag('delete-chat'));
			setChats(prev => prev.filter(chat => chat.chatId !== chatId));
			alert.show(
				`Chat was successfully deleted`,
				{ type: 'success' }
			);
			if (currentChat.chatId === chatId) {
				setCurrentChat(null)
				setMessages([])
			}
		  }).catch(function (error) {
			console.log(error);
			dispatch(removeLoadingFlag('delete-chat'));
		  });
	}

	useEffect( () => {
		webSocketConnect();
	
		return () => {
			if (currentWebSocketObject.current) {
				currentWebSocketObject.current?.close();
				currentSocket.current = null;
				currentWebSocketObject.current = null
			}
		
		};
	 }, []);

	 async function webSocketConnect () {
		return new Promise((resolve, reject) => {
			const socket = new WebSocket(constants.BASE_WS_URL); // WebSocket server address
			socket.onopen = () => {
				console.log('WebSocket connection established');
			};

			socket.onmessage = (event) => {
				console.log('Received message:', event.data);
				let data = event.data
				try {
					data = JSON.parse(event.data); // Parse the JSON string into an object
				} catch (error) {
					console.error('Error parsing JSON:', error);
				}
				console.log('Received message obj:', data);

				if (data?.type === 'clientId') {
					console.log('clientID set')
					currentSocket.current = data.clientId
					currentWebSocketObject.current= socket
					resolve(socket)
				}

				if (data?.type === 'content') {
					
					setMessages(prev => {
						const newMessages = [...prev]
						console.log(newMessages)
						if (newMessages[newMessages.length - 1]?.content === '...checking notes...') {
							newMessages[newMessages.length - 1].content = data.content
						} else {
							newMessages[newMessages.length - 1].content = newMessages[newMessages.length - 1].content + data.content
						}
						return newMessages
					});
				}
				if (data?.type === 'message' && data?.message === 'first generation') {
					setLoading(false)
				}
				if (data?.type === 'message' && data?.message === 'last generation') {
					alert.show(`Text was successfully generated`, {
						type: 'success',
					});
					setLoading(false)
					setIsGenerationButtonAllowed(true)
					getUserTokens()
				}
			
            // Handle incoming messages from the WebSocket server
			};

			socket.onerror = (error) => {
				console.error('WebSocket error:', error);
				// Handle WebSocket connection errors
			};

			socket.onclose = () => {
				console.log('WebSocket connection closed');
				currentSocket.current = null
				setIsGenerationButtonAllowed(true)
				// Handle WebSocket connection closure
			};

		});
	}

	async function addMessage (message) {
		if (!currentSocket.current) {
			try {
				await webSocketConnect();
				if (currentWebSocketObject.current.readyState === WebSocket.OPEN) {
					generationFunction();
				}
			} catch (error) {
				console.error('Error connecting to WebSocket:', error);
			}
		} else {
			generationFunction();
		}
		async function generationFunction () {
			setMessages(prev => [...prev, {role: 'assistant',content: '...checking notes...'}]);
			await axios
			  .post(constants.BASE_URL + 'api/chat/add-message', {
				chatId: currentChat.chatId,
				content: message,
				projectTitle: currentProject?.title,
				projectDescription: currentProject?.description,
				clientId: currentSocket.current
			  }, {
				headers: {
				  'Authorization': `Bearer ${token}`
				},
			  } ).then(function (response) {
				// newMessages.push({role: 'user', content: message},response.data)
				// setMessages(newMessages);
				// getUserTokens()
			  }).catch(function (error) {
				if (error.response?.data === 'error with openAi') {
					alert.show(
					  `Something went wrong with AI server`,
					  { type: 'error' }
					);
				}  else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
					if (localStorage.getItem('role')=== 'corporate user') {
					  alert.show(
						`Something wrong with subscription, please note your corporate admin`,
						{ type: 'error' }
					  );
					} else {
					  alert.show(
						`Subsctiption is required for generation`,
						{ type: 'error' }
					  );
					}
								
				  } else if (error.response?.data === 'Not enought tokens') {
					if (localStorage.getItem('role')=== 'corporate user') {
					  alert.show(
						`Something wrong with token count, please note your corporate admin`,
						{ type: 'error' }
					  );
					} else {
						if (!hideTokensFillModal){
							setTokensFillModal(true)
						  }
					  alert.show(
						`You don't have enough tokens to perform this action`,
						{ type: 'error' }
					  );
					}
							
				} else {
					alert.show(
						`Something went wrong with text generation`,
						{ type: 'error' }
					);
					console.log(error);
				}
				setLoading(false);
				setIsGenerationButtonAllowed(true)
				setMessages(prev => [...prev?.filter(message => message?.content !== '...checking notes...')]);
			});			
		}
		
	}

	useEffect(() => {
		if (currentChat !== null) {
			getChatMessages()
		}
		
	},[currentChat])

	async function getChatMessages () {
		console.log(currentChat)
		axios
		.get(
		  constants.BASE_URL + 'api/chat/get-chat-messages/' + currentChat.chatId,
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  setMessages(response.data)
		  console.log(response.data)
		}).catch(function (error) {
		  console.log(error);
		});
	}

	return (
		<>
			<div
				style={{
					position: 'fixed',
					right: '3px',
					bottom: '5px',
					width: '50px',
					height: '50px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '50%',
					background: theme.palette.primary.main,
					border: '2px solid #000',
					zIndex: '999999',
					cursor: 'pointer',
				}}
				onClick={()=> setOpen(true)}
			>
				<ChatIcon 
					sx={{ fontSize:"30px"
					}}
				/>
			</div>
			<Modal
				open={open}
				onClose={()=> setOpen(false)}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					component='div'
					sx={style}
				> 
					
					<Drawer
						sx={{
							
							width: {
								sm: 240,
								xs: '100vw',
							},
							
							'& .MuiDrawer-paper': {
								width: {
									sm: 240,
									xs: '100vw',
								},
								
								backgroundColor: '#29323c',
								borderRadius: '14px',
								'&::-webkit-scrollbar': {
									width: '2px',
									height: 0,
								},
								'&::-webkit-scrollbar-thumb': {
									borderRadius: '6px',
									backgroundColor: theme.palette.primary.main,
								},
							},
						}}
						variant='permanent'
						anchor='left'
						
					>
						{chats.map((chat,index) => <Typography
							sx={{
								width: '100%',
								borderTop: '1px solid black',
								borderBottom: '1px solid black',
								padding: '15px 0',
								textAlign: 'center',
								cursor: 'pointer',
								position: 'relative'
							}}
							onClick={() => setCurrentChat(chat)}
							onMouseEnter={() => setHoveredIndex(index)}
          					onMouseLeave={() => setHoveredIndex(null)}
						>{chat.title ? chat.title : 'Chat' + (index + 1)}
						{hoveredIndex === index && (
						 <IconButton
							sx={{
							position: 'absolute',
							top: '50%',
							right: '10px',
							transform: 'translateY(-50%)',
							backgroundColor: 'red',
							color: 'white',
							'&:hover': {
								backgroundColor: 'darkred',
							},
							}}
							onClick={(e) => {
								e.stopPropagation()
								deleteChat(chat.chatId)
							}}
						>
							<DeleteForeverIcon />
						</IconButton>)}
						</Typography>)}
						<Button
							variant='contained'
							onClick={() => {
								addChat()
							}}
							sx={{
								fontSize: '16px',
								color: 'white',
								height: '40px',
								position: 'fixed',
								bottom: '15px',
								left: '45px',
							
							}}
							disableElevation
						>
							Add new chat
						</Button>
						{/* </div> */}
					</Drawer>
					<Box ref={messagesContainer} sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end',
						height: '90%',
						width: '100%',
						position: 'static',
						overflow: 'scroll',
						paddingRight: '5px',
						'&::-webkit-scrollbar': {
							width: '2px',
							height: 0,
						},
						'&::-webkit-scrollbar-thumb': {
							borderRadius: '6px',
							backgroundColor: theme.palette.primary.main,
						},
					}}>
					{(currentChat !== null && messages !== null) && messages.map((message, index) => (
						<Box
							key={index}
							sx={{
							width: '100%',
							padding: '15px 0',
							
							}}
						>
							{/* Conditional prefix based on user role */}
							<Box component="span" sx={{ display: 'block', color: (theme) => message?.role === 'user' ? theme.palette.secondary.main : theme.palette.primary.main }}>
							{message?.role === 'user' ? 'YOU:' : 'STORYBUILT:'}
							<br />
							</Box>
							{/* Handling new lines in message content */}
							<Box marginLeft={3} marginRight={5}> 
							{message?.content.split('\n').map((line, lineIndex) => (
							<React.Fragment key={lineIndex}>
								{line}
								{lineIndex !== message.content.split('\n').length - 1 && <br />}
							</React.Fragment> 
							))} </Box>
						</Box>
						))}
					{currentChat !== null && <TextField
									margin='normal'
									fullWidth
									id='textField'
									name='textField'
									multiline
									sx={{
										width: 'calc(75vw - 240px)',
										position: 'fixed',
										bottom: '5px',
									}}
									minRows={1}
									maxRows={1}
									placeholder='Brainstorm with our AI about your project here!'
									value={fieldValue}
									disabled={!isGenerationButtonAllowed ? loading : false}
									onChange={(e) => setFieldValue(e.target.value)}
									InputLabelProps={{ shrink: true }}
									onKeyDown={(event)=> {
										if (event.keyCode === 13) {
											setMessages(prev => [...prev, {
												content:fieldValue,
												role: 'user'
											}])
											setFieldValue('')
											addMessage(fieldValue)
										}
									}}
									onKeyUp={(event)=> {
										if (event.keyCode === 13) {
											setFieldValue('')
										}
									}}
									InputProps={{
										endAdornment: (
										  <InputAdornment position="end">
											<IconButton
												onClick={() => {
													if (isGenerationButtonAllowed && !loading) {
														setMessages(prevMessages => [...prevMessages, {
															content: fieldValue,
															role: 'user'
															}]);
														setFieldValue(''); 
														addMessage(fieldValue);
													}
												}}
>
											  <SendIcon />
											</IconButton>
										  </InputAdornment>
										),
									  }}
								/>}
					</Box>
				</Box>
			</Modal>
		</>
	);
}
