import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useSelector ,
   useDispatch
   } from 'react-redux';
import axios from 'axios';
import * as constants from '../../helpers/constants'
import Loader from '../../components/Loader';
import Button from '@mui/material/Button';
import { addLoadingFlag, removeLoadingFlag } from '../../store/slices/loadingSlice';
import { useAlert } from 'react-alert';
import AdminChangePassword from '../../components/modals/AdminChangePassword';
import AdminRegister from '../../components/modals/AdminRegister';
import { useNavigate } from 'react-router-dom';

const Users = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loading);
  const alert = useAlert();
  const navigate = useNavigate()
  const [users, setUsers] = useState([])
  const [changeModalOpen, setChangeModalOpen] = useState(false)
  const [registerModalOpen, setRegisterModalOpen] = useState(false)
  const [currentUserMail,setCurrentUserMail] = useState('');

  const getUsers = () => {
    dispatch(addLoadingFlag('get-users'));
    axios
    .get(
      constants.BASE_URL + 'api/auth/get-users',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(function (response) {
      dispatch(removeLoadingFlag('get-users'));
      alert.show(
        `Got users`,
        { type: 'success' }
      );
      setUsers(response.data)
    }).catch(function (error) {
      if (error.response.data === 'Permission denied') {
        navigate('/#');
      }
      console.log(error);
      dispatch(removeLoadingFlag('get-prompts'));
    });
  }

  const changeUserRole = async (role,userId) => {
    const newRole = role === 'user' ? 'admin' : 'user'
    dispatch(addLoadingFlag('get-users'));
    await axios
      .post(constants.BASE_URL + 'api/auth/change-user-role', {
        role: newRole,
        userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      } ).then(function (response) {
        dispatch(removeLoadingFlag('get-users'));
        alert.show(
          `Role changed`,
          { type: 'success' }
        );
        getUsers()
      }).catch(function (error) {
        if (error.response.data === 'Permission denied') {
          navigate('/#');
        }
        console.log(error);
        dispatch(removeLoadingFlag('get-prompts'));
      });
    }

  // const resetUserSubscriptionPlan = async (userId) => {
  //   dispatch(addLoadingFlag('reset-subscription'));
  //   await axios
  //     .post(constants.BASE_URL + 'api/auth/reset-user-subscription-plan', {
  //      userId
  //     }, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`
  //       },
  //     } ).then(function (response) {
  //       dispatch(removeLoadingFlag('reset-subscription'));
  //       alert.show(
  //         `Subscription changed`,
  //         { type: 'success' }
  //       );
  //       getUsers()
  //     }).catch(function (error) {
  //       if (error.response.data === 'Permission denied') {
  //         navigate('/#');
  //       }
  //       console.log(error);
  //       dispatch(removeLoadingFlag('reset-subscription'));
  //     });
  //   }
  
  const deleteUser = async (userId, deepDeleteFlag = false) => {
    dispatch(addLoadingFlag('delete-user'));
    await axios
      .post(constants.BASE_URL + 'api/auth/delete-user', {
       userId,deepDeleteFlag
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      } ).then(function (response) {
        dispatch(removeLoadingFlag('delete-user'));
        alert.show(
          `User deleted`,
          { type: 'success' }
        );
        getUsers()
      }).catch(function (error) {
        if (error.response.data === 'Permission denied') {
          navigate('/#');
        }
        console.log(error);
        dispatch(removeLoadingFlag('delete-user'));
      });
    }

    useEffect(()=> {
      getUsers()
    },[alert,dispatch,token])

    if (loading) {
      return <Loader />
    }
    return (
        <Container component="main" maxWidth='lg'>
          <CssBaseline />
          <AdminChangePassword open={changeModalOpen} setOpen={setChangeModalOpen} userMail={currentUserMail}/>
          <AdminRegister open={registerModalOpen} setOpen={setRegisterModalOpen} />
          {!loading && <>
              <Box
                sx={{
                  marginTop: {
                    xs: 2,
                    md: 8,
                  },
                  marginBottom: 4,
                  display: {
                    xs: 'flex',
                  },
                  alignItems: 'center',
                  justifyContent: {
                    md: 'space-between',
                    xs: 'space-around',
                  },
                  flexDirection: 'column',
                  flexWrap: {
                    xs: 'wrap',
                  },
                  gridTemplateColumns: '1fr 1fr',
                  gap: '30px 0',
                  position: 'relative',
                  paddingBottom: '100px',
                }}
              >
                <Box
                  sx={{
                    position: 'fixed',
                    bottom: '-1px',
                    padding: '10px',
                    left: '0',
                    zIndex: '10',
                    display: 'flex',
                    justifyContent: 'center',
                    right: '0',
                    width: '100%',
                    background: constants.DARK_GREY
                  }}
                >
                  {/* <Button
                  onClick={() => ''}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '56px',
                    color: 'white',
                    marginTop: '16px',
                    marginBottom: '8px',
                    fontSize: '36px',
                  }}
                >
                  SAVE
                </Button> */}
              </Box>
              {users?.length > 1 && <Button
                  onClick={() => {
                    setRegisterModalOpen(true)
                  }}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '36px',
                    color: 'white',
                    marginTop: '8px',
                    marginBottom: '8px',
                    fontSize: '14px',
                    marginRight: '8px'
                  }}
                >
                  Create new user
                </Button>}
             {users.map(user => 
               <Box
               key={user._id}
                  sx={{
                    padding: '15px',
                    border: '1px solid white',
                    borderRadius: '10px',
                    width: '70vw'
                  }}
                  fullWidth
               >
                <Typography>User Email: {user.email}</Typography>
                <Typography>User Role: {user.role}</Typography>
                {(user.role !== 'corporate admin' && user.role !== 'corporate user') &&
                <Button
                  onClick={() => changeUserRole(user.role,user._id)}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '36px',
                    color: 'white',
                    marginTop: '8px',
                    marginBottom: '8px',
                    fontSize: '14px',
                    marginRight: '8px'
                  }}
                >
                  Change Role
                </Button>
                }
                
                {/* <Button
                  onClick={() => resetUserSubscriptionPlan(user._id)}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '36px',
                    color: 'white',
                    marginTop: '8px',
                    marginBottom: '8px',
                    fontSize: '14px',
                    marginRight: '8px'
                  }}
                >
                  Reset Subscription
                </Button> */}
                <Button
                  onClick={() => {
                    setCurrentUserMail(user.email)
                    setChangeModalOpen(true)
                  }}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '36px',
                    color: 'white',
                    marginTop: '8px',
                    marginBottom: '8px',
                    fontSize: '14px',
                    marginRight: '8px'
                  }}
                >
                  Change password
                </Button>
                <Button
                  onClick={() => deleteUser(user._id, false)}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '36px',
                    color: 'white',
                    marginTop: '8px',
                    marginBottom: '8px',
                    fontSize: '14px',
                    marginRight: '8px'
                  }}
                  // disabled
                >
                  Delete
                </Button>
                <Button
                  onClick={() => deleteUser(user._id, true)}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '36px',
                    color: 'white',
                    marginTop: '8px',
                    marginBottom: '8px',
                    fontSize: '14px',
                    marginRight: '8px'
                  }}
                  // disabled
                >
                  Deep Delete
                </Button>
              </Box>)
              }
          
            </Box>
          </>}
      </Container>
  );
};

export default Users;

