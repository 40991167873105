import axios from 'axios';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import {Box,TextField,Typography,CardMedia, Modal} from '@mui/material';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import { logout } from '../../../store/slices/authSlice';
import * as constants from '../../../helpers/constants';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ModalLoader from '../../ModalLoader';
import { useEffect } from 'react';
import { JellyfishSpinner } from 'react-spinners-kit';
import placeholder from '../../../assets/images/placeholder.png'
import pptxgen from 'pptxgenjs';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export default function EditFolder({
	folder
}) {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const alert = useAlert();
	const token = useSelector((state) => state.auth.token);
	const [folderItem,setFolderItem] = useState(folder?.folder)
	const [scaledImage, setScaledImage] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	
	useEffect(() => {
		
	}, []);

	const downloadPresentation = () => {
		if (folderItem?.images?.length > 0) {
			const pptx = new pptxgen();
			
			if (folderItem.images.every(item => typeof item === 'string')) {
				folderItem.images.forEach((imagePath, index) => {
					let slide = pptx.addSlide(index);
					slide.addImage({ path: constants.IMAGE_URL + imagePath, x: 0, y: 0, w: 6, h: 4 }); // Adjust width (w) and height (h)
				  });
			} else {
				folderItem.images.filter(item => Array.isArray(item)).forEach(([imagePath, description], index) => {
					let slide = pptx.addSlide(index);
					slide.addImage({ path: constants.IMAGE_URL + imagePath, x: 0, y: 0, w: 6, h: 4 }); // Adjust width (w) and height (h)
					slide.addText(description, { x: 6, y: 2, w: 4, h: 1, fontSize: 14, color: '000' }); // Adjust y position for text
				  });
			}
		  
			pptx.writeFile('presentation');
		  } else {
			alert.show(
				`Something went wrong!`,
				{ type: 'error' }
			);
		  }		  
		  
	  };
	

	return (
		<>
			<Box sx={{
				display: 'flex',
				gap: '20px',
				flexWrap: 'wrap',
			}}>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				>
				<Box sx={style}>
					{scaledImage !== null && 
						<CardMedia
								component='img'
								sx={{
									height: '80vh',
									width: 'auto',
									cursor: 'pointer'
								}}
								image={typeof folderItem?.images[scaledImage] === 'string' ? constants.IMAGE_URL + folderItem?.images[scaledImage] : constants.IMAGE_URL + folderItem?.images[scaledImage][0]}
								alt={'generated image'}
								onClick={() => {
									setScaledImage(null)
									setIsOpen(false)
								}}
								onError={(e) => {
									e.target.src = placeholder;
								}}
							/>
						}
				</Box>
			</Modal>
					
				{folderItem?.images?.map((link,index)=> <Box sx={{
					display: 'flex',
					justifyContent: 'center'
				}}>
					{Array.isArray(link) && 
					<Box sx={{
						display: 'flex',
						gap: '5px',
						flexDirection: 'column',					
					}}>
						<CardMedia
							component='div'

							sx={{
								height: 210,
								width: 170,
								cursor: 'pointer'
							}}
							image={constants.IMAGE_URL + link[0]}
							alt={'generated image'}
							onClick={() => {
								setScaledImage(index)
								setIsOpen(true)								
							}}
							onError={(e) => {
								e.target.src = placeholder;
							}}
						/>
						{link[1] && <Typography sx={{ m: 0, p: 2, fontSize: '12px', width: '170px' }}>{link[1]}</Typography>}
					</Box>}
					{(!Array.isArray(link) && link !== "wrong link") && <CardMedia
						component='div'
						sx={{
							height: 210,
							width: 170,
							cursor: 'pointer'
						}}
						image={constants.IMAGE_URL + link}
						alt={'generated image'}
						onClick={() => {
							setScaledImage(index)
							setIsOpen(true)								
						}}
						onError={(e) => {
							e.target.src = placeholder;
						}}
					/>	}

				</Box>)}
			</Box>
			<div
						style={{
							display: 'flex',
							'justify-content': 'flex-end',
							position: 'fixed',
							bottom: '0',
							right: '0',
							width: '100%',
							paddingRight: '30px',
							'background-color': '#2f3944',
							'z-index': '1',
						}}
					>
						<Button
							fullWidth
							variant='contained'
							sx={{
								mt: 3,
								mb: 2,
								color: '#fff',
								width: 'fit-content',
								'align-self': 'self-end',
								marginRight: '10px'
							}}
							onClick={() =>
								downloadPresentation()
							}
						>
								Export Presentation
						</Button>
					</div>
				</>
		
	);
}
