import React, { useRef, useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {
	// CardActionArea,
	CardActions,
	Typography,
} from '@mui/material';
import ChangeProject from '../modals/ChangeProject';
import DeleteProject from '../modals/DeleteProject';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SortableElement } from 'react-sortable-hoc';
import * as constants from '../../helpers/constants';

const GalleryProjectCard = SortableElement(
	({ handleClick, image, title, index, project, getAllProjects }) => {
		const [changeProjectOpen, setChangeProjectOpen] = useState(false);
		const [deleteProjectOpen, setDeleteProjectOpen] = useState(false);
		const [anchorEl, setAnchorEl] = useState(null);
		const menuItemRef = useRef();
		const open = Boolean(anchorEl);
		const handleOpen = (event) => {
			setAnchorEl(event.currentTarget);
			event.stopPropagation();
			event.preventDefault();
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		return (
			<Card sx={{ p: 1, maxWidth: '316px' }}>
				{/* <CardActionArea> */}
				<div style={{ position: 'relative' }}>
					<ChangeProject
						open={changeProjectOpen}
						setOpen={setChangeProjectOpen}
						project={project}
						getAllProjects={getAllProjects}
					/>
					<DeleteProject
						open={deleteProjectOpen}
						setOpen={setDeleteProjectOpen}
						project={project}
						getAllProjects={getAllProjects}
					/>
					<Box
						onClick={handleClick}
						sx={(theme) => ({
							position: 'relative',
							height: {
							xs: 330,
							sm: 400,
							},
							width: {
							xs: 220,
							sm: 300,
							},
							background: `linear-gradient(to bottom left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
							cursor: 'pointer',
							overflow: 'hidden',
						})}
					>
						<CardMedia
							component='div'
							sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							objectFit: 'cover',
							zIndex: 2,
							}}
							image={constants.IMAGE_URL + image + '?time=' + project?.lastImageUpdate}
							alt='#'
							onClick={handleClick}
						/>
						<Typography
								sx={{
									position: 'absolute',
									top: '35%',
									//left: '35%',
									left: '0',
									right: '0',
									textAlign: 'center',
									color: 'black',
									fontSize: '40px',
									textTransform: 'uppercase',
									color: 'white',
									zIndex: 1,
								}}
							>
									{project?.title?.split(/\s/).slice(0, 3).map(word => word[0]).join('')}
							</Typography>
					</Box>
					
					<Menu
						anchorEl={anchorEl}
						id='account-menu'
						open={open}
						onClose={handleClose}
						onClick={handleClose}
						transformOrigin={{
							horizontal: 'right',
							vertical: 'top',
						}}
						anchorOrigin={{
							horizontal: 'right',
							vertical: 'bottom',
						}}
					>
						<MenuItem onClick={() => setChangeProjectOpen(true)}>
							<Typography textAlign='center'>Edit</Typography>
						</MenuItem>
						<MenuItem onClick={() => setDeleteProjectOpen(true)}>
							<Typography textAlign='center'>Delete</Typography>
						</MenuItem>
					</Menu>
					<CardActions>
						<Typography
							gutterBottom
							variant='h5'
							component='div'
							sx={{
								textAlign: 'center',
								width: {
									xs: 180,
									sm: 260,
								},
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								marginBottom: '0',
								whiteSpace: 'nowrap',
							}}
							onClick={() => {
								handleClick();
							}}
						>
							{title}
						</Typography>
						<Tooltip ref={menuItemRef}>
							<IconButton
								onClick={handleOpen}
								size='small'
								sx={{
									ml: 2,
									position: 'absolute',
									right: '0',
									bottom: '6px',
								}}
								aria-controls={
									open ? 'account-menu' : undefined
								}
								aria-haspopup='true'
								aria-expanded={open ? 'true' : undefined}
							>
								<Avatar
									sx={{
										width: 24,
										height: 24,
										bgcolor: 'transparent',
									}}
								>
									<MoreVertIcon style={{ color: '#fff' }} />
								</Avatar>
							</IconButton>
						</Tooltip>
					</CardActions>
				</div>
				{/* </CardActionArea> */}
			</Card>
		);
	}
);

export default GalleryProjectCard;
