import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { useAlert } from 'react-alert';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import axios from 'axios';
import * as constants from '../../../helpers/constants';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../store/slices/authSlice';
import imageCompression from 'browser-image-compression';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '100%',
		sm: '400px',
	},
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export default function ChangeCharacter({
	open,
	setOpen,
	character,
	characterId,
	epizodeIndex,
	setCardsToRenderFunc,
}) {
	const handleClose = () => setOpen(false);
	const stateLoading = useSelector((state) => state.loading.loading);
	const dispatch = useDispatch();
	const alert = useAlert();
	const [characterName, setCharacterName] = useState('');
	const token = useSelector((state) => state.auth.token);
	const navigate = useNavigate();

	const [imageFile, setImageFile] = useState();
	const [imageInput, setImageInput] = useState('');
	const [imageName, setImageName] = useState('');
	const [wasImageChanged, setWasImageChanged] = useState(false);

	const handleSubmit = async (e) => {
		if (stateLoading) {
			return
		}
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		if (data.get('image')?.size === 0) data.delete('image');
		if (
			!wasImageChanged ||
			constants.IMAGE_URL + character?.image === data.get('imageURL')
		)
			data.delete('imageURL');

		if (data.get('image') && character?.image !== 'default') {
			if (await checkImageSize(imageInput)) {
				setCharacterName(data.get('name'));
				dispatch(addLoadingFlag('update-character'));
				if (data.get('image')) {
					const file = data.get('image')
					data.delete('image');
					try {
						const options = {
							maxSizeMB: 0.90,
						}
						const compressedFile = await imageCompression(file, options);
						data.append('image',compressedFile)
						await request()

					} catch (error) {
						alert.show(
							`Something went wrong with image compression.`,
							{ type: 'error' }
						);
					}
				} else {
					await request()
				}
			}
		} else {
			if (data.get('image') || data.get('imageURL')) {
				await defaultImage(data)
			} else {
				const characterWithoutImage = {...character}
				delete characterWithoutImage.image
				const defaultData = {...characterWithoutImage,imageURL: 'default'}
				await defaultImage(defaultData)
			}
		}

		async function request () {
			console.log(data)
			await axios
				.post(
					constants.BASE_URL +
					'api/projects/update-character-by-id/' +
					character.id,
					data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					handleClose();
					alert.show(
						`'${
							characterName ? characterName : character?.name
						}' was successfully changed!`,
						{ type: 'success' }
					);
					dispatch(removeLoadingFlag('update-character'));
					setCardsToRenderFunc();
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('update-character'));
					if (error.response?.data === 'Non existing user.') {
						navigate('login');
						dispatch(logout());
					}
					
					
						alert.show(
							`Something went wrong, could not add illustrations.`,
							{ type: 'error' }
						);
					
				});
		}
		async function defaultImage (data) {
			dispatch(addLoadingFlag('update-character'));
			await axios
				.post(
					constants.BASE_URL +
						'api/projects/update-character-by-id/' +
						character.id,
						data
					,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					handleClose()
					alert.show(
						`'${
							character?.name
						}' was successfully changed!`,
						{ type: 'success' }
					);
					dispatch(removeLoadingFlag('update-character'));
					setCardsToRenderFunc();
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('update-character'));
					if (error.response?.data === 'Non existing user.') {
						navigate('login');
						dispatch(logout());
					}
					
						alert.show(
							`Something went wrong, could not add illustrations.`,
							{ type: 'error' }
						);
					
				});
		
			};
	};

	const handleFileChange = (event) => {
		console.log(event);
		const file = event.target.files[0];
		console.log(file);
		if (file) {
			if (file.size > 1024 * 1024 * 4) {
				console.log('error');
				setImageFile('');
				setImageInput('');
				setImageName('');
				// 4MB in bytes
				alert.show(`Image is too large. Maximum allowed size is 4MB.`, {
					type: 'error',
				});
			} else {
				// Proceed with image upload
				setImageFile(event.target.value);
				setImageInput('');
				setImageName(file.name);
			}
		}
	};

	const checkImageSize = (url) => {
		return fetch(url)
			.then((response) => {
				if (response.headers.get('content-length') > 1024 * 1024 * 4) {
					alert.show(
						`Image is too large. Maximum allowed size is 4MB.`,
						{
							type: 'error',
						}
					);
					setImageFile('');
					setImageInput('');
					setImageName('');
					return false;
				} else {
					// Proceed with using the image
					setImageFile('');
					setImageInput(url);
					setImageName('');

					return true;
				}
			})
			.catch((error) => {
				alert.show(
					`Something wrong with image`,
					{ type: 'error' }
				);
				console.error('Error fetching the image:', error);
				return false;
			});
	};

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					component='form'
					onSubmit={handleSubmit}
					noValidate
					sx={style}
				>
					<Typography
						sx={{
							m: 0,
							p: 2,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						CHANGE `{character?.name}`
					</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
					<Box
						sx={{
							maxHeight: '60vh',
							overflow: 'scroll',
							paddingRight: '8px',
							'&::-webkit-scrollbar': {
								width: '2px',
								height: 0,
							},
							'&::-webkit-scrollbar-thumb': {
								borderRadius: '6px',
								backgroundColor: constants.ORANGE,
							},
						}}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<TextField
								margin='normal'
								fullWidth
								name='imageURL'
								label='Image link'
								id='image'
								value={imageInput}
								onChange={(e) => {
									console.log(e.target.value);
									setImageInput(e.target.value);
									setImageFile('');
									setWasImageChanged(true);
								}}
								InputLabelProps={{ shrink: true }}
							/>
							<Typography sx={{ m: 0, p: 2 }}>OR</Typography>
							<Button
								variant='contained'
								component='label'
								sx={{
									height: '56px',
									marginTop: '16px',
									marginBottom: '8px',
								}}
							>
								Upload
								<input
									name='image'
									value={imageFile}
									hidden
									accept='image/*'
									onChange={(e) => {
										handleFileChange(e);
									}}
									type='file'
								/>
							</Button>
						</div>
						<TextField
							margin='normal'
							fullWidth
							name='imageName'
							label='Name of uploaded image'
							id='imageName'
							value={imageName}
							InputLabelProps={{ shrink: true }}
							placeholder=''
							disabled
						/>
					</Box>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
					>
						Change
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
