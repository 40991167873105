import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import projectsSlice from './slices/projectsSlice';
import loadingSlice from './slices/loadingSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    projects: projectsSlice,
    loading: loadingSlice,
  },
});

export default store;
