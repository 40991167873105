import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

const Collapsible = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const contentRef = useRef(null);
  const observer = useRef(null);

  useEffect(() => {
    const currentContent = contentRef.current;
    if (!currentContent) return;

    if (isExpanded) {
      // When expanded, set height to auto to allow for natural resizing
      currentContent.style.height = 'auto';
      const autoHeight = currentContent.scrollHeight; // Force browser to calculate the auto height

      // Then set it back to a specific value so we can animate it
      currentContent.style.height = `${autoHeight}px`;
    } else {
      // When collapsing, we can animate from the current height to 0
      currentContent.style.height = `${currentContent.scrollHeight}px`;
      window.requestAnimationFrame(() => {
        currentContent.style.height = '0px';
      });
    }
  }, [isExpanded]);

  useEffect(() => {
    const updateHeight = () => {
      if (!isExpanded || !contentRef.current) return;
      contentRef.current.style.height = 'auto';
    };

    const config = { childList: true, subtree: true };
    observer.current = new MutationObserver(updateHeight);
    observer.current.observe(contentRef.current, config);

    return () => observer.current.disconnect();
  }, [isExpanded]); // Only re-run this effect if isExpanded changes

  const handleToggle = () => setIsExpanded(!isExpanded);

  return (
    <div className={`list-group-item ${isExpanded ? "is-expanded" : ""}`}>
      <div className="card-title" onClick={handleToggle}>
        <h2 style={{
          position: 'relative',
          top: '-31px',
          left: '0%',
          marginBottom: '-38px',
        }}>
            {title}
            <ExpandCircleDownIcon
          style={{
            transition: "transform 0.3s ease",
            transform: isExpanded ? "rotate(0deg)" : "rotate(-90deg)",
          }}
        />
        </h2>
      </div>
      <div
        className="card-collapse"
        style={{
          overflow: "hidden",
          transition: "height 0.3s ease",
        }}
      >
        <div ref={contentRef} className="card-body">
          {children}
        </div>
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Collapsible;