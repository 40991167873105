import React, {useEffect, useRef, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {StyledBadge} from './style'
import { useNavigate } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';
import logoLong from '../../assets/images/Logo-long.png';
/* import logoTextOnly from '../../assets/images/Logo-text-only.png'; */
import { useDispatch } from 'react-redux';
import { logout } from '../../store/slices/authSlice';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import * as constants from '../../helpers/constants'
import { useSelector} from 'react-redux';
import { setTokens } from '../../store/slices/authSlice';

const pages = [
  {
    title: 'Projects',
    link: '/projects',
  },
  // {
  //   title: 'Setting',
  //   link: '/setting'
  // },
];
const settings = ['Logout', 'User info'];

function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const generationTokens = useSelector((state) => state.auth.generationTokens);
  console.log('generationTokens',generationTokens)
  const openNav = Boolean(anchorElNav);
  const openUser = Boolean(anchorElUser);
  const [user,setUser] = useState();
  const rerenderFlag = useRef()

  useEffect(()=> {
    getUser()
  },[])

  rerenderFlag.current = !rerenderFlag.current

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogout = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('email')
    localStorage.removeItem('tutorialFlag')
    localStorage.removeItem('userId')
    localStorage.removeItem('role')
    dispatch(logout());
    setAnchorElUser(null);
    setAnchorElNav(null)
    navigate('/login')
  };

  const getUser = () => {
    axios
    .get(
      constants.BASE_URL + 'api/auth/get-user-info',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(function (response) {
      console.log(response.data)
      setUser(response.data)
      dispatch(setTokens(response.data?.tokens))
    }).catch(function (error) {
      console.log(error);
    });
  }

  return (
    <>
    <AppBar position="fixed" color='secondary' enableColorOnDark sx={{height: '62.5px', bgcolor: 'black', width: '100%'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{justifyContent: {xs: 'space-between'}}}>
          <CardMedia
              component="img"
              sx={{
                  height: 40,
                  width: 'auto',
                  cursor: 'pointer'
                }}
              image={logoLong}
              alt="#"
              onClick={() => location.pathname !== '/login' && navigate('/projects')}
          />
           {/*  <CardMedia
              component="img"
              sx={{
                  height: 25,
                  width: 'auto',
                  cursor: 'pointer'
                }}
              image={logoTextOnly}
              alt="#"
              onClick={() => location.pathname !== '/login' && navigate('/projects')}
          /> 
          <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} /> */}
          {(location.pathname !== '/login') && <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>}
          <Menu
              disableScrollLock={ true }
              id="menu-appbar"
              anchorEl={anchorElNav}
              // anchorOrigin={{
              //   vertical: 'top',
              //   horizontal: 'right',
              // }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={openNav}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {
                (location.pathname === '/register' || location.pathname === '/contact') && 
                <MenuItem  onClick={() => {
                  handleCloseNavMenu()
                  navigate('/login')
                }}>
                <Typography textAlign="center">Sign in</Typography>
              </MenuItem>
              }

              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact') && pages.map((page) => (
                <MenuItem key={page.title} onClick={() => {
                    handleCloseNavMenu()
                    navigate(page.link)
                  }}>
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
               {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact') && 
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">{settings[0]}</Typography>
                </MenuItem>
              }
              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact') && 
                <MenuItem onClick={() => navigate('/user-info')}>
                  <Typography textAlign="center">{settings[1]}</Typography>
                </MenuItem>
              }
                 {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && localStorage.getItem('role') === 'corporate admin') && 
                <MenuItem onClick={() => navigate('/users-management')}>
                  <Typography textAlign="center">Users managment</Typography>
                </MenuItem>
              }
            </Menu>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {(location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/contact') && pages.map((page) => (
              <Button
                key={page.title}
                onClick={() => {
                  handleCloseNavMenu()
                  navigate(page.link)
                }}
                sx={{ fontSize: 18, ml: 2, color: 'white', display: 'block',"&:hover": {
                  backgroundColor: 'inherit'
                } }}
              >
                {page.title}
              </Button>
            ))}
            {(location.pathname === '/register' || location.pathname === '/contact') && 
              <Button
                onClick={() => {
                  handleCloseNavMenu()
                  navigate('/login')
                }}
                sx={{ my: 2, color: 'white', display: 'block',"&:hover": {
                  backgroundColor: 'inherit'
                } }}
              >
                Sign in
              </Button>
            }
          </Box>
          {(!rerenderFlag.current && location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/contact' && user?.subscriptionPlan === 'active' && generationTokens) && <Typography sx={{marginRight: '10px'}}>Tokens: {generationTokens}</Typography>}
          {(rerenderFlag.current && location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/contact' && user?.subscriptionPlan === 'active' && generationTokens) && <Typography sx={{marginRight: '10px'}}>Tokens: {generationTokens}</Typography>}
          {(location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/contact') && <Box sx={{ flexGrow: 0, display: {xs: 'none', md: 'block'} }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
              >
                 <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                  >
                    <Avatar  src="/static/images/avatar/1.jpg" />
                  </StyledBadge>
              </StyledBadge>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '43px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openUser}
              onClose={handleCloseUserMenu}
            >
               {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact') && 
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">{settings[0]}</Typography>
                </MenuItem>
              }
              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact') && 
                <MenuItem onClick={() => navigate('/user-info')}>
                  <Typography textAlign="center">{settings[1]}</Typography>
                </MenuItem>
              }
              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && localStorage.getItem('role') === 'corporate admin') && 
                <MenuItem onClick={() => navigate('/users-management')}>
                  <Typography textAlign="center">Users managment</Typography>
                </MenuItem>
              }
            </Menu>
          </Box>}
        </Toolbar>
      </Container>
    </AppBar>
    <div style={{height: '10px'}}></div>
    </>
  );
}
export default Header;
