import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import { Typography, MenuItem,	FormControl, InputLabel, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import * as constants from '../../../helpers/constants';
import { logout } from '../../../store/slices/authSlice';
import ModalLoader from '../../ModalLoader';
import CardMedia from '@mui/material/CardMedia';
import placeholder from '../../../assets/images/placeholder.png';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '100%',
		sm: '400px',
	},
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export default function ChangeImage({
	image,
	open,
	setOpen,
	episodesArray,
	setCardsToRenderFunc,
	setting,
	characters,
	stories
}) {
	const [loading, setLoading] = useState(false);
	const stateLoading = useSelector((state) => state.loading.loading);
	const dispatch = useDispatch();
	const alert = useAlert();
	const navigate = useNavigate();
	const token = useSelector((state) => state.auth.token);
	const [prompt, setPrompt] = useState(image?.prompt ?? '');
	const [title, setTitle] = useState(image?.title ?? '');
	const [generationMode, setGenerationMode] = useState(null);
	const [storyValue, setStoryValue] = useState(image?.storyValue ?? '')
	const [sceneValue, setSceneValue] = useState(image?.sceneValue ?? '')
	const [imageStyles, setImageStyles] = useState(image?.imageStyles ?? '')
	
	const imageStylesArray = constants.IMAGESTYLESARRAY;
	const handleClose = () => {
		setOpen(false)
		setGenerationMode(null)
	};
	
	const [generatedLink, setGeneratedLink] = useState(image?.image ? constants.IMAGE_URL + image.image : '');

	const handleGenerateImage = async () => {
		if (stateLoading || loading)  {
			return
		}
		const data = {
			prompt
		}

		if ((generationMode === '2' && (!storyValue || !sceneValue || !prompt || !imageStyles)) || (generationMode === '1' && (!prompt || !imageStyles))) {
			alert.show(
				`Please fill out all fields.`,
				{ type: 'error' }
			);	
			return
		}
		const chosenStory = generationMode === '2' ? stories?.find(item => item?.story?.id === storyValue) : null
		const chosenScene = generationMode === '2' ? chosenStory?.story?.scenes?.find(item => item?.id === sceneValue) : null

		const charactersToAI = (charactersArr) => charactersArr?.reduce((accumulator,charId) => {
			const character = characters?.find(item => item?.character?.id === charId)
			return accumulator = [...accumulator,character]
		},[])

		const settingToAI = (settignArr) => settignArr?.reduce((accumulator,setId) => {
			const settingItem = setting?.find(item => item?.settingItem?.id === setId)
			return accumulator = [...accumulator,settingItem]
		},[])
	
		if (chosenScene?.characters && generationMode === '2') data.characters = charactersToAI(chosenScene?.characters);
		if (chosenScene?.setting && generationMode === '2') data.setting = settingToAI(chosenScene?.setting);
		if (generationMode === '2') data.scene_script = chosenScene.scene_full_script

		data.imageStyles = imageStyles

		setLoading(true)
		if (generationMode === '2') {
			request('generate-scene-image')
		} else {
			request('generate-image')
		}
		
		async function request (link) {
			await axios
			.post(
				constants.BASE_URL +
					'api/ai/' + link,
				data,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(function (response) {
				console.log(response.data)
				setGeneratedLink(response.data)
				alert.show('New image was successfully added!', {
					type: 'success',
				});
				setLoading(false)
			})
			.catch(function (error) {
				setLoading(false)
				if (error.response?.data === 'Non existing user.') {
					navigate('login');
					dispatch(logout());
				}
				if (error.response?.data === 'error with openAi') {
					alert.show(
					  `Something went wrong with AI server`,
					  { type: 'error' }
					);
				} else {
					alert.show(
						`Something went wrong with text generation`,
						{ type: 'error' }
					);
				}
			});
		}

	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!title || stateLoading || loading)  {
			return
		}

		const data = {
			title,
			imageURL: generatedLink,
			prompt,
			generationMode: generationMode,
			imageStyles
		}

		const chosenStory = generationMode === '2' ? stories?.find(item => item?.story?.id === storyValue) : null
		const chosenScene = generationMode === '2' ? chosenStory?.story?.scenes?.find(item => item?.id === sceneValue) : null

		if (generationMode === '2') data.sceneValue = sceneValue;
		if (generationMode === '2') data.storyValue = storyValue;
		if (generationMode === '2') data.scene_script = chosenScene.scene_full_script

		dispatch(addLoadingFlag('update-image'));
		await axios
			.post(
				constants.BASE_URL +
					'api/projects/update-image-by-id/' +
					image.id,
					data,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(function (response) {
				handleClose();
				alert.show(
					`Image was successfully changed!`,
					{ type: 'success' }
				);
				setTitle('')
				setGeneratedLink('')
				setPrompt('')
				dispatch(removeLoadingFlag('update-image'));
				setCardsToRenderFunc();
			})
			.catch(function (error) {
				dispatch(removeLoadingFlag('update-image'));
				if (error.response?.data === 'Non existing user.') {
					navigate('login');
					dispatch(logout());
				}

				alert.show(
					`Something went wrong`,
					{ type: 'error' }
				);
		});

	}

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					component='form'
					onSubmit={handleSubmit}
					noValidate
					sx={style}
				>
				 <>
					<Typography sx={{ m: 0, p: 2 }}>CHANGE IMAGE</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
					<Box
						sx={{
							maxHeight: '60vh',
							overflow: 'scroll',
							paddingRight: '8px',
							'&::-webkit-scrollbar': {
								width: '2px',
								height: 0,
							},
							'&::-webkit-scrollbar-thumb': {
								borderRadius: '6px',
								backgroundColor: constants.ORANGE,
							},
						}}
					>
						{loading && <ModalLoader loading={loading} />}
						{generationMode === null && <>
					<Typography sx={{ m: 0, p: 2 }}>CHOOSE IMAGE GENERATION MODE</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
					
					<Button
						onClick={() => {
							setGenerationMode('1')
						}}
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
					>
						Manual
					</Button>
					<Button
						onClick={() => {
							setGenerationMode('2')
						}}
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
					>
						Scene
					</Button>
				</>}
					{generationMode === '1' && <>
						<TextField
							margin='normal'
							required
							fullWidth
							id='title'
							label='Title'
							name='title'
							autoFocus
							placeholder='Name Your Image'
							value={title}
							InputLabelProps={{ shrink: true }}
							onChange={(e) => setTitle(e.target.value)}
							onKeyPress={(ev) => {
								console.log(`Pressed keyCode ${ev.key}`);
								if (ev.key === 'Enter') {
								  ev.preventDefault();
								}
							  }}
						/>
						<FormControl
							fullWidth
							variant='outlined'
							style={{ marginTop: "10px" }}
						>
							<InputLabel
								variant='outlined'
								id='test-select-label'
								shrink={true}
							>
								Choose style *
							</InputLabel>
							<Select
								value={imageStyles}
								onChange={(e)=> setImageStyles(e.target.value)}
								labelId='test-select-label'
								variant='outlined'
								label='Choose style'
								fullWidth
								notched={true}
								InputLabelProps={{ shrink: true }}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 300,  
											maxWidth: '100%',
											overflow: 'auto', 
										},
									},
								}}
							>
								{imageStylesArray?.map(item => <MenuItem value={item}>{item}</MenuItem>)}
							</Select>
						</FormControl>
						<TextField
							margin='normal'
							required
							name='prompt'
							label='Describe what should be in the image'
							id='prompt'
							fullWidth
							minRows={4}
							maxRows={12}
							multiline
							value={prompt}
							onChange={(e) => {
								setPrompt(e.target.value);
							}}
							InputLabelProps={{ shrink: true }}
							onKeyPress={(ev) => {
								console.log(`Pressed keyCode ${ev.key}`);
								if (ev.key === 'Enter') {
								  ev.preventDefault();
								}
							  }}
						/>
						</>}
						{generationMode === '2' && <>
							<Box
								sx={{
									maxHeight: '60vh',
									overflow: 'scroll',
									paddingRight: '8px',
									'&::-webkit-scrollbar': {
										width: '2px',
										height: 0,
									},
									'&::-webkit-scrollbar-thumb': {
										borderRadius: '6px',
										backgroundColor: constants.ORANGE,
									},
								}}
							>
								<FormControl
									fullWidth
									variant='outlined'
									style={{ marginTop: "10px" }}
								>
									<InputLabel
										variant='outlined'
										id='test-select-label'
										shrink={true}
									>
										Choose style *
									</InputLabel>
									<Select
										value={imageStyles}
										onChange={(e)=> setImageStyles(e.target.value)}
										labelId='test-select-label'
										variant='outlined'
										label='Choose style'
										fullWidth
										notched={true}
										InputLabelProps={{ shrink: true }}
									>
										{imageStylesArray?.map(item => <MenuItem value={item}>{item}</MenuItem>)}
									</Select>
								</FormControl>
								<FormControl
									fullWidth
									variant='outlined'
									style={{ marginTop: "10px" }}
								>
									<InputLabel
										variant='outlined'
										id='test-select-label'
										shrink={true}
									>
										Choose story *
									</InputLabel>
									<Select
										value={storyValue}
										onChange={(e)=> setStoryValue(e.target.value)}
										labelId='test-select-label'
										variant='outlined'
										label='Choose story'
										fullWidth
										notched={true}
										InputLabelProps={{ shrink: true }}
									>
										{stories?.map(item => <MenuItem value={item?.story?.id}>{item?.story?.title}</MenuItem>)}
									</Select>
								</FormControl>
								{storyValue && 
								<FormControl
									fullWidth
									variant='outlined'
									style={{ marginTop: "10px" }}
								>
									<InputLabel
										variant='outlined'
										id='test-select-label'
										shrink={true}
									>
										Choose scene *
									</InputLabel>
									<Select
										value={sceneValue}
										onChange={(e)=> setSceneValue(e.target.value)}
										labelId='test-select-label'
										variant='outlined'
										label='Choose scene'
										fullWidth
										notched={true}
										InputLabelProps={{ shrink: true }}
									>
										{stories?.find(item => item?.story?.id === storyValue)?.story?.scenes?.map(item => <MenuItem value={item?.id}>{item?.scene_title}</MenuItem>)}
									</Select>
								</FormControl>
								}
								<TextField
									margin='normal'
									required
									name='prompt'
									label='Describe what should be in the image'
									id='prompt'
									fullWidth
									minRows={4}
									maxRows={12}
									multiline
									value={prompt}
									onChange={(e) => {
										setPrompt(e.target.value);
									}}
									InputLabelProps={{ shrink: true }}
									onKeyPress={(ev) => {
										console.log(`Pressed keyCode ${ev.key}`);
										if (ev.key === 'Enter') {
										  ev.preventDefault();
										}
									  }}
								/>
							</Box>
						</>}
						{generationMode !== null && <>
							{generatedLink.length > 1 && 
							/* <Box sx={{
								display: 'flex',
								justifyContent: 'center'
							}}>
								<CardMedia
									component='div'
									sx={{
										width: 256,
    									height: 256,   
									}}
									image={generatedLink}
									alt={'generated image'}
									onError={(e) => {
										e.target.src = placeholder;
									}}
								/>	
								</Box> */
								<Box sx={{
									display: 'flex',
									justifyContent: 'center',
									//width: '100%', // Set the width to 100% of the parent container
								}}>
									<CardMedia
										component="img"
										sx={{
											maxWidth: '90%',
                        					height: 'auto',
										}}
										image={generatedLink}
										alt={'generated image'}
										onError={(e) => {
											e.target.src = placeholder;
										}}
									/>  
								</Box>
							
						}
						</>}
						
					</Box>
					{generationMode !== null && <>
						<Button
							onClick={()=> handleGenerateImage()}
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2, color: '#fff' }}
						>
							regenerate
						</Button>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{ mt: 0, mb: 2, color: '#fff' }}
						>
							Save
						</Button>
					</>}		
					</>
				</Box>
			</Modal>
		</div>
	);
}
