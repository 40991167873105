import React, { useRef, useState, useEffect } from 'react';
import { fabric } from 'fabric';
import { Button, TextField, Grid, CssBaseline } from '@mui/material';
import Container from '@mui/material/Container';
import { useAlert } from 'react-alert';
import * as constants from '../../../helpers/constants'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MenuItem,	FormControl, InputLabel, Select } from '@mui/material';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import axios from 'axios';
import { logout } from '../../../store/slices/authSlice';
import deepObjectCompare from '../../../helpers/deepObjectCompare';

const EditPage = ({
    page,
    setCardsToRenderFunc,
    addNewImage,
    doesCardHaveUnsavedData,
    images,
    setImages,
    setTokensFillModal
  }) => {
  const canvasRef = useRef(null);
  const dispatch = useDispatch();
	const alert = useAlert();
	const navigate = useNavigate();
  const [panels, setPanels] = useState([]);
  const [currentText, setCurrentText] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const token = useSelector((state) => state.auth.token);
  const [selectedImage, setSelectedImage] = useState()
  const [canvasWasLoaded, setCanvasWasLoaded] = useState(false);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [isCanvasDataLoaded, setIsCanvasDataLoaded] = useState(false)
  const hideTokensFillModal = useSelector((state) => state.auth.hideTokensFillModal);

  function checkIfCardHasUnsavedData () {
		if (!page) return
		
    const canvasData = canvasRef.current?.toDatalessJSON();
    const jsonString = JSON.stringify(canvasData);

		const newCardData = {
			canvasData: jsonString
		}

    console.log(page.canvasData === jsonString)
		const isEqual = deepObjectCompare(newCardData,page)

		if (!isEqual) {
			doesCardHaveUnsavedData.current = true
		} else {
			doesCardHaveUnsavedData.current = false
		}
	}

  useEffect(() => {
    canvasRef.current = new fabric.Canvas('comic-canvas', {
      selection: false,
    });
    const updatePageWidth = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener('resize', updatePageWidth);

    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  useEffect(()=> {
    if (canvasRef.current && page?.canvasData && !canvasWasLoaded && page !== undefined) {
      const canvasData = JSON.parse(page?.canvasData);
      const calculatedPercentage = pageWidth > 620 ? 100 : (pageWidth - 48) * 100 / 600
      const convertedCanvasData = renderInPercentage(canvasData,calculatedPercentage);
      canvasRef.current.loadFromJSON(convertedCanvasData, () => {
        console.log('Canvas data loaded successfully');
        setIsCanvasDataLoaded(true)
      });
      setCanvasWasLoaded(true)
      canvasRef.current.on('object:moving',checkIfCardHasUnsavedData)
    } else if (canvasRef.current && !canvasWasLoaded && !page?.canvasData && page !== undefined) {
      setIsCanvasDataLoaded(true)
      setCanvasWasLoaded(true)
    }

  },[canvasRef.current, page?.canvasData])

  function renderInPercentage(canvasData, percentage) {
    return {
      version: canvasData.version,
      objects: canvasData.objects.map((object) => {
        const convertedObject = { ...object };
  
        if ('left' in convertedObject) {
          convertedObject.left = convertedObject.left * percentage / 100;
        }
        if ('top' in convertedObject) {
          convertedObject.top = convertedObject.top * percentage / 100;
        }
  
        // Adjusting width and height
        if ('scaleX' in convertedObject && 'scaleY' in convertedObject) {
          
          convertedObject.scaleX = convertedObject.scaleX * percentage / 100
          convertedObject.scaleY = convertedObject.scaleY * percentage / 100;
        }
  
        // Check if the object is a text object
        if (convertedObject.type === 'text' && 'fontSize' in convertedObject) {
          convertedObject.fontSize = convertedObject.fontSize * percentage / 100;
        }
  
        return convertedObject;
      }),
    };
  }

  function restorePercentage(canvasData, percentage) {
    return {
      version: canvasData.version,
      objects: canvasData.objects.map((object) => {
        const convertedObject = { ...object };
  
        if ('left' in convertedObject) {
          convertedObject.left = convertedObject.left * 100 / percentage;
        }
        if ('top' in convertedObject) {
          convertedObject.top = convertedObject.top * 100 / percentage;
        }
  
        // Adjusting width and height
        if ('scaleX' in convertedObject && 'scaleY' in convertedObject) {
          convertedObject.scaleX = convertedObject.scaleX * 100 / percentage;
          convertedObject.scaleY = convertedObject.scaleY * 100 / percentage;
        }
  
        // Check if the object is a text object
        if (convertedObject.type === 'text' && 'fontSize' in convertedObject) {
          convertedObject.fontSize = convertedObject.fontSize * 100 / percentage;
        }
  
        return convertedObject;
      }),
    };
  }
  
  const handleAddCustom = () => {
    const points = [
      { x: 3, y: 4 },
      { x: 16, y: 3 },
      { x: 30, y: 5 },
      { x: 25, y: 55 },
      { x: 19, y: 44 },
      { x: 15, y: 30 },
      { x: 15, y: 55 },
      { x: 9, y: 55 },
      { x: 6, y: 53 },
      { x: -2, y: 55 },
      { x: -4, y: 40 },
      { x: 0, y: 20 },
    ];

    const polygon = new fabric.Polygon(points, {
      left: 100,
      top: 50,
      fill: '#D81B60',
      strokeWidth: 0,
      scaleX: 4,
      scaleY: 4,
      objectCaching: false,
      transparentCorners: false,
      cornerColor: 'blue',
    });

    // canvasRef.current.viewportTransform = [0.7, 0, 0, 0.7, -50, 50];
    canvasRef.current.add(polygon);
  }

  function polygonPositionHandler(dim, finalMatrix, fabricObject) {
    const x = fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x;
    const y = fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;
    return fabric.util.transformPoint(
      { x: x, y: y },
      fabric.util.multiplyTransformMatrices(
        fabricObject.canvas.viewportTransform,
        fabricObject.calcTransformMatrix()
      )
    );
  }

  function actionHandler(eventData, transform, x, y) {
    const polygon = transform.target;
    const currentControl = polygon.controls[polygon.__corner];
    const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
    const polygonBaseSize = getObjectSizeWithStroke(polygon);
    const size = polygon._getTransformedDimensions(0, 0);
    const finalPointPosition = {
      x: (mouseLocalPosition.x * polygonBaseSize.x) / size.x + polygon.pathOffset.x,
      y: (mouseLocalPosition.y * polygonBaseSize.y) / size.y + polygon.pathOffset.y,
    };
    polygon.points[currentControl.pointIndex] = finalPointPosition;
    return true;
  }

  function getObjectSizeWithStroke(object) {
    const stroke = new fabric.Point(
      object.strokeUniform ? 1 / object.scaleX : 1,
      object.strokeUniform ? 1 / object.scaleY : 1
    ).multiply(object.strokeWidth);
    return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
  }

  function anchorWrapper(anchorIndex, fn) {
    return function (eventData, transform, x, y) {
      const fabricObject = transform.target;
      const absolutePoint = fabric.util.transformPoint(
        {
          x: fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
          y: fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y,
        },
        fabricObject.calcTransformMatrix()
      );
      const actionPerformed = fn(eventData, transform, x, y);
      const newDim = fabricObject._setPositionDimensions({});
      const polygonBaseSize = getObjectSizeWithStroke(fabricObject);
      const newX =
        (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x;
      const newY =
        (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
      fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
      return actionPerformed;
    };
  }

  const Edit = () => {
    const poly = canvasRef.current.getActiveObject();
    canvasRef.current.setActiveObject(poly);
    console.log(poly)
    if (poly && poly.type === 'polygon') {
      poly.edit = !poly.edit;
      if (poly.edit) {
        const lastControl = poly.points.length - 1;
        poly.cornerStyle = 'circle';
        poly.cornerColor = 'rgba(0,0,255,0.5)';
        poly.controls = poly.points.reduce(function (acc, point, index) {
          acc['p' + index] = new fabric.Control({
            positionHandler: polygonPositionHandler,
            actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
            actionName: 'modifyPolygon',
            pointIndex: index,
          });
          return acc;
        }, {});
      } else {
        poly.cornerColor = 'blue';
        poly.cornerStyle = 'rect';
        poly.controls = fabric.Object.prototype.controls;
      }
      poly.hasBorders = !poly.edit;
      canvasRef.current.requestRenderAll();
    }
  };

  const handleAddPanel = () => {
    const calculatedWidth = pageWidth > 620 ? 1 : (pageWidth - 48) / 600
    const scaleY = calculatedWidth * 0.2
    const scaleX = calculatedWidth * 0.29
    const panel = new fabric.Rect({
      left: calculatedWidth * 160,
      top: (calculatedWidth * calculatedWidth) * 300,
      width: 1024,
      height: 1024,
      fill: 'white',
      stroke: 'black',
      strokeWidth: 2,
      hasControls: true,
      hasBorders: true,
      scaleX: scaleX,
      scaleY: scaleY
    });

    canvasRef.current.add(panel);
    setPanels([...panels, panel]);
    checkIfCardHasUnsavedData()
  };

  const handleAddText = async () => {
  
    const text = new fabric.Text(currentText, {
      left: 10,
      top: 10,
      fontSize: 16,
      selectable: true,
    });

    canvasRef.current.add(text);
    await canvasRef.current.renderAll();
    checkIfCardHasUnsavedData()
    
  };

  const handleTextChange = (e) => {
    setCurrentText(e.target.value);
    checkIfCardHasUnsavedData()
  };

  const handleRemovePanel = () => {
    const activePanel = canvasRef.current.getActiveObject();

    if (activePanel && (activePanel.type === 'rect' || activePanel.type === 'polygon') ) {
      canvasRef.current.remove(activePanel);
      setPanels((prevPanels) => prevPanels.filter((panel) => panel !== activePanel));
    }
    checkIfCardHasUnsavedData()
  };

  const handleRemoveText = () => {
    const activeObject = canvasRef.current.getActiveObject();
  
    if (activeObject && activeObject.type === 'text') {
      canvasRef.current.remove(activeObject);
      canvasRef.current.renderAll();
    }
    checkIfCardHasUnsavedData()
  };

  const isValidImageUrl = async (url) => {
    const proxyUrl = constants.BASE_URL + 'proxy?url=';
    try {
      const response = await fetch(proxyUrl + encodeURIComponent(url));
      console.log(response)
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  
  const handleAddImage = async (selectedImage) => {
    const activePanel = canvasRef.current.getActiveObject();
  
    console.log(activePanel.type)
    const imageToInsert = selectedImage ? selectedImage : imageUrl
    if (activePanel && activePanel.type === 'rect') {
      if (await isValidImageUrl(imageToInsert)) {
        fabric.Image.fromURL(
          imageToInsert,
          (img) => {
            // Handle successful image loading
            const activePanel = canvasRef.current.getActiveObject();
  
            if (activePanel && activePanel.type === 'rect') {
              activePanel.set(
                'fill',
                new fabric.Pattern(
                  {
                    source: img.getElement(),
                    repeat: 'no-repeat',
                  },
                  null,
                  { crossOrigin: 'Anonymous' }
                )
              );
  
              canvasRef.current.renderAll();
            }
          },
          (err) => {
            alert.show(`Something went wrong with the image link`, {
              type: 'error',
            });
          },
          { crossOrigin: 'Anonymous' }
        );
      } else {
        alert.show(`Invalid image URL or unable to load image`, {
          type: 'error',
        });
      }
    } else if (activePanel && activePanel.type === 'polygon') {
      if (await isValidImageUrl(imageToInsert)) {
          fabric.Image.fromURL(
              imageToInsert,
              (img) => {
                  // Handle successful image loading
                  const activePanel = canvasRef.current.getActiveObject();
  
                  if (activePanel && activePanel.type === 'polygon') {
                      // Create a fabric polygon object as the clip path
                      const points = activePanel.points; // Use the points of the polygon as the clip path
                      const clipPath = new fabric.Polygon(points, {
                          fill: 'white', // Define a fill color for the clip path
                          strokeWidth: 0 // Set strokeWidth to 0 to make the clip path invisible
                      });
  
                      // Set the clip path for the image
                      img.set({ clipPath: clipPath });
  
                      // Set the fill pattern for the polygon
                      activePanel.set({
                          fill: new fabric.Pattern({
                              source: img.getElement(),
                              repeat: 'no-repeat',
                          })
                      });
  
                      // Add the image and the clip path to the canvas
                      canvasRef.current.add(img, clipPath);
  
                      // Render the canvas
                      canvasRef.current.renderAll();
                  }
              },
              (err) => {
                  alert.show(`Something went wrong with the image link`, {
                      type: 'error',
                  });
              },
              { crossOrigin: 'Anonymous' }
          );
      } else {
          alert.show(`Invalid image URL or unable to load image`, {
              type: 'error',
          });
      }
  }  
    checkIfCardHasUnsavedData()
  };
  

  const handleImageUrlChange = (e) => {
    setImageUrl(e.target.value);
  };

  const handleSavePage = async () => {
    const canvasData = canvasRef.current?.toDatalessJSON();
    const calculatedPercentage = pageWidth > 620 ? 100 : (pageWidth - 48) * 100 / 600
    const convertedCanvasData = restorePercentage(canvasData,calculatedPercentage);
    const jsonString = JSON.stringify(convertedCanvasData);
    dispatch(addLoadingFlag('update-page'))
			await axios
					.post(
						constants.BASE_URL +
							'api/projects/update-page-by-id/' +
							page.id,
						{
              canvasData: jsonString
            },
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(function (response) {
						dispatch(removeLoadingFlag('update-page'));
						alert.show(
							`Page was successfully saved!`,
							{ type: 'success' }
						);
            doesCardHaveUnsavedData.current = false
						setCardsToRenderFunc();
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('update-page'));
						if (error.response?.data === 'Non existing user.') {
							navigate('login');
							dispatch(logout());
						}
					
							alert.show(
								`Something went wrong, could not add illustrations.`,
								{ type: 'error' }
							);
						
					});
  };

  const toggleClippingMode = () => {
    const canvas = canvasRef.current;
    
    fabric.Image.fromURL('http://localhost:8080/api/projects/imagery/655dbd876eee8dd8683e9312', function(img) {
      const points = [
        { x: 3, y: 4 },
        { x: 16, y: 3 },
        { x: 30, y: 5 },
        { x: 25, y: 55 },
        { x: 19, y: 44 },
        { x: 15, y: 30 },
        { x: 15, y: 55 },
        { x: 9, y: 55 },
        { x: 6, y: 53 },
        { x: -2, y: 55 },
        { x: -4, y: 40 },
        { x: 0, y: 20 },
      ];
  
      var shell = new fabric.Polygon(points, {
        left: 100,
        top: 50,
        strokeWidth: '0.5',
        scaleX: 1,
        scaleY: 1,
        objectCaching: false,
        transparentCorners: false,
        cornerColor: 'black',
        fill: 'rgba(0,0,0,0)',
      });

      var clipPath = new fabric.Polygon(points, {
        absolutePositioned: true,
        originX: 'center',
        originY: 'center',
        left: 100,
        top: 50,
        strokeWidth: 0,
        scaleX: 4,
        scaleY: 4,
        objectCaching: false,
        transparentCorners: false,
      });
  
      img.scale(0.5).set({
        left: 100,
        top: 50,
        clipPath: clipPath
      });

      shell.on('moving', ({ e, transform, pointer }) => {
        //  only because they are absolutePositioned
        clipPath.setPositionByOrigin(shell.getCenterPoint(), 'center', 'center');
        img.set('dirty', true);
      });
      shell.on('rotating', () => {
        clipPath.set({ angle: shell.angle });
        img.set('dirty', true);
      });

      img.clipPath = clipPath;
      canvas.add(img, shell);
      canvas.setActiveObject(img);
  
    });

  };

  function handleOpenMenu () {
    setMenuIsOpen(prev => !prev)
  }

  return (
    <Container component="main" spacing={2} maxWidth='lg' sx={{padding: '0 0 50px 0'}}>
    <CssBaseline />
    <div
      style={{
        display: 'flex',
        gap: '20px'
      }}
    >
      <Button
					onClick={() => {
						handleOpenMenu();
					}}
					sx={{
						color: 'white',
						display: {
							xs: 'block',
							md: 'none',
						},
						borderRadius: '8px',
						height: '40px',
						textDecoration: 'underline',
            position: 'absolute',
            top: '-40px',
            right: '0'
					}}
				>
					{'Menu'}
				</Button>
       <Grid item xs={6}>
        <div style={{ overflow: 'hidden' }}>
          <canvas id="comic-canvas" width={pageWidth < 700 ? pageWidth - 48 : 600} height={pageWidth < 700 ? (pageWidth - 48) * 1.2 : 800} style={{ border: '2px solid white'}}/>
        </div>
      </Grid>
      <Grid item xs={3} sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginLeft: menuIsOpen ? '-200px' : '0',
        backgroundColor: '#2f3944',
        minWidth: '195px',
        transition: '0.4s',
      }}>
        <Button variant="contained" sx={{color: '#fff' }} onClick={handleAddPanel} disabled={!isCanvasDataLoaded}>
          Add Panel
        </Button>
        <Button variant="contained" sx={{color: '#fff' }} onClick={handleAddCustom} disabled={!isCanvasDataLoaded}>
          Add Custom
        </Button>
        <Button variant="contained" sx={{color: '#fff' }} onClick={Edit} disabled={!isCanvasDataLoaded}>
          Change mode
        </Button>
        <Button variant="contained" sx={{color: '#fff' }} onClick={toggleClippingMode} disabled={!isCanvasDataLoaded}>
          Change mode 1
        </Button>

        <TextField
          type="text"
          value={currentText}
          onChange={handleTextChange}
          placeholder="Add Text"
        />
        <Button variant="contained" sx={{color: '#fff' }} onClick={handleAddText} disabled={!isCanvasDataLoaded}>
          Add Text
        </Button>
        <Button variant="contained" sx={{color: '#fff' }} onClick={handleRemovePanel} disabled={!isCanvasDataLoaded}>
          Remove Panel
        </Button>
        <Button variant="contained" sx={{color: '#fff' }} onClick={handleRemoveText} disabled={!isCanvasDataLoaded}>
          Remove Text
        </Button>
        <TextField
          type="text"
          value={imageUrl}
          onChange={handleImageUrlChange}
          placeholder="Image URL"
        />
        <Button variant="contained" sx={{color: '#fff' }} onClick={handleAddImage} disabled={!isCanvasDataLoaded}>
          Add Image
        </Button>
        <Button variant="contained" sx={{color: '#fff' }} onClick={addNewImage}>
          Generate Image
        </Button>
        <Button variant="contained" sx={{color: '#fff' }} onClick={handleSavePage} disabled={!isCanvasDataLoaded}>
          Save
        </Button>
        <FormControl
            fullWidth
            variant='outlined'
            style={{ marginTop: "10px" }}
          >
            <InputLabel
              variant='outlined'
              id='test-select-label'
              shrink={true}
            >
              Select image
            </InputLabel>
            <Select
              value={selectedImage}
              onChange={(e)=> setSelectedImage(e.target.value)}
              labelId='test-select-label'
              variant='outlined'
              label='Select image'
              fullWidth
              notched={true}
              InputLabelProps={{ shrink: true }}
            >
              {images?.map((item,index) => <MenuItem key={index} value={item?.image?.image}>{item?.image?.title}</MenuItem>)}
            </Select>
          </FormControl>
          <Button variant="contained" sx={{color: '#fff' }} disabled={!isCanvasDataLoaded} onClick={() => {
            handleAddImage(constants.IMAGE_URL + selectedImage)
          }}>
            Add selected image
          </Button>
          {/* <Button variant="contained" sx={{color: '#fff' }} onClick={() => {
            setImages([])
          }}>
            setImage
          </Button> */}
      </Grid>
    </div>
    
  </Container>
  );
};

export default EditPage;

