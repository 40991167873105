import { createSlice } from '@reduxjs/toolkit';
// import spiderMan from '../../assets/images/spider-man.png'
// import ironMan from '../../assets/images/iron-man.jpeg'
// import batman from '../../assets/images/batman.jpeg'
// import superman from '../../assets/images/superman.jpeg'
// import hulk from '../../assets/images/hulk.jpeg'
// import {pages, characters, setting} from '../projectsMockData';

// const reversedPages = [...pages[0]].reverse()
// const initialState = {
//   projects: [
//     {
//         id: '1',
//         title: 'Project 1',
//         image: spiderMan,
//         images: pages[0],
//         characters: characters[0],
//         description: 'sci-fi',
//         setting: setting[0],
//         episodes: [
//           {
//             title: 'Episode 1',
//             images: pages[0],
//             characters: characters[0],
//             setting: setting[0],
//           },
//           {
//             title: 'Episode 2',
//             images: pages[0],
//             characters: characters[0],
//             setting: setting[0],
//           },
//         ]
//     },
//     {
//         id: '2',
//         title: 'Project 2',
//         image: ironMan,
//         images: pages[1],
//         characters: characters[1],
//         description: 'sci-fi',
//         setting: setting[0],
//         episodes: [
//           {
//             title: 'Episode 1',
//             images: reversedPages,
//             characters: characters[0],
//             setting: setting[0],
//           },
//           {
//             title: 'Episode 2',
//             images: pages[0],
//             characters: characters[0],
//             setting: setting[0],
//           },
//         ]
//     },
//     {
//         id: '3',
//         title: 'Project 3',
//         image: batman,
//         images: pages[2],
//         characters: characters[2],
//         description: 'sci-fi',
//         setting: setting[0],
//         episodes: [
//           {
//             title: 'Episode 1',
//             images: pages[0],
//             characters: characters[0],
//             setting: setting[0],
//           },
//           {
//             title: 'Episode 2',
//             images: pages[0],
//             characters: characters[0],
//             setting: setting[0],
//           },
//         ]
//     },
//     {
//         id: '4',
//         title: 'Project 4',
//         image: superman,
//         images: pages[3],
//         characters: characters[3],
//         description: 'sci-fi',
//         setting: setting[0],
//         episodes: [
//           {
//             title: 'Episode 1',
//             images: pages[0],
//             characters: characters[0],
//             setting: setting[0],
//           },
//           {
//             title: 'Episode 2',
//             images: pages[0],
//             characters: characters[0],
//             setting: setting[0],
//           },
//         ]
//     },
//     {
//         id: '5',
//         title: 'Project 5',
//         image: hulk,
//         images: pages[4],
//         characters: characters[4],
//         description: 'sci-fi',
//         setting: setting[0],
//         episodes: [
//           {
//             title: 'Episode 1',
//             images: pages[0],
//             characters: characters[0],
//             setting: setting[0],
//           },
//           {
//             title: 'Episode 2',
//             images: pages[0],
//             characters: characters[0],
//             setting: setting[0],
//           },
//         ]
//     },
//   ],
// };

const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    projects: []
  },
  reducers: {
    overrideProjects:  (state, action) => {
      state.projects = action.payload
    },
    changeProjectsOrder:  (state, action) => {
      state.projects = action.payload
    },
    addNewProject: (state, action) => {
      state.projects = [...state.projects, action.payload]
    },
    addNewEpisode: (state, action) => {
      state.projects = state.projects.map(item => {
        if (item.id === action.payload.projectId) {
          const episodes = [...item.episodes]
          episodes.push(action.payload.newEpisode)
          return {...item, episodes: episodes}
        } else {
          return item
        }
      })
    },
    deleteEpisode: (state, action) => {
      state.projects = state.projects.map(item => {
        if (item.id === action.payload.projectId) {
          const episodes = [...item.episodes]
          episodes.splice(action.payload.episodeId,1)
          return {...item, episodes: episodes}
        } else {
          return item
        }
      })
    },
    changeEpisode: (state, action) => {
      state.projects = state.projects.map(item => {
        if (item.id === action.payload.projectId) {
          const episodes = [...item.episodes]
          episodes[action.payload.episodeId].title = action.payload.newEpisodeTitle
          return {...item, episodes: episodes}
        } else {
          return item
        }
      })
    },
    changeProject: (state, action) => {
      state.projects = state.projects.map(item => item.id === action.payload.projectId ? action.payload.project : item)
    },
    deleteProject: (state, action) => {
      state.projects = state.projects.filter(item => item.id !== action.payload )
    },
    addNewPage: (state, action) => {
      state.projects = state.projects.map(item => {
        if (item.id === action.payload.projectId) {
          if (!isNaN(action.payload.epizodeIndex)) {
            const episodes = [...item.episodes]
            episodes[action.payload.epizodeIndex].images.push(action.payload.image)
            return {...item, episodes: episodes}
          } else {
            return {...item, images: [...item?.images,action.payload.image]}
          }
        } else {
          return item
        }
      })
    },
    deletePage: (state, action) => {
      state.projects = state.projects.map(item => {
        if (item.id === action.payload.projectId) {
          if (!isNaN(action.payload.epizodeIndex)) {
            const episodes = [...item.episodes]
            const newPages = episodes[action.payload.epizodeIndex].images.filter((_,index) => index !== action.payload.imageIndex)
            episodes[action.payload.epizodeIndex].images = newPages
            return {...item, episodes: episodes}
          } else {
            return {...item,images: item.images.filter((_,index) => index !== action.payload.imageIndex)}
          }
        } else {
          return item
        }
      })
    },
    changeCharacter: (state, action) => {
      state.projects = state.projects.map(item => {
        if (item.id === action.payload.projectId) {
          const newCharacter = action.payload.newCharacter
          if (!isNaN(action.payload.epizodeIndex)) {
            const episodes = [...item.episodes]
            const newCharacters = [...episodes[action.payload.epizodeIndex].characters]
            newCharacters[action.payload.characterId] = newCharacter
            episodes[action.payload.epizodeIndex].characters = newCharacters
            return {...item, episodes: episodes} 
          } else {
            const newCharacters = [...item.characters]
            newCharacters[action.payload.characterId] = newCharacter
            return {...item, characters: newCharacters} 
          }
         
        } else {
          return item
        }
      })
    },
    changeSettingItem: (state, action) => {
      state.projects = state.projects.map(item => {
        if (item.id === action.payload.projectId) {
          const newSettingItem = action.payload.newSettingItem
          if (!isNaN(action.payload.epizodeIndex)) {
            const episodes = [...item.episodes]
            const newSetting = [...episodes[action.payload.epizodeIndex].setting]
            newSetting[action.payload.settingItemId] = newSettingItem
            episodes[action.payload.epizodeIndex].setting = newSetting
            return {...item, episodes: episodes} 
          } else {
            const newSetting = [...item.setting]
            newSetting[action.payload.settingItemId] = newSettingItem
            return {...item, setting: newSetting} 
          }
        } else {
          return item
        }
      })
    },
    deleteCharacter: (state, action) => {
      state.projects = state.projects.map(item => {
        if (item.id === action.payload.projectId) {
          if (!isNaN(action.payload.epizodeIndex)) {
            const episodes = [...item.episodes]
            const newCharacters = [...episodes[action.payload.epizodeIndex].characters]
            newCharacters.splice(action.payload.characterId,1)
            episodes[action.payload.epizodeIndex].characters = newCharacters
            return {...item, episodes: episodes} 
          } else {
            const newCharacters = [...item.characters]
            newCharacters.splice(action.payload.characterId,1)
            return {...item, characters: newCharacters} 
          }
       
        } else {
          return item
        }
      })
    },
    addNewCharacter: (state, action) => {
      state.projects = state.projects.map(item => {
        if (item.id === action.payload.projectId) {
          if (!isNaN(action.payload.epizodeIndex)) {
            const episodes = [...item.episodes]
            episodes[action.payload.epizodeIndex].characters.push(action.payload.newCharacter)
            return {...item, episodes: episodes}
          } else {
            return {...item, characters: [...item?.characters, action.payload.newCharacter]} 
          }
        } else {
          return item
        }
      })
    },
    deleteSettingItem: (state, action) => {
      state.projects = state.projects.map(item => {
        if (item.id === action.payload.projectId) {
          if (!isNaN(action.payload.epizodeIndex)) {
            const episodes = [...item.episodes]
            const newSetting = [...episodes[action.payload.epizodeIndex].setting]
            newSetting.splice(action.payload.settingItemId,1)
            episodes[action.payload.epizodeIndex].setting = newSetting
            return {...item, episodes: episodes} 
          } else {
            const newSetting = [...item.setting]
            newSetting.splice(action.payload.settingItemId,1)
            return {...item, setting: newSetting}
          }
        } else {
          return item
        }
      })
    },
    addNewSettingItem: (state, action) => {
      state.projects = state.projects.map(item => {
        if (item.id === action.payload.projectId) {
          if (!isNaN(action.payload.epizodeIndex)) {
            const episodes = [...item.episodes]
            episodes[action.payload.epizodeIndex].setting.push(action.payload.newSettingItem)
            return {...item, episodes: episodes}
          } else {
            return {...item, setting: [...item?.setting, action.payload.newSettingItem]} 
          }
        } else {
          return item
        }
      })
    },
  },
});

export const {
  changeProjectsOrder,
  addNewProject,
  changeProject,
  deleteProject,
  addNewPage,
  deletePage,
  changeCharacter,
  deleteCharacter,
  addNewCharacter,
  addNewSettingItem,
  deleteSettingItem,
  changeSettingItem,
  addNewEpisode,
  deleteEpisode,
  changeEpisode,
  overrideProjects,
 } = projectsSlice.actions;
export default projectsSlice.reducer;
