import React from 'react'
import { JellyfishSpinner } from 'react-spinners-kit'
import CssBaseline from '@mui/material/CssBaseline';
import * as constants from '../../helpers/constants'
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

export default function Loader() {
  const loading = useSelector((state) => state.loading.loading);
  const theme = useTheme(); 

  return (
    <>
    <CssBaseline/>
    <div style={{
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: '9999999',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '100vh',
        minWidth: '100vw',
        background: constants.DARK_GREY,
        zIndex: '99999999'}}>
        <JellyfishSpinner size={300} color={theme.palette.primary.main} loading={loading} />
      </div>
    </>
  )
}
