import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';
import * as constants from './helpers/constants';
import { useState, useMemo } from 'react';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffffff', 
    },
    secondary: {
      main: '#ffffff', 
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: constants.PRIMARY, 
      darker: constants.PRIMARY_DARK, // Look into bringing this into compliance
    },
    secondary: {
      main: constants.SECONDARY, 
    },
    background: {
      default: constants.DARK_GREY, 
    },
    text: {
      primary: constants.TEXT, 
    },
  },
});

function Root() {
  const [mode, setMode] = useState('dark');

  const theme = useMemo(() => (mode === 'light' ? lightTheme : darkTheme), [mode]);

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App toggleTheme={toggleTheme} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ReactDOM.createRoot(document.getElementById('root')).render(<Root />);
