import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import { FormControl, InputLabel, Typography } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import * as constants from '../../../helpers/constants';
import useQuery from '../../../helpers/hooks/useQuery';
import { logout } from '../../../store/slices/authSlice';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import imageCompression from 'browser-image-compression';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '100%',
		sm: '400px',
	},
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export default function AddNewFolder({
	open,
	setOpen,
	episodesArray,
	setCardsToRenderFunc,
	cardsToRenderLength
}) {
	const handleClose = () => setOpen(false);
	const stateLoading = useSelector((state) => state.loading.loading);
	const dispatch = useDispatch();
	const location = useLocation();
	const alert = useAlert();
	const query = useQuery();
	const navigate = useNavigate();
	const episodesQueryValue = query.get('episodes');
	const token = useSelector((state) => state.auth.token);
	const projectId = () =>
		location.pathname.slice(9, location.pathname.length);
	const [title, setTitle] = useState();
	const [searchParams, setSearchParams] = useSearchParams();

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (stateLoading) {
			return
		}
		const data = {
			title,
			order: cardsToRenderLength
		};

		if (title) {
			dispatch(addLoadingFlag('set-folder'));
			await request()

		}

		async function request () {
				await axios
					.post(
						constants.BASE_URL +
							'api/projects/set-folder/' +
							projectId(),
						data,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(function (response) {
						handleClose();
						const params = {};
						for(let entry of searchParams.entries()) {
						  params[entry[0]] = entry[1];
						}
						console.log(params)
						setSearchParams({...params, item: cardsToRenderLength})
						alert.show(
							'New folder was successfully added!',
							{
								type: 'success',
							}
						);
						dispatch(removeLoadingFlag('set-folder'));
						setCardsToRenderFunc();
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('set-folder'));
						if (error.response?.data === 'Non existing user.') {
							navigate('login');
							dispatch(logout());
						}
				
						alert.show(
							'Somewthing went wrong!',
							{
								type: 'error',
							}
						);
					});
			}
	};

	
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					component='form'
					onSubmit={handleSubmit}
					noValidate
					sx={style}
				>
					<Typography sx={{ m: 0, p: 2 }}>
						ADD NEW IMAGES FOLDER
					</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
					<Box
						sx={{
							maxHeight: '60vh',
							overflow: 'scroll',
							paddingRight: '8px',
							position: 'relative',
							'&::-webkit-scrollbar': {
								width: '2px',
								height: 0,
							},
							'&::-webkit-scrollbar-thumb': {
								borderRadius: '6px',
								backgroundColor: constants.ORANGE,
							},
						}}
					>
						<TextField
							margin='normal'
							required
							fullWidth
							id='title'
							label='Title'
							name='title'
							autoFocus
							placeholder='Story 1 Scene 2'
							value={title}
							InputLabelProps={{ shrink: true }}
							onChange={(e) => setTitle(e.target.value)}
						/>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2, color: '#fff' }}
						>
							Create
						</Button>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
