import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useSelector ,
   useDispatch
   } from 'react-redux';
import axios from 'axios';
import * as constants from '../../helpers/constants'
import Loader from '../../components/Loader';
import Button from '@mui/material/Button';
import { addLoadingFlag, removeLoadingFlag } from '../../store/slices/loadingSlice';
import { useAlert } from 'react-alert';
import IconButton from '@mui/material/IconButton';
import {StyledBadge} from './style'
import Avatar from '@mui/material/Avatar';
import ChangePassword from '../../components/modals/ChangePassword';
import { setTokens } from '../../store/slices/authSlice';
import Checkbox from '@mui/material/Checkbox';

const User = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loading);
  const alert = useAlert();
  const [user, setUser] = useState()
  const [changeModalOpen, setChangeModalOpen] = useState(false)
  const currentFullUrlWithoutParams = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const [autoFillTokensFlag,setAutoFillTokensFlag] = useState(false);

  useEffect(()=>{
    getUser()
  },[token]) 

  console.log('token',token)
  const getUser = () => {
    dispatch(addLoadingFlag('get-user-info'));
    axios
    .get(
      constants.BASE_URL + 'api/auth/get-user-info',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(function (response) {
      dispatch(removeLoadingFlag('get-user-info'));
      console.log(response.data)
      setUser(response.data)
      setAutoFillTokensFlag(response.data?.autoFillTokensFlag)
    }).catch(function (error) {
      console.log(error);
      dispatch(removeLoadingFlag('get-user-info'));
    });
  }

  const changeUserSubscriptionPlan = async () => {
    dispatch(addLoadingFlag('change-plan'));
    await axios
      .post(constants.BASE_URL + 'api/auth/change-user-subscription-plan', {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      } ).then(function (response) {
        dispatch(removeLoadingFlag('change-plan'));
        alert.show(
          `Subscription changed`,
          { type: 'success' }
        );
        setUser(prev => { return {...prev,subscriptionPlan: 'Pro'}})

      }).catch(function (error) {
        console.log(error);
        dispatch(removeLoadingFlag('change-plan'));
      });
    }

  const createUserSubscriptionBill = async () => {

    dispatch(addLoadingFlag('get-users'));
    await axios
      .post(constants.BASE_URL + 'api/auth/create-user-subscription-plan-bill', {
       successUrl: currentFullUrlWithoutParams,
       cancelUrl: currentFullUrlWithoutParams,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      } ).then(function (response) {
        dispatch(removeLoadingFlag('get-users'));
        if (response.data === 'subscription active') {
          changeUserSubscriptionPlan()
        } else {
          window.location.replace(response.data);
        }
      }).catch(function (error) {
        console.log(error);
        dispatch(removeLoadingFlag('get-prompts'));
      });
    }

  const createUserAddingTokensBill = async () => {
    dispatch(addLoadingFlag('add-tokens'));
    await axios
      .post(constants.BASE_URL + 'api/auth/add-tokens', {
       successUrl: currentFullUrlWithoutParams,
       cancelUrl: currentFullUrlWithoutParams,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      } ).then(function (response) {
        dispatch(removeLoadingFlag('add-tokens'));
        if (response.data === 'subscription not bought') {
          alert.show(
            `Subscription not bought`,
            { type: 'error' }
          );
        } else if (response.data === 'subscription not active') {
          alert.show(
            `Subscription not active`,
            { type: 'error' }
          );
        }else {
          window.location.replace(response.data);
        }
      }).catch(function (error) {
        console.log(error);
        dispatch(removeLoadingFlag('add-tokens'));
      });
    }

  const autoTokensfill = async () => {
    dispatch(addLoadingFlag('add-tokens'));
    await axios
      .post(constants.BASE_URL + 'api/auth/add-automatically-tokens',{}, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      } ).then(function (response) {
        dispatch(removeLoadingFlag('add-tokens'));
        if (response.data === 'subscription not bought') {
          alert.show(
            `Subscription not bought`,
            { type: 'error' }
          );
        } else if (response.data === 'subscription not active') {
          alert.show(
            `Subscription not active`,
            { type: 'error' }
          );
        }else {
          alert.show(
            `Tokens successfully added`,
            { type: 'success' }
          );
          getUserTokens()
        }
      }).catch(function (error) {
        console.log(error);
        dispatch(removeLoadingFlag('add-tokens'));
      });
    }

  const resetUserSubscriptionPlan = async (userId) => {
    dispatch(addLoadingFlag('reset-subscription'));
    await axios
      .post(constants.BASE_URL + 'api/auth/reset-user-subscription-plan', {
       userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      } ).then(function (response) {
        dispatch(removeLoadingFlag('reset-subscription'));
        alert.show(
          `Subscription canceled`,
          { type: 'success' }
        );
        getUser()
      }).catch(function (error) {
        console.log(error);
        dispatch(removeLoadingFlag('reset-subscription'));
      });
    }
  const resetCancelUserSubscriptionPlan = async (userId) => {
    dispatch(addLoadingFlag('reset-subscription'));
    await axios
      .post(constants.BASE_URL + 'api/auth/reset-cancel-user-subscription-plan', {
       userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      } ).then(function (response) {
        dispatch(removeLoadingFlag('reset-subscription'));
        alert.show(
          `Subscription continued`,
          { type: 'success' }
        );
        getUser()
      }).catch(function (error) {
        console.log(error);
        dispatch(removeLoadingFlag('reset-subscription'));
      });
    }
    const getUserTokens = () => {
      axios
      .get(
        constants.BASE_URL + 'api/auth/get-user-info',
        {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        }
      ).then(function (response) {
        console.log(response.data)
        dispatch(setTokens(response.data?.tokens))
      }).catch(function (error) {
        console.log(error);
      });
      }

      async function changeFlag (flagValue) {
        await axios
          .post(constants.BASE_URL + 'api/auth/change-user-tokens-auto-fill-flag', {
            flagValue
          }, {
            headers: {
            'Authorization': `Bearer ${token}`
            },
          } ).then(function (response) {
          }).catch(function (error) {
            console.log(error);
          });
      }    

    const convertTimestamp = (timestamp) => {
      const date = new Date(timestamp * 1000);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    if (loading) {
      return <Loader />
    }
    return (
        <Container component="main" maxWidth='lg'>
          <CssBaseline />
          <ChangePassword open={changeModalOpen} setOpen={setChangeModalOpen} />
          {user && <>
              <Box
                sx={{
                  marginTop: {
                    xs: 2,
                    md: 8,
                  },
                  marginBottom: 4,
                  display: {
                    xs: 'flex',
                  },
                  alignItems: 'center',
                  justifyContent: {
                    md: 'space-between',
                    xs: 'space-around',
                  },
                  flexDirection: 'column',
                  flexWrap: {
                    xs: 'wrap',
                  },
                  gridTemplateColumns: '1fr 1fr',
                  gap: '30px 0',
                  position: 'relative',
                  paddingBottom: '100px',
                }}
              >
                <Box
                  sx={{
                    position: 'fixed',
                    bottom: '-1px',
                    padding: '10px',
                    left: '0',
                    zIndex: '10',
                    display: 'flex',
                    justifyContent: 'center',
                    right: '0',
                    width: '100%',
                    background: constants.DARK_GREY
                  }}
                >
              </Box>
              
              <IconButton sx={{ p: 0 }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
              >
                 <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                  >
                    <Avatar sx={{width: '200px', height: '200px'}} src="/static/images/avatar/1.jpg" />
                  </StyledBadge>
              </StyledBadge>
              </IconButton>
              {user && <>
                <Typography>{user.email}</Typography>
                <Typography>Role: {user.role}</Typography>
                <Typography>Chats: {user.chats.length}</Typography>
                <Typography>Tutorial: {user.tutorialFlag === 'false' ? 'not passed' : 'passed'}</Typography>
                <Button
                 onClick={() => setChangeModalOpen(true)}
                 variant='contained'
                 component='label'
                 sx={{
                   height: '36px',
                   color: 'white',
                   marginTop: '8px',
                   marginBottom: '8px',
                   fontSize: '14px',
                   marginRight: '8px'
                 }}
               >
                 Change password 
               </Button>
                <Box>
                <Typography>Subscription: {user.role === 'corporate user' ? user?.subscriptionPlan === 'not subscribed' ? 'Something wrong with subscription, please note your corporate administrator' : 'Active' : user?.subscriptionPlan === 'not subscribed' ? 'Not subscibed' : 'Active'}</Typography>
                {user?.subscriptionPlan === 'active' && <Typography>Tokens: {user?.tokens}</Typography>}
                {(user?.subscriptionPlan === 'active' && user?.subscriptionExipireDate) && <Typography>Subscription expire at: {convertTimestamp(user?.subscriptionExipireDate)}</Typography>}
               {user.role !== 'corporate user' && 
               <>
{user?.subscriptionPlan === 'not subscribed' && 
                 <Button
                 onClick={() => createUserSubscriptionBill()}
                 variant='contained'
                 component='label'
                 sx={{
                   height: '36px',
                   color: 'white',
                   marginTop: '8px',
                   marginBottom: '8px',
                   fontSize: '14px',
                   marginRight: '8px'
                 }}
               >
                 Buy subscription 
               </Button>
                }
                 {(user?.subscriptionPlan === 'active' && user?.subscriptionExipireDate) && 
                <Button
                  onClick={() => resetCancelUserSubscriptionPlan(localStorage.getItem('userId'))}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '36px',
                    color: 'white',
                    marginTop: '8px',
                    marginBottom: '8px',
                    fontSize: '14px',
                    marginRight: '8px'
                  }}
                >
                  Renew your subscription 
                </Button>}
                 {(user?.subscriptionPlan === 'active' && !user?.subscriptionExipireDate) && 
                <Button
                  onClick={() => resetUserSubscriptionPlan(localStorage.getItem('userId'))}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '36px',
                    color: 'white',
                    marginTop: '8px',
                    marginBottom: '8px',
                    fontSize: '14px',
                    marginRight: '8px'
                  }}
                >
                  Unsubscribe 
                </Button>}
                {user?.subscriptionPlan === 'active' && 
                 <Button
                 onClick={() => createUserAddingTokensBill()}
                 variant='contained'
                 component='label'
                 sx={{
                   height: '36px',
                   color: 'white',
                   marginTop: '8px',
                   marginBottom: '8px',
                   fontSize: '14px',
                   marginRight: '8px'
                 }}
               >
                 Buy 20000 tokens 
               </Button>
                }
                {user?.subscriptionPlan === 'active' && 
                 <Button
                 onClick={() => autoTokensfill()}
                 variant='contained'
                 component='label'
                 sx={{
                   height: '36px',
                   color: 'white',
                   marginTop: '8px',
                   marginBottom: '8px',
                   fontSize: '14px',
                   marginRight: '8px'
                 }}
               >
                 Buy 20000 tokens automatically 
               </Button>
                }
                {user?.subscriptionPlan === 'active' && 
                 <Box
                 sx={
                   {
                     display: 'flex',
                     alignItems: 'center'
                   }
                 }
               >
                 <Checkbox  
                   checked={autoFillTokensFlag}
                   onChange={(e) => {
                    setAutoFillTokensFlag(e.target.checked)
                    changeFlag(e.target.checked)
                   }}
                   inputProps={{ 'aria-label': 'controlled' }}
                 />
                 <Typography >
                   Auto charge money for extra tokens when its needed
                 </Typography>
               </Box>
                }
              </>
               }
                </Box>
              </>}
             
            </Box>
          </>}
      </Container>
  );
};

export default User;

