import React, { useCallback, useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ComicPageCard from '../../components/ComicPageCard';
import { useSelector } from 'react-redux';
import AddNewPage from '../../components/modals/AddNewPage';
import * as constants from '../../helpers/constants';
import ProjectHeader from '../../components/ProjectHeader';
import useQuery from '../../helpers/hooks/useQuery';
import ComicCharacterCard from '../../components/ComicCharacterCard';
import ComicImageCard from '../../components/ComicImageCard';
import AddNewCharacter from '../../components/modals/AddNewCharacter';
import ComicSettingCard from '../../components/ComicSettingCard';
import AddNewSettingItem from '../../components/modals/AddNewSettingItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import AllCardsView from '../../components/modals/AllCardsView';
import AppsIcon from '@mui/icons-material/Apps';
import axios from 'axios';
import Loader from '../../components/Loader';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../store/slices/loadingSlice';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/slices/authSlice';
import EditCharacter from '../../components/FieldsEdit/EditCharacter';
import EditSetting from '../../components/FieldsEdit/EditSetting';
import EditStory from '../../components/FieldsEdit/EditStory';
import EditFolder from '../../components/FieldsEdit/EditFolder';
import ComicStoryCard from '../../components/ComicStoryCard';
import AddNewStory from '../../components/modals/AddNewStory';
import AddNewImage from '../../components/modals/AddNewImage';
import CardMedia from '@mui/material/CardMedia';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import EditPage from '../../components/FieldsEdit/EditPage';
import TabChangeModal from '../../components/modals/TabChangeModal';
import AddNewFolder from '../../components/modals/AddNewFolder';
import ComicFolderCard from '../../components/ComicFolderCard/ComicFolderCard';
import ChatModal from '../../components/modals/ChatModal';
import StartGuideModal from '../../components/modals/StartGuideModal';
import AutoFillTokensModal from '../../components/modals/AutoFillTokensModal';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Project = () => {
	const query = useQuery();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [addNewPageOpen, setAddNewPageOpen] = useState(false);
	const [addNewCharOpen, setAddNewCharOpen] = useState(false);
	const [addNewStoryOpen, setAddNewStoryOpen] = useState(false);
	const [addNewImageOpen, setAddNewImageOpen] = useState(false);
	const [addNewFolderOpen, setAddNewFolderOpen] = useState(false);
	const [allCardsOpen, setAllCardsOpen] = useState(false);
	const [cardsToRender, setCardsToRender] = useState([]);
	const [addNewSettingItemOpen, setAddNewSettingItemOpen] = useState(false);
	const projectId = location.pathname.slice(9, location.pathname.length);
	const pageQueryValue = query.get('page');
	const episodesQueryValue = query.get('episodes');
	const itemRef = useRef(query.get('item'))
	const [itemState, setItemState] = useState(query.get('item'))
	const [pageRerenderFlag,setPageRerenderFlag] = useState(true)
	const token = useSelector((state) => state.auth.token);
	// const hideTokensFillModal = useSelector((state) => state.auth.hideTokensFillModal);
	// console.log('hideTokensFillModal',hideTokensFillModal)
	const [tokensFillModal, setTokensFillModal] = useState(false);
	const loading = useSelector((state) => state.loading.loading);
	const dispatch = useDispatch();
	const [currentProject, setCurrentProject] = useState({});
	const episodesArray = useRef([]);
	const navigate = useNavigate();
	const [setting, setSetting] = useState([]);
	const [characters, setCharacters] = useState([]);
	const [stories, setStories] = useState([]);
	const [images, setImages] = useState([]);
	const doesCardHaveUnsavedData = useRef(false);
	const [openCardModal, setOpenCardModal] = useState(false);
	const [isProjectHeaderVisible, setIsProjectHeaderVisible] = useState(true)
	const currentLink = useRef();

	useEffect(() => {
		setItemState(query.get('item'))
		if (itemRef !== itemState) {
			setPageRerenderFlag(prev => !prev)
		}
	},[query.get('item')])

	useEffect(()=> {
		doesCardHaveUnsavedData.current = false
	},[pageQueryValue])

	const handleProjectHeaderVisibility = (flag) => {
		const pageWidth = window.innerWidth
		if(pageWidth < 600) {
			setIsProjectHeaderVisible(flag)
		} else {
			setIsProjectHeaderVisible(true)
		}
	}

	const handleExportPdf = async () => {
		const items = [...cardsToRender];
		const currentItemIndex = query.get('item') !== null && query.get('item') !== 'null' ? query.get('item') : 0;
		const currentItem = cardsToRender[currentItemIndex];
		
		if (query.get('page') === 'story') {
			const itemsModifiedCharacters = updateCharactersDeep(items,'characters', characters)
			const itemsModifiedSummaryCharacters = updateCharactersDeep(itemsModifiedCharacters,'summaryCharacters', characters)
			const itemsModifiedSummarySettingAndCharacters = updateSettingDeep(itemsModifiedSummaryCharacters,'summarySetting', setting)
			const itemsModifiedSettingAndCharacters = updateSettingDeep(itemsModifiedSummarySettingAndCharacters,'setting', setting)
			const content = formatItemAsStory(currentItem, currentProject);

			const documentDefinition = { content };
	
			const pdfDoc = pdfMake.createPdf(documentDefinition);
			pdfDoc.download('output.pdf');

		} else {
			const content = [];

			for (const item of items) {
				const formattedJSON = JSON.stringify(item, null, '\u200B\t');
		  
				content.push({ text: formattedJSON, font: 'Roboto', fontSize: 12, margin: [0, 0, 0, 10] });
			  }
	
			const documentDefinition = {
				content,
			};
	
			const pdfDoc = pdfMake.createPdf(documentDefinition);
			pdfDoc.download('output.pdf');
		}
	
	};
	
	function formatItemAsStory(item) {
		const styledContent = [];

		if (currentProject && currentProject.title) {
			styledContent.push({
				text: currentProject.title.toUpperCase(),
				font: 'Roboto',
				fontSize: 18, // Adjust fontSize as needed
				bold: true,
				decoration: 'underline',
				margin: [0, 200, 0, 0], // Adjust margin as needed
				alignment: 'center',
			});
		}
		if (currentProject && currentProject.tagline) {
			styledContent.push({
				text: currentProject.tagline,
				font: 'Roboto',
				fontSize: 14, // Adjust fontSize as needed
				italics: true,
				alignment: 'center',
				pageBreak: 'after',
				margin: [0, 0] // Adjust margin as needed
			});
		}
		if (item.story.title) {
			styledContent.push({ 
				text: item.story.title.toUpperCase(), 
				font: 'Roboto',
				fontSize: 16, 
				bold: true, 
				alignment: 'center',
				decoration: 'underline',
				margin: [0, 10]
			});
		}
		if (item.story.summary) {
			styledContent.push({ 
				text: item.story.summary, 
				font: 'Roboto',
				fontSize: 14, 
				bold: false, 
				margin: [10, 10]
			});
		}
		if (item.story.list_of_acts) {
			styledContent.push({ 
				text: item.story.list_of_acts, 
				font: 'Roboto',
				fontSize: 14, 
				margin: [10, 10]
			});
		}
	
		if (item.story.acts && item.story.acts.length > 0) {
			item.story.acts.forEach(act => {
				if (act.scenes && act.scenes.length > 0) {
					act.scenes.forEach(scene => {
						styledContent.push({ 
							text: scene.scene_full_script, 
							font: 'Roboto',
							fontSize: 14, 
							margin: [0, 10]
						});
					});
				}
			});
		}
	
		return styledContent;
	}
	
	

	function updateCharactersDeep(obj, fieldName, newValue) {
		if (Array.isArray(obj)) {
		  return obj.map((item) => updateCharactersDeep(item, fieldName, newValue));
		} else if (typeof obj === 'object' && obj !== null) {
		  const updatedObj = { ...obj };
		  for (const key in updatedObj) {
			updatedObj[key] = updateCharactersDeep(updatedObj[key], fieldName, newValue);
		  }
		  if (updatedObj.hasOwnProperty(fieldName)) {
			const newCharactersArray = updatedObj[fieldName]?.reduce((accumulator,charId) => {
				const character = newValue?.find(item => item?.character?.id === charId)
				return accumulator = [...accumulator,character.character.name]
			},[])
			updatedObj[fieldName] = newCharactersArray
		  }
		  return updatedObj;
		} else {
		  return obj;
		}
	  }
	  
	function updateSettingDeep(obj, fieldName, newValue) {
		if (Array.isArray(obj)) {
		  return obj.map((item) => updateSettingDeep(item, fieldName, newValue));
		} else if (typeof obj === 'object' && obj !== null) {
		  const updatedObj = { ...obj };
		  for (const key in updatedObj) {
			updatedObj[key] = updateSettingDeep(updatedObj[key], fieldName, newValue);
		  }
		  if (updatedObj.hasOwnProperty(fieldName)) {
			const newSettingArray = updatedObj[fieldName]?.reduce((accumulator, setId) => {
				const settingItem = newValue?.find(item => item?.settingItem?.id === setId);
				return [...accumulator, settingItem.settingItem.title];
			}, [])
			updatedObj[fieldName] = newSettingArray
		  }
		  return updatedObj;
		} else {
		  return obj;
		}
	  }
	  
	const setImagesToRenderFunc = async () => {
		const imagesToRenderArr = [];
		const cardsToRenderArr = [];
	
		await getImages(imagesToRenderArr,cardsToRenderArr)
		if (episodesQueryValue !== '0') {
			await getEpisodeImages(imagesToRenderArr,cardsToRenderArr)
		}

		setImages(imagesToRenderArr)
	}

	const setCardsToRenderFunc = useCallback(async () => {
		dispatch(addLoadingFlag('get-data'));
		// setCardsToRender([]);
		const cardsToRenderArr = [];
		const settingToRenderArr = [];
		const charactersToRenderArr = [];
		const storiesToRenderArr = [];
		const imagesToRenderArr = [];

		if (pageQueryValue === 'story') {
			dispatch(addLoadingFlag('get-stories'));
			
			if (episodesQueryValue !== '0') {
				dispatch(addLoadingFlag('get-episode-stories'));
			}
			await getStories()

			/* Pages current epizode render */
			if (episodesQueryValue !== '0') {
				await getEpisodeStories()
			}
		}
		if (pageQueryValue === 'pages') {
			/* Pages common render */
			dispatch(addLoadingFlag('get-pages'));

			if (episodesQueryValue !== '0') {
				dispatch(addLoadingFlag('get-episode-pages'));
			}
				
				await getPages();
		
			/* Pages current epizode render */
			if (episodesQueryValue !== '0') {
				
				await getEpisodePages()

			}
		}
		if (pageQueryValue === 'images') {
			
			dispatch(addLoadingFlag('get-images'));
			if (episodesQueryValue !== '0') {
				dispatch(addLoadingFlag('get-episode-images'));
			}
			await getImagesTab()

			/* Pages current epizode render */
			if (episodesQueryValue !== '0') {

				await getEpisodeImagesTab()

			}

		}
		if (pageQueryValue === 'characters') {
			/* characters common render */
			if (episodesQueryValue !== '0') {
				dispatch(addLoadingFlag('get-episode-characters'));
			}
			dispatch(addLoadingFlag('get-characters'));
			await getCharacters()
			
			/* characters current epizode render */
			if (episodesQueryValue !== '0') {
				await getEpisodeCharacters()
			}
		}
		if (pageQueryValue === 'setting') {
			/* setting common render */
			dispatch(addLoadingFlag('get-setting'));
			if (episodesQueryValue !== '0') {
				dispatch(addLoadingFlag('get-episode-setting'));
			}
			await getSetting()
			/* setting current epizode render */
			if (episodesQueryValue !== '0') {
				await getEpisodeSetting()
			}
		}
		if (pageQueryValue === 'folders') {

			dispatch(addLoadingFlag('get-folders'));
	
			await getFolders()

		}
		async function getCharacters () {
			await axios
			.get(
				constants.BASE_URL +
					'api/projects/get-characters/' +
					projectId,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(function (response) {
				if (pageQueryValue === 'story' || pageQueryValue === 'images' || pageQueryValue === 'pages') {
					charactersToRenderArr.push(
						...response.data.map((character, index) => {
							return {
								character: character,
								characterId: index,
							};
						})
					);
					dispatch(removeLoadingFlag('story-characters'))
				} else {
					cardsToRenderArr.push(
						...response.data.map((character, index) => {
							return {
								character: character,
								characterId: index,
							};
						})
					);
				}
				
				dispatch(removeLoadingFlag('get-characters'));
			})
			.catch(function (error) {
				dispatch(removeLoadingFlag('get-characters'));
				if (error.response?.data === 'Non existing user.') {
					navigate('login');
					dispatch(logout());
				}
			});
		}
		async function getPages () {
			await axios
				.get(
					constants.BASE_URL + 'api/projects/get-pages/' + projectId,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					cardsToRenderArr.push(
						...response.data?.pages.map((page, index) => {
							return {
								page: page,
								imageIndex: index,
							};
						})
					);
					storiesToRenderArr.push(
						...response.data?.stories.map((story, index) => {
							return {
								story: story,
								characterId: index,
							};
						})
					);
					settingToRenderArr.push(
						...response.data?.setting.map((settingItem, index) => {
							return {
								settingItem: settingItem,
								settingItemId: index,
							};
						})
					);
					charactersToRenderArr.push(
						...response.data?.characters.map((character, index) => {
							return {
								character: character,
								characterId: index,
							};
						})
					);
					const filtredData = response.data?.images.filter(item => item !== null)
					
					imagesToRenderArr.push(
						...filtredData.map((image, index) => {
							return {
								image: image,
								imageIndex: index,
							};
						})
					);
					dispatch(removeLoadingFlag('get-pages'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('get-pages'));
					if (error.response?.data === 'Non existing user.') {
						navigate('login');
						dispatch(logout());
					}
				});
		}

		async function getEpisodePages () {
			 	await axios
					.get(
						constants.BASE_URL +
							'api/projects/get-episode-pages/' +
							projectId +
							'/' +
							episodesArray.current[
								parseInt(episodesQueryValue) - 1
							]?.id,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(function (response) {
						cardsToRenderArr.push(
							...response.data?.pages.map((page, index) => {
								return {
									page: page,
									imageIndex: index,
								};
							})
						);
						storiesToRenderArr.push(
							...response.data?.stories.map((story, index) => {
								return {
									story: story,
									characterId: index,
								};
							})
						);
						settingToRenderArr.push(
							...response.data?.setting.map((settingItem, index) => {
								return {
									settingItem: settingItem,
									settingItemId: index,
								};
							})
						);
						charactersToRenderArr.push(
							...response.data?.characters.map((character, index) => {
								return {
									character: character,
									characterId: index,
								};
							})
						);
						const filtredData = response.data?.images.filter(item => item !== null)
						
						imagesToRenderArr.push(
							...filtredData.map((image, index) => {
								return {
									image: image,
									imageIndex: index,
								};
							})
						);
						dispatch(removeLoadingFlag('get-episode-pages'));
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('get-episode-pages'));
						if (error.response?.data === 'Non existing user.') {
							navigate('login');
							dispatch(logout());
						}
					});
		}
		 async function getStories () {
			 await axios
				.get(
					constants.BASE_URL + 'api/projects/get-stories/' + projectId,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					cardsToRenderArr.push(
						...response.data?.stories.map((story, index) => {
							return {
								story: story,
								imageIndex: index,
							};
						}).filter(item => item?.story !== null)
					);
					settingToRenderArr.push(
						...response.data?.setting.map((settingItem, index) => {
							return {
								settingItem: settingItem,
								settingItemId: index,
							};
						})
					);
					charactersToRenderArr.push(
						...response.data?.characters.map((character, index) => {
							return {
								character: character,
								characterId: index,
							};
						})
					);
					dispatch(removeLoadingFlag('get-stories'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('get-stories'));
					if (error.response?.data === 'Non existing user.') {
						navigate('login');
						dispatch(logout());
					}
				});
			}
		
		 async function getEpisodeStories () {
			 await axios
					.get(
						constants.BASE_URL +
							'api/projects/get-episode-stories/' +
							projectId +
							'/' +
							episodesArray.current[
								parseInt(episodesQueryValue) - 1
							]?.id,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(function (response) {
						cardsToRenderArr.push(
							...response.data?.stories.map((story, index) => {
								return {
									story: story,
									imageIndex: index,
								};
							})
						);
						settingToRenderArr.push(
							...response.data?.setting.map((settingItem, index) => {
								return {
									settingItem: settingItem,
									settingItemId: index,
								};
							})
						);
						charactersToRenderArr.push(
							...response.data?.characters.map((character, index) => {
								return {
									character: character,
									characterId: index,
								};
							})
						);
						dispatch(removeLoadingFlag('get-episode-stories'));
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('get-episode-stories'));
						if (error.response?.data === 'Non existing user.') {
							navigate('login');
							dispatch(logout());
						}
					});
		}

		async function getEpisodeCharacters () {
			await axios
					.get(
						constants.BASE_URL +
							'api/projects/get-episode-characters/' +
							projectId +
							'/' +
							episodesArray.current[
								parseInt(episodesQueryValue) - 1
							]?.id,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(function (response) {
						if (pageQueryValue === 'story' || pageQueryValue === 'images' || pageQueryValue === 'pages') {
							charactersToRenderArr.push(
								...response.data.map((character, index) => {
									return {
										character: character,
										characterId: index,
									};
								})
							);
							dispatch(addLoadingFlag('story-episode-characters'))
						} else {
							cardsToRenderArr.push(
								...response.data.map((character, index) => {
									return {
										character: character,
										characterId: index,
									};
								})
							);
						}
						dispatch(removeLoadingFlag('get-episode-characters'));
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('get--episodecharacters'));
						if (error.response?.data === 'Non existing user.') {
							navigate('login');
							dispatch(logout());
						}
					});
		}

		async function getSetting () {
			 await axios
				.get(
					constants.BASE_URL +
						'api/projects/get-setting/' +
						projectId,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					if (pageQueryValue === 'story' || pageQueryValue === 'images' || pageQueryValue === 'pages') {
						settingToRenderArr.push(
							...response.data?.map((settingItem, index) => {
								return {
									settingItem: settingItem,
									settingItemId: index,
								};
							})
						);
						dispatch(addLoadingFlag('story-setting'))
					} else {
						cardsToRenderArr.push(
							...response.data?.map((settingItem, index) => {
								return {
									settingItem: settingItem,
									settingItemId: index,
								};
							})
						);
					}
					dispatch(removeLoadingFlag('get-setting'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('get-setting'));
					if (error.response?.data === 'Non existing user.') {
						navigate('login');
						dispatch(logout());
					}
				});

		}
		async function getFolders () {
			 await axios
				.get(
					constants.BASE_URL +
						'api/projects/get-folders/' +
						projectId,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
						cardsToRenderArr.push(
							...response.data?.map((folder, index) => {
								return {
									folder: folder,
									folderId: index,
								};
							})
						);
					
					dispatch(removeLoadingFlag('get-folders'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('get-folders'));
					if (error.response?.data === 'Non existing user.') {
						navigate('login');
						dispatch(logout());
					}
				});
		}

		 async function getEpisodeSetting () {
			 await axios
					.get(
						constants.BASE_URL +
							'api/projects/get-episode-setting/' +
							episodesArray.current[
								parseInt(episodesQueryValue) - 1
							]?.id,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(function (response) {
						if (pageQueryValue === 'story' || pageQueryValue === 'images' || pageQueryValue === 'pages') {
							settingToRenderArr.push(
								...response.data?.map((settingItem, index) => {
									return {
										settingItem: settingItem,
										settingItemId: index,
									};
								})
							);
							dispatch(addLoadingFlag('story-episode-setting'))
						} else {
							cardsToRenderArr.push(
								...response.data?.map((settingItem, index) => {
									return {
										settingItem: settingItem,
										settingItemId: index,
									};
								})
							);
						}
						dispatch(removeLoadingFlag('get-episode-setting'));
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('get-episode-setting'));
						if (error.response?.data === 'Non existing user.') {
							navigate('login');
							dispatch(logout());
						}
					});
		}
		async function getImagesTab () {
			await axios
				.get(
					constants.BASE_URL + 'api/projects/get-images/' + projectId,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
						const filtredData = response.data?.images.filter(item => item !== null)
						cardsToRenderArr.push(
							...filtredData.map((image, index) => {
								return {
									image: image,
									imageIndex: index,
								};
							})
						);
						storiesToRenderArr.push(
							...response.data?.stories.map((story, index) => {
								return {
									story: story,
									characterId: index,
								};
							})
						);
						settingToRenderArr.push(
							...response.data?.setting.map((settingItem, index) => {
								return {
									settingItem: settingItem,
									settingItemId: index,
								};
							})
						);
						charactersToRenderArr.push(
							...response.data?.characters.map((character, index) => {
								return {
									character: character,
									characterId: index,
								};
							})
						);
					dispatch(removeLoadingFlag('get-images'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('get-images'));
					if (error.response?.data === 'Non existing user.') {
						navigate('login');
						dispatch(logout());
					}
				});
		}	
		async function getEpisodeImagesTab () {
			await axios
				.get(
					constants.BASE_URL +
						'api/projects/get-episode-images/' +
						projectId +
						'/' +
						episodesArray.current[
							parseInt(episodesQueryValue) - 1
						]?.id,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
						const filtredData = response.data?.images.filter(item => item !== null)
						cardsToRenderArr.push(
							...filtredData.map((image, index) => {
								return {
									image: image,
									imageIndex: index,
								};
							})
						);
						storiesToRenderArr.push(
							...response.data?.stories.map((story, index) => {
								return {
									story: story,
									characterId: index,
								};
							})
						);
						settingToRenderArr.push(
							...response.data?.setting.map((settingItem, index) => {
								return {
									settingItem: settingItem,
									settingItemId: index,
								};
							})
						);
						charactersToRenderArr.push(
							...response.data?.characters.map((character, index) => {
								return {
									character: character,
									characterId: index,
								};
							})
						);
					dispatch(removeLoadingFlag('get-images'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('get-images'));
					if (error.response?.data === 'Non existing user.') {
						navigate('login');
						dispatch(logout());
					}
				});
		}	

		setSetting(settingToRenderArr?.sort((a, b) => parseInt(a?.settingItem?.order) - parseInt(b?.settingItem?.order)));
		setCharacters(charactersToRenderArr?.sort((a, b) => parseInt(a?.character?.order) - parseInt(b?.character?.order)));
		setStories(storiesToRenderArr?.sort((a, b) => parseInt(a?.story?.order) - parseInt(b?.story?.order)));
		setImages(imagesToRenderArr?.sort((a, b) => parseInt(a?.image?.order) - parseInt(b?.image?.order)))
		
		if (pageQueryValue === 'story') {
			setCardsToRender(cardsToRenderArr?.sort((a, b) => parseInt(a?.story?.order) - parseInt(b?.story?.order)));
		}
		if (pageQueryValue === 'pages') {
			setCardsToRender(cardsToRenderArr?.sort((a, b) => parseInt(a?.page?.order) - parseInt(b?.page?.order)));
		}
		if (pageQueryValue === 'images') {
			setCardsToRender(cardsToRenderArr?.sort((a, b) => parseInt(a?.image?.order) - parseInt(b?.image?.order)));
		}
		if (pageQueryValue === 'characters') {
			setCardsToRender(cardsToRenderArr?.sort((a, b) => parseInt(a?.character?.order) - parseInt(b?.character?.order)));
		}
		if (pageQueryValue === 'setting') {
			setCardsToRender(cardsToRenderArr?.sort((a, b) => parseInt(a?.settingItem?.order) - parseInt(b?.settingItem?.order)));
		}
		if (pageQueryValue === 'folders') {
			setCardsToRender(cardsToRenderArr?.sort((a, b) => parseInt(a?.folder?.order) - parseInt(b?.folder?.order)));
		}
		dispatch(removeLoadingFlag('get-data'));
	}, [
		token,
		pageQueryValue,
		episodesQueryValue,
		projectId,
		navigate,
	]);

	async function getImages (imagesToRender,cardsToRenderArr) {
		await axios
			.get(
				constants.BASE_URL + 'api/projects/get-images/' + projectId,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(function (response) {
					const filtredData = response.data?.images.filter(item => item !== null)
					if (pageQueryValue === 'pages') {
						imagesToRender.push(
							...filtredData.map((image, index) => {
								return {
									image: image,
									imageIndex: index,
								};
							})
						);
					} else {
						cardsToRenderArr.push(
							...filtredData.map((image, index) => {
								return {
									image: image,
									imageIndex: index,
								};
							})
						);
					}
				dispatch(removeLoadingFlag('get-images'));
			})
			.catch(function (error) {
				dispatch(removeLoadingFlag('get-images'));
				if (error.response?.data === 'Non existing user.') {
					navigate('login');
					dispatch(logout());
				}
			});
	}		
	
	async function getEpisodeImages (imagesToRender,cardsToRenderArr) {
		await axios
				.get(
					constants.BASE_URL +
						'api/projects/get-episode-images/' +
						projectId +
						'/' +
						episodesArray.current[
							parseInt(episodesQueryValue) - 1
						]?.id,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					const filtredData = response.data.filter(item => item !== null)
					if (pageQueryValue === 'pages') {
						imagesToRender.push(
							...filtredData.map((image, index) => {
								return {
									image: image,
									imageIndex: index,
								};
							})
						);
					} else {
						cardsToRenderArr.push(
							...filtredData.map((image, index) => {
								return {
									image: image,
									imageIndex: index,
								};
							})
						);
					}						
					dispatch(removeLoadingFlag('get-episode-images'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('get-episode-images'));
					if (error.response?.data === 'Non existing user.') {
						navigate('login');
						dispatch(logout());
					}
				});
	}

	const setEpisodesToRender = useCallback(async () => {
		dispatch(addLoadingFlag('get-episodes'));
		await axios
			.get(
				constants.BASE_URL + 'api/projects/get-episodes/' + projectId,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(async function (response) {
				episodesArray.current = response.data;
				await setCardsToRenderFunc();
				dispatch(removeLoadingFlag('get-episodes'));
			})
			.catch(function (error) {
				dispatch(removeLoadingFlag('get-episodes'));
				if (error.response?.data === 'Non existing user.') {
					navigate('login');
					dispatch(logout());
				}
			});
	}, [token, projectId, dispatch, setCardsToRenderFunc, navigate]);

	useEffect(() => {
		axios
			.get(constants.BASE_URL + 'api/projects/' + projectId, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(async function (response) {
				await setCurrentProject(response.data);
			})
			.catch(function (error) {
				if (error.response?.data === 'Non existing user.') {
					navigate('login');
					dispatch(logout());
				}
				if (error.response?.status === 404) {
					navigate('gallery');
					// dispatch(logout());
				}
			});

		setEpisodesToRender();
	}, [dispatch, projectId, token, setEpisodesToRender, navigate]);

	const onCardHandleClick = () => {
		const params = {};
		for (let entry of searchParams.entries()) {
			params[entry[0]] = entry[1];
		}
		setSearchParams({ ...params, item: currentLink.current });
	};

	function handleDragEnd(result) {
		if (!result.destination) return;
		const reorderedItems = [...cardsToRender];
		const [removed] = reorderedItems.splice(result.source.index, 1);
		reorderedItems.splice(result.destination.index, 0, removed);

		setCardsToRender(reorderedItems);

		if (pageQueryValue === 'story') {
			const changeStoriesOrderInDataBase = async (stories) => {
				await axios.post(constants.BASE_URL + 'api/projects/update-stories-order' , {
					stories,
				}, {
				headers: {
					'Authorization': `Bearer ${token}`
				},
				} )
				.then(function (response) {

				})
				.catch(function (error) {
				if (error.response?.data === 'Non existing user.') {
					navigate('login')
					dispatch(logout())
				} 
				});
			}
			changeStoriesOrderInDataBase(reorderedItems.map((item,indx) => {
				return {
					id: item.story.id,
					order: indx
				}
			}))
		}
		if (pageQueryValue === 'pages') {
			const changePagesOrderInDataBase = async (pages) => {
				await axios.post(constants.BASE_URL + 'api/projects/update-pages-order' , {
					pages,
				}, {
				headers: {
					'Authorization': `Bearer ${token}`
				},
				} )
				.then(function (response) {

				})
				.catch(function (error) {
				if (error.response?.data === 'Non existing user.') {
					navigate('login')
					dispatch(logout())
				} 
				});
			}
			changePagesOrderInDataBase(reorderedItems.map((item,indx) => {
				return {
					id: item.page.id,
					order: indx
				}
			}))
		}
		if (pageQueryValue === 'images') {
			const changeImagesOrderInDataBase = async (images) => {
				await axios.post(constants.BASE_URL + 'api/projects/update-images-order' , {
					images,
				}, {
				headers: {
					'Authorization': `Bearer ${token}`
				},
				} )
				.then(function (response) {

				})
				.catch(function (error) {
				if (error.response?.data === 'Non existing user.') {
					navigate('login')
					dispatch(logout())
				} 
				});
			}
			changeImagesOrderInDataBase(reorderedItems.map((item,indx) => {
				return {
					id: item.image.id,
					order: indx
				}
			}))
		}
		if (pageQueryValue === 'characters') {
			const changeCharactersOrderInDataBase = async (characters) => {
				await axios.post(constants.BASE_URL + 'api/projects/update-characters-order' , {
					characters,
				}, {
				headers: {
					'Authorization': `Bearer ${token}`
				},
				} )
				.then(function (response) {

				})
				.catch(function (error) {
				if (error.response?.data === 'Non existing user.') {
					navigate('login')
					dispatch(logout())
				} 
				});
			}
			changeCharactersOrderInDataBase(reorderedItems.map((item,indx) => {
				return {
					id: item.character.id,
					order: indx
				}
			}))
		}
		if (pageQueryValue === 'setting') {
			const changeSettingsOrderInDataBase = async (characters) => {
				await axios.post(constants.BASE_URL + 'api/projects/update-settings-order' , {
					characters,
				}, {
				headers: {
					'Authorization': `Bearer ${token}`
				},
				} )
				.then(function (response) {

				})
				.catch(function (error) {
				if (error.response?.data === 'Non existing user.') {
					navigate('login')
					dispatch(logout())
				} 
				});
			}
			changeSettingsOrderInDataBase(reorderedItems.map((item,indx) => {
				return {
					id: item.settingItem.id,
					order: indx
				}
			}))
		}
		if (pageQueryValue === 'folders') {
			const changeFoldersOrderInDataBase = async (folders) => {
				await axios.post(constants.BASE_URL + 'api/projects/update-folders-order' , {
					folders,
				}, {
				headers: {
					'Authorization': `Bearer ${token}`
				},
				} )
				.then(function (response) {

				})
				.catch(function (error) {
				if (error.response?.data === 'Non existing user.') {
					navigate('login')
					dispatch(logout())
				} 
				});
			}
			changeFoldersOrderInDataBase(reorderedItems.map((item,indx) => {
				return {
					id: item.folder.id,
					order: indx
				}
			}))
		}
	}

	const onNavigateLinkClick = (link) => {
		currentLink.current = link
		
		if (doesCardHaveUnsavedData.current) {
			setOpenCardModal(true)
		} else {
			onCardHandleClick()
		}
	};

	return (
		<>
		{loading && <Loader />}
		<Box sx={{ display: 'flex', overflow: 'hidden' }}>
			<ProjectHeader
				episodesArray={episodesArray.current}
				currentProject={currentProject}
				setEpisodesToRender={setEpisodesToRender}
				handleExportPdf={handleExportPdf}
				doesCardHaveUnsavedData={doesCardHaveUnsavedData}
				isProjectHeaderVisible={isProjectHeaderVisible}
			/>

			{/* Modals */}
			<ChatModal currentProject={currentProject} setTokensFillModal={setTokensFillModal}/>
			<StartGuideModal />
			<AllCardsView
				open={allCardsOpen}
				setOpen={setAllCardsOpen}
				cardsToRender={cardsToRender}
				setCardsToRender={setCardsToRender}
			/>
			<AddNewPage
				setCardsToRenderFunc={setCardsToRenderFunc}
				open={addNewPageOpen}
				setOpen={setAddNewPageOpen}
				episodesArray={episodesArray.current}
				cardsToRenderLength={cardsToRender.length}
			/>
			<AddNewCharacter
				setCardsToRenderFunc={setCardsToRenderFunc}
				open={addNewCharOpen}
				setOpen={setAddNewCharOpen}
				episodesArray={episodesArray.current}
				cardsToRenderLength={cardsToRender.length}
			/>
			<AddNewSettingItem
				setCardsToRenderFunc={setCardsToRenderFunc}
				open={addNewSettingItemOpen}
				setOpen={setAddNewSettingItemOpen}
				episodesArray={episodesArray.current}
				cardsToRenderLength={cardsToRender.length}
			/>
			<AddNewFolder
				setCardsToRenderFunc={setCardsToRenderFunc}
				open={addNewFolderOpen}
				setOpen={setAddNewFolderOpen}
				episodesArray={episodesArray.current}
				cardsToRenderLength={cardsToRender.length}
			/>
			<AddNewImage
				setCardsToRenderFunc={setCardsToRenderFunc}
				open={addNewImageOpen}
				setOpen={setAddNewImageOpen}
				episodesArray={episodesArray.current}
				cardsToRenderLength={cardsToRender.length}
				setting={setting}
				characters={characters}
				stories={stories}
				setImagesToRenderFunc={setImagesToRenderFunc}
			/>
			<AddNewStory
				setCardsToRenderFunc={setCardsToRenderFunc}
				open={addNewStoryOpen}
				setOpen={setAddNewStoryOpen}
				episodesArray={episodesArray.current}
				cardsToRenderLength={cardsToRender.length}
			/>
			<TabChangeModal open={openCardModal} setOpen={setOpenCardModal} onSubmit={onCardHandleClick}/>
			<AutoFillTokensModal open={tokensFillModal} setOpen={setTokensFillModal}/>

			<CssBaseline />

			{projectId &&
				(pageQueryValue === 'pages' ||
					pageQueryValue === 'characters' ||
					pageQueryValue === 'setting' ||
					pageQueryValue === 'images' ||
					pageQueryValue === 'story' ||
					pageQueryValue === 'folders') && (
					<Drawer
						sx={{
							position: {
								sm: 'static',
								xs: 'absolute',
							},
							display: {
								xs:
									query.get('item') &&
									query.get('item') !== 'null'
										? 'none'
										: 'block',
								md: 'block',
							},
							width: {
								sm: 240,
								xs: '100vw',
							},
							flexShrink: 0,
							zIndex: '2',
							'& .MuiDrawer-paper': {
								width: {
									sm: 240,
									xs: '100vw',
								},
								boxSizing: 'border-box',
								top: '160px',
								left: '0',
								height: 'calc(100vh - 160px)',
								backgroundColor: '#29323c',
								'&::-webkit-scrollbar': {
									width: '2px',
									height: 0,
								},
								'&::-webkit-scrollbar-thumb': {
									borderRadius: '6px',
									backgroundColor: constants.TEAL,
								},
							},
						}}
						variant='permanent'
						anchor='left'
					>
						<DragDropContext onDragEnd={handleDragEnd}>
							<Droppable droppableId='card-list'>
								{(provided) => (
									<Box
										sx={{
											marginTop: 2,
											marginBottom: {
												xs: 20,
												sm: 8,
											},
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
											gap: '20px',
										}}
										{...provided.droppableProps}
										ref={provided.innerRef}
									>
										{cardsToRender.length < 1 && (
											<div
												style={{
													marginTop: '70px',
													height: '100%',
													width: '100%',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													textAlign: 'center',
												}}
											>
												Click "Add New" to begin creating.
											</div>
										)}
										{/* Render cards to side bar*/}
										{pageQueryValue === 'pages' &&
											cardsToRender.map((item, index) => (
												<ComicPageCard
													setCardsToRenderFunc={
														setCardsToRenderFunc
													}
													key={index}
													index={index}
													onCardHandleClick={() =>
														onNavigateLinkClick(index)
													}
													imageIndex={item.imageIndex}
													page={item?.page}
													image={item.page?.image}
													epizodeIndex={
														item.epizodeIndex
															? item.epizodeIndex
															: undefined
													}
												/>
											))}
										{pageQueryValue === 'images' &&
											cardsToRender.map((item, index) => (
												<ComicImageCard
													setCardsToRenderFunc={
														setCardsToRenderFunc
													}
													key={index}
													index={index}
													onCardHandleClick={() =>
														onNavigateLinkClick(index)
													}
													image={item.image}
													epizodeIndex={
														item.epizodeIndex
															? item.epizodeIndex
															: undefined
													}
													setting={setting}
													characters={characters}
													stories={stories}
												/>
											))}
										{pageQueryValue === 'characters' &&
											cardsToRender.map((item, index) => (
												<ComicCharacterCard
													setCardsToRenderFunc={
														setCardsToRenderFunc
													}
													key={index}
													index={index}
													onCardHandleClick={() =>
														onNavigateLinkClick(index)
													}
													characterId={
														item.characterId
													}
													character={item.character}
													epizodeIndex={
														item.epizodeIndex
															? item.epizodeIndex
															: undefined
													}
												/>
											))}
										{pageQueryValue === 'setting' &&
											cardsToRender.map((item, index) => (
												<ComicSettingCard
													setCardsToRenderFunc={
														setCardsToRenderFunc
													}
													key={index}
													index={index}
													onCardHandleClick={() =>
														onNavigateLinkClick(index)
													}
													settingItemId={
														item.settingItemId
													}
													settingItem={
														item.settingItem
													}
													epizodeIndex={
														item.epizodeIndex
															? item.epizodeIndex
															: undefined
													}
												/>
											))}
											{pageQueryValue === 'story' &&
											cardsToRender.map((item, index) => (
												<ComicStoryCard
													setCardsToRenderFunc={
														setCardsToRenderFunc
													}
													key={index}
													index={index}
													onCardHandleClick={() =>
														onNavigateLinkClick(index)
													}
													storyId={
														item.storyId
													}
													story={
														item.story
													}
													epizodeIndex={
														item.epizodeIndex
															? item.epizodeIndex
															: undefined
													}
												/>
											))}
											{pageQueryValue === 'folders' &&
											cardsToRender.map((item, index) => (
												<ComicFolderCard
													setCardsToRenderFunc={
														setCardsToRenderFunc
													}
													key={index}
													index={index}
													onCardHandleClick={() =>
														onNavigateLinkClick(index)
													}
													folderId={
														item.folderId
													}
													folder={
														item.folder
													}
													epizodeIndex={
														item.epizodeIndex
															? item.epizodeIndex
															: undefined
													}
												/>
											))}
										{provided.placeholder}
										<Box
											sx={{
												position: 'fixed',
												bottom: '0',
												bgcolor: '#29323c',
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
												gap: '5px',
												width: {
													sm: 230,
													xs: 'calc(100vw - 10px)',
												},
												padding: '10px 0',
												zIndex: 5,
											}}
										>
											<Button
												variant='contained'
												onClick={() => {
													if (
														pageQueryValue ===
														'pages'
													)
														setAddNewPageOpen(true);
													if (
														pageQueryValue ===
														'characters'
													)
														setAddNewCharOpen(true);
													if (
														pageQueryValue ===
														'setting'
													)
														setAddNewSettingItemOpen(true);
													if (
														pageQueryValue ===
														'story'
													)
														setAddNewStoryOpen(true);
													if (
														pageQueryValue ===
														'images'
													)
														setAddNewImageOpen(true);
													if (
														pageQueryValue ===
														'folders'
													)
														setAddNewFolderOpen(true);
												}}
												sx={{
													width: '50%',
													fontSize: '16px',
													color: 'white',
												}}
												disableElevation
											>
												Add new
											</Button>
											<Button
												variant='contained'
												onClick={() => {
													setAllCardsOpen(true);
												}}
												sx={{
													width: '20%',
													fontSize: '16px',
													color: 'white',
													height: '40px',
												}}
												disableElevation
											>
												<AppsIcon />
											</Button>
										</Box>
									</Box>
								)}
							</Droppable>
						</DragDropContext>
					</Drawer>
				)}
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					bgcolor: 'background.default',
					p: 3,
					overflowX: 'hidden',
					paddingBottom: {
						xs: '80px',
						sm: '200px',
					},
					paddingTop: {
						xs: '130px',
						sm: '200px',
					},
				}}
			>
				<Button
					onClick={() => {
						onNavigateLinkClick(null);
					}}
					sx={{
						color: 'white',
						display: {
							xs:
								!query.get('item') ||
								query.get('item') === 'null'
									? 'none'
									: 'block',
							md: 'none',
						},
						borderRadius: '8px',
						height: '40px',
						textDecoration: 'underline',
					}}
				>
					{'<- Back'}
				</Button>
				<Toolbar
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'baseline',
						justifyContent: 'center',
						padding: '0'
					}}
				>
					{cardsToRender.length < 1 && (
						<div
							style={{
								height: '100%',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							PAGES allows you to create a storyboard or graphic novel for your project using our AI generated images that you generated using our IMAGES tool.
							<br />
							CHARACTERS allows you to create a roster of characters to draw from for your project, so that when our AI generates something for you, it knows who to include.
							<br />
							SETTING allows you to create various locations, times, seasons, etc that the AI can draw upon when generating for you.
							<br />
							STORY is our Story Generator. Here you take everything that you have created in CHARACTERS and SETTING, and start to write your story.
							<br />
							IMAGES is our Image Generator. It will allow you to generate images to be used elsewhere in your project.
						</div>
					)}
					{/* DISPLAY FIRST CARD DESCRIPTION WHEN PAGE LOADED WITHOUT SELECTED ITEM  */}
					{(!query.get('item') || query.get('item') === 'null') &&
						cardsToRender.length > 0 && (
							<>
								{(pageQueryValue === 'folders' && pageRerenderFlag) && (
									<EditFolder
										folder={cardsToRender[0]?.folder}
									/>
								)}
								{(pageQueryValue === 'folders' && !pageRerenderFlag) && (
									<EditFolder
										folder={cardsToRender[0]?.folder}
									/>
								)}
								{(pageQueryValue === 'pages' && pageRerenderFlag) && (
									<EditPage
										page={cardsToRender[0]?.page}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										addNewImage={() => setAddNewImageOpen(true)}
										images={images}
										setImages={setImages}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{(pageQueryValue === 'pages' && !pageRerenderFlag) && (
									<EditPage
										page={cardsToRender[0]?.page}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										addNewImage={() => setAddNewImageOpen(true)}
										images={images}
										setImages={setImages}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{pageQueryValue === 'characters' && (
									<EditCharacter
										character={cardsToRender[0]?.character}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										handleExportPdf={handleExportPdf}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										handleProjectHeaderVisibility={(flag)=> handleProjectHeaderVisibility(flag)}
										currentProject={currentProject}
										setTokensFillModal={setTokensFillModal}
									/>
								)}

								{pageQueryValue === 'story' && (
									<EditStory
										story={
											cardsToRender[0]?.story
										}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										setting={setting}
										characters={characters}
										handleExportPdf={handleExportPdf}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										handleProjectHeaderVisibility={(flag)=> handleProjectHeaderVisibility(flag)}
										episodesArray={episodesArray}
										currentProject={currentProject}
										setTokensFillModal={setTokensFillModal}
									/>
								)}

								{pageQueryValue === 'setting' && (
									<EditSetting
										propsSettingItem={
											cardsToRender[0]?.settingItem
										}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										handleExportPdf={handleExportPdf}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										handleProjectHeaderVisibility={(flag)=> handleProjectHeaderVisibility(flag)}
										currentProject={currentProject}
										setTokensFillModal={setTokensFillModal}
									/>
								)}

								{pageQueryValue === 'images' && (
									<Box
										sx={{
											position: 'relative',
											height: '512px',
											width: '512px',
											background: 'linear-gradient(to bottom left,  #11D3AF, #6637C4)',
											cursor: 'pointer',
											overflow: 'hidden',
										}}
									>
										<CardMedia
											component='div'
											sx={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											height: '100%',
											objectFit: 'cover',
											}}
											image={constants.IMAGE_URL + cardsToRender[0]?.image?.image}
											alt={'generated image'}
										/>
									</Box>	
								)}
							</>
						)}
					{/* DISPLAY SELECTED CARD ITEM DESCRIPTION */}

					{query.get('item') &&
						query.get('item') !== 'null' &&
						cardsToRender[query.get('item')] && (
							<>
								{(pageQueryValue === 'folders' && pageRerenderFlag) && (
									<EditFolder
										folder={cardsToRender[query.get('item')]}
									/>
								)}
								{(pageQueryValue === 'folders' && !pageRerenderFlag) && (
									<EditFolder
									folder={cardsToRender[query.get('item')]}
								/>
								)}
								{(pageQueryValue === 'pages' && pageRerenderFlag) && (
									<EditPage
										page={cardsToRender[query.get('item')]?.page}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										addNewImage={() => setAddNewImageOpen(true)}
										images={images}
										setImages={setImages}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{(pageQueryValue === 'pages' && !pageRerenderFlag) && (
									<EditPage
										page={cardsToRender[query.get('item')]?.page}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										addNewImage={() => setAddNewImageOpen(true)}
										images={images}
										setImages={setImages}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{pageQueryValue === 'characters' && (
									<EditCharacter
										character={
											cardsToRender[query.get('item')]
												?.character
										}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										handleExportPdf={handleExportPdf}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										handleProjectHeaderVisibility={(flag)=> handleProjectHeaderVisibility(flag)}
										currentProject={currentProject}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{pageQueryValue === 'story' && (
									<EditStory
										story={
											cardsToRender[query.get('item')]
												?.story
										}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										setting={setting}
										characters={characters}
										handleExportPdf={handleExportPdf}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										handleProjectHeaderVisibility={(flag)=> handleProjectHeaderVisibility(flag)}
										episodesArray={episodesArray}
										currentProject={currentProject}
										setTokensFillModal={setTokensFillModal}
									/>
								)}

								{pageQueryValue === 'setting' && (
									<EditSetting
										propsSettingItem={
											cardsToRender[query.get('item')]
												?.settingItem
										}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										handleExportPdf={handleExportPdf}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										handleProjectHeaderVisibility={(flag)=> handleProjectHeaderVisibility(flag)}
										currentProject={currentProject}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{pageQueryValue === 'images' && (
									<Box
										sx={{
											position: 'relative',
											width: {
												xs: '80vw',
												sm: '60vw'
											},
											height: {
												xs: '80vw',
												sm: '60vw'
											},
											background: 'linear-gradient(to bottom left,  #11D3AF, #6637C4)',
											cursor: 'pointer',
											overflow: 'hidden',
										}}
									>
										<CardMedia
											component='div'
											sx={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											height: '100%',
											objectFit: 'cover',
											}}
											image={constants.IMAGE_URL + cardsToRender[query.get('item')]?.image?.image}
											alt={'generated image'}
										/>
									</Box>	
								)}
							</>
						)}
				</Toolbar>
			</Box>
		</Box>
		</>
	);
};

export default Project;
