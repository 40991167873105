import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import * as constants from '../../helpers/constants'
import { useNavigate } from 'react-router-dom';
import story from '../../assets/images/story.png';
import script from '../../assets/images/script.png';
import vaEngage from '../../assets/images/vaEngage.png';

const Products = () => {
  const navigate = useNavigate();
    return (
        <Container component="main" maxWidth='lg'>
          <CssBaseline />
              <Box
                sx={{
                  marginTop: {
                    xs: 2,
                    md: 8,
                  },
                  marginBottom: 4,
                  display: {
                    xs: 'flex',
                    md: 'grid',
                  },
                  justifyContent: {
                    md: 'space-between',
                    xs: 'space-around',
                  },
                  gridTemplateColumns: '1fr 1fr 1fr',
                  flexDirection: {
                    xs: 'column',
                    md: 'row',
                  },
                  flexWrap: {
                    xs: 'wrap',
                  },
                  gap: '30px',
                  position: 'relative',
                  paddingBottom: '100px',
                }}
              >
                <Box
                onClick={()=> navigate('/projects')}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems:"center",
                    width: '100%',
                    backgroundColor: constants.DARK_GREY,
                    borderRadius: '8px',
                    padding: '15px',
                    cursor: 'pointer',
                    border: '1px solid ' + constants.TEAL,
                  }}
                >
                   <img src={story} alt="product icon" style={{height: '80px', width: 'auto'}} />
                  
                   <Typography align="center" sx={{fontSize: '20px', fontWeight: ''}}>Our revolutionary AI writing tool</Typography>
                  
              </Box>
              <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems:"center",
                    width: '100%',
                    backgroundColor: constants.DARK_GREY,
                    borderRadius: '8px',
                    padding: '15px',
                    cursor: 'pointer',
                    border: '1px solid ' + constants.TEAL,
                  }}
                >
                  <img src={script} alt="product icon" style={{height: '80px', width: 'auto'}} />
                  <Typography align="center" sx={{fontSize: '20px', fontWeight: 'bold'}}>COMING SOON</Typography>
                   <Typography align="center" sx={{fontSize: '20px', fontWeight: ''}}>Style Analysis and Production Planning. Feed our AI model a completed script, and it will return writing analysis and breakdown major pieces to help with production planning.</Typography>
                  
              </Box>
              <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems:"center",
                    width: '100%',
                    backgroundColor: constants.DARK_GREY,
                    borderRadius: '8px',
                    padding: '15px',
                    cursor: 'pointer',
                    border: '1px solid ' + constants.TEAL,
                  }}
                >
                   <img src={vaEngage} alt="product icon" style={{height: '80px', width: 'auto'}} />
                   <Typography align="center" sx={{fontSize: '20px', fontWeight: 'bold'}}>COMING SOON</Typography>
                   <Typography align="center" sx={{fontSize: '20px', fontWeight: ''}}>As our workforces grow more mobile and remote, how do we insure that everyone is on the same page? Our AI tools will help keep employees on task, engaged, and in the know. </Typography>
                   
              </Box>
            </Box>
      </Container>
  );
};

export default Products;

