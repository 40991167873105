import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import {  Typography } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import * as constants from '../../../helpers/constants';
import useQuery from '../../../helpers/hooks/useQuery';
import { logout } from '../../../store/slices/authSlice';
import imageCompression from 'browser-image-compression';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '100%',
		sm: '400px',
	},
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export default function AddNewStory({
	open,
	setOpen,
	episodesArray,
	setCardsToRenderFunc,
	cardsToRenderLength
}) {
	const handleClose = () => setOpen(false);
	const stateLoading = useSelector((state) => state.loading.loading);
	const dispatch = useDispatch();
	const location = useLocation();
	const alert = useAlert();
	const query = useQuery();
	const navigate = useNavigate();
	const episodesQueryValue = query.get('episodes');
	const token = useSelector((state) => state.auth.token);
	const projectId = () =>
		location.pathname.slice(9, location.pathname.length);
	const [searchParams, setSearchParams] = useSearchParams();
	const [name, setName] = useState();
	const [imageFile, setImageFile] = useState();
	const [imageInput, setImageInput] = useState();
	const [imageName, setImageName] = useState('');
	


	const handleSubmit = async (e) => {
		if (stateLoading) {
			return
		}
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		if (data.get('image')?.size === 0) data.delete('image');
		data.append('order',cardsToRenderLength)
		data.append('acts',JSON.stringify([
			{
				list_of_scenes: '',
				list_of_acts_ai_adjust: '',
				actSetting: [],
				actCharacters: [],
				scenes: [{ 
					scene_title: 'Scene 1',
					list_of_beats_for_the_scene: '',
					list_of_beats_for_the_scene_ai_adjust: '',
					scene_full_script: '',
					scene_full_script_ai_adjust: '',
					characters: [],
					setting: [],
					scriptSceneFormat: 'Script',
				}]
			}
		]))
		if (data.get('title')) {
			if (await checkImageSize(imageInput)) {
				dispatch(addLoadingFlag('set-story'));
					if (data.get('image')) {
						const file = data.get('image')
						data.delete('image');
						try {
							const options = {
								maxSizeMB: 0.90,
							}
							const compressedFile = await imageCompression(file, options);
							data.append('image',compressedFile)
							await request()
	
						} catch (error) {
							alert.show(
								`Something went wrong with image compression.`,
								{ type: 'error' }
							);
						}
					} else {
						await request()
					}			
				
			}
		}
		async function request () {
			if (episodesQueryValue !== '0' && episodesArray[parseInt(episodesQueryValue) - 1]?.id) {
				await axios
					.post(
						constants.BASE_URL +
							'api/projects/set-episode-story/' +
							projectId() +
							'/' +
							episodesArray[parseInt(episodesQueryValue) - 1]
								?.id,
						data,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(function (response) {
						handleClose();
						const params = {};
						for(let entry of searchParams.entries()) {
						  params[entry[0]] = entry[1];
						}
						console.log(params)
						setSearchParams({...params, item: cardsToRenderLength})
						alert.show(
							'New story was successfully added!',
							{
								type: 'success',
							}
						);
						dispatch(removeLoadingFlag('set-story'));
						setCardsToRenderFunc();
						setName('')
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('set-story'));
						if (error.response?.data === 'Non existing user.') {
							navigate('login');
							dispatch(logout());
						}
					
						alert.show(
							`Something went wrong, could not add illustrations.`,
							{ type: 'error' }
						);
						
					});
			} else {
				await axios
					.post(
						constants.BASE_URL +
							'api/projects/set-story/' +
							projectId(),
						data,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(function (response) {
						handleClose();
						const params = {};
						for(let entry of searchParams.entries()) {
						  params[entry[0]] = entry[1];
						}
						console.log(params)
						setSearchParams({...params, item: cardsToRenderLength})
						alert.show(
							'New story was successfully added!',
							{
								type: 'success',
							}
						);
						dispatch(removeLoadingFlag('set-story'));
						setCardsToRenderFunc();
						setName('')
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('set-story'));
						if (error.response?.data === 'Non existing user.') {
							navigate('login');
							dispatch(logout());
						}
						
						alert.show(
							`Something went wrong, could not add illustrations.`,
							{ type: 'error' }
						);
						
					});
			}
		}
	};
	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			if (file.size > 1024 * 1024 * 4) {
				console.log('error');
				setImageFile('');
				setImageInput('');
				setImageName('');
				// 4MB in bytes
				alert.show(`Image is too large. Maximum allowed size is 4MB.`, {
					type: 'error',
				});
			} else {
				// Proceed with image upload
				setImageFile(event.target.value);
				setImageInput('');
				setImageName(file.name);
			}
		}
	};

	const checkImageSize = (url) => {
		return fetch(url)
			.then((response) => {
				if (response.headers.get('content-length') > 1024 * 1024 * 4) {
					alert.show(
						`Image is too large. Maximum allowed size is 4MB.`,
						{
							type: 'error',
						}
					);
					setImageFile('');
					setImageInput('');
					setImageName('');
					return false;
				} else {
					// Proceed with using the image
					setImageFile('');
					setImageInput(url);
					setImageName('');

					return true;
				}
			})
			.catch((error) => {
				alert.show(
					`Something wrong with image`,
					{ type: 'error' }
				);
				console.error('Error fetching the image:', error);
				return false;
			});
	};
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					component='form'
					onSubmit={handleSubmit}
					noValidate
					sx={style}
				>
					<Typography sx={{ m: 0, p: 2 }}>
						ADD NEW STORY
					</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
					<Box
						sx={{
							maxHeight: '60vh',
							overflow: 'scroll',
							paddingRight: '8px',
							position: 'relative',
							'&::-webkit-scrollbar': {
								width: '2px',
								height: 0,
							},
							'&::-webkit-scrollbar-thumb': {
								borderRadius: '6px',
								backgroundColor: constants.ORANGE,
							},
						}}
					>
						<TextField
							margin='normal'
							required
							fullWidth
							id='title'
							label='Title'
							name='title'
							autoFocus
							placeholder='Dark night'
							value={name}
							InputLabelProps={{ shrink: true }}
							onChange={(e) => setName(e.target.value)}
						/>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<TextField
								margin='normal'
								fullWidth
								name='imageURL'
								label='Image link'
								id='image'
								value={imageInput}
								onChange={(e) => {
									setImageInput(e.target.value);
									setImageFile('');
									setImageName('');
								}}
								InputLabelProps={{ shrink: true }}
							/>
							<Typography sx={{ m: 0, p: 2 }}>OR</Typography>
							<Button
								variant='contained'
								component='label'
								sx={{
									height: '56px',
									marginTop: '16px',
									marginBottom: '8px',
								}}
							>
								Upload
								<input
									name='image'
									value={imageFile}
									hidden
									accept='image/*'
									onChange={(e) => {
										handleFileChange(e);
									}}
									type='file'
								/>
							</Button>
						</div>
						<TextField
							margin='normal'
							fullWidth
							name='imageName'
							label='Name of uploaded image'
							id='imageName'
							value={imageName}
							InputLabelProps={{ shrink: true }}
							placeholder=''
							disabled
						/>
					</Box>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
					>
						Create
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
