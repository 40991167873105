import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { 
    // CardActionArea,
    CardActions,
    Typography,
    Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import DeletePage from '../modals/DeletePage';
import { SortableElement } from 'react-sortable-hoc';
import * as constants from '../../helpers/constants'

const ComicPageCardGrid = SortableElement(({page,imageIndex,epizodeIndex,onCardHandleClick,index}) => {
    const [deletePageOpen, setDeletePageOpen] = useState(false);
    return (
        <Card 
            
            sx={{p: 1, maxWidth: '186px', zIndex: '99999'}}
            onClick={() => onCardHandleClick()}
        >
            <div style={{position: 'relative'}}>
            <DeletePage open={deletePageOpen} setOpen={setDeletePageOpen} imageIndex={imageIndex} epizodeIndex={epizodeIndex}/>
            {/* <CardActionArea> */}
            {(page?.image !== 'default') && (
            <Box
                sx={(theme) => ({
                    position: 'relative',
                    height: 210,
                    width: 170,
                    background: `linear-gradient(to bottom left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    cursor: 'pointer',
                    overflow: 'hidden',
                })}
            >
                <CardMedia
                    component='div'
                    sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: 2,
                    }}
                    image={constants.IMAGE_URL + page?.image + '?time=' + page?.lastImageUpdate}
                    alt="#"
                />
                <Typography
								sx={{
									position: 'absolute',
									top: '30%',
									//left: '35%',
									left: '0',
									right: '0',
									textAlign: 'center',
									color: 'black',
									fontSize: '40px',
									textTransform: 'uppercase',
									color: 'white',
									zIndex: 1,
								}}
							>
									{page?.title?.split(/\s/).slice(0, 3).map(word => word[0]).join('')}
							</Typography>
            </Box>
        )}
              {(page?.image === 'default') && (
							<Box sx={{
								position: 'relative',
							}}>
								<CardMedia
									component='div'
									sx={(theme) => ({
										height: 210,
										width: 170,
										background: `linear-gradient(to bottom left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
									})}
									
									alt={page?.alt}
									onClick={() => onCardHandleClick()}
							
								/>	
								<Typography
									sx={{
										position: 'absolute',
										top: '35%',
										left: '35%',
										color: 'black',
										fontSize: '40px',
										textTransform: 'uppercase',
										color: 'white'
									}}
								>
									{page?.title?.split(/\s/).slice(0, 3).map(word => word[0]).join('')}
								</Typography>
							</Box>
						)}
            <CardActions>
                <Typography 
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{
                        textAlign: 'left',
                        width: '100%',
                        fontSize: '16px',
                        paddingTop: '10px',
                        // maxWidth: 'calc(100% - 20px)',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    Page {imageIndex + 1}
                </Typography>
            </CardActions>
            {/* </CardActionArea> */}
            <Tooltip>
            <IconButton
                onClick={(event) => {
                    event.stopPropagation();
                    setDeletePageOpen(true)
                }}
                size="small"
                sx={{ ml: 2,
                    position: 'absolute',
                    right: '0',
                    bottom: '7px',
                    display: 'none'
                }}
                aria-haspopup="true"
            >
                <Avatar sx={{ width: 24, height: 24, bgcolor: 'transparent' }}>
                    <DeleteIcon style={{color: '#fff' }}/>
                </Avatar>
            </IconButton>
            </Tooltip>
        </div>
        </Card>
    );
})

export default ComicPageCardGrid;
