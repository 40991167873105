import React, { useState } from 'react';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import * as constants from '../../helpers/constants'
import axios from 'axios';
import { useAlert } from 'react-alert'
import logo from '../../assets/images/Logo.png'
import CardMedia from '@mui/material/CardMedia';
import { useSelector } from 'react-redux';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
      Storybuilt.AI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isPasswordValid, setIsPasswordValid] = useState(true)
  const stateLoading = useSelector((state) => state.loading.loading);

  const handleSubmit = async (e) => {
    if (stateLoading) {
      return
    }
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    if (!constants.EMAIL_REGEXP.test(data.get('email'))) {
      setIsEmailValid(false);
      alert.show('Email is not valid!',{type: 'error'})
      return
    } else if (data.get('password').length < 6) {
      setIsPasswordValid(false);
      alert.show('Password should be longer!',{type: 'error'})
      return
    } else {
      await axios.post(constants.BASE_URL + 'api/auth/register', {
        email: data.get('email'),
        password: data.get('password')
      })
      .then(function (response) {

        const payload = {
          user: data.get('email'),
          token: response.data.token
        };
        dispatch(setUser(payload));
        setIsEmailValid(true);
        setIsPasswordValid(true);
        alert.show('Account was successfully created',{type: 'success'})
        setTimeout(()=>navigate('/login'),6000) 
    
      })
      .catch(function (error) {
        setIsEmailValid(false);
        setIsPasswordValid(false);
        alert.show('Wrong credentials, or user has already exist',{type: 'error'})
      });
    }
  };

    return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: constants.ORANGE }}> */}
              {/* <LockOutlinedIcon /> */}
              <CardMedia
                  component="img"
                  sx={{
                      height: 60,
                      width: 'auto',
                      cursor: 'pointer'
                    }}
                  image={logo}
                  alt="#"
              />
            {/* </Avatar> */}
            <Typography component="h1" variant="h5">
              Create new account
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={!isEmailValid}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={!isPasswordValid}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, color: '#fff' }}
              >
                Create
              </Button>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
  );
};

export default Register;
