import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {
	// CardActionArea,
	CardActions,
	Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChangeSettingItem from '../modals/ChangeSettingItem';
import DeleteSettingItem from '../modals/DeleteSettingItem';
import { Draggable } from 'react-beautiful-dnd';
import * as constants from '../../helpers/constants';
import { Box } from '@mui/material';

export default function ComicSettingCard({
	settingItem,
	settingItemId,
	epizodeIndex,
	onCardHandleClick,
	index,
	setCardsToRenderFunc,
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [changeSettingItemOpen, setChangeSettingItemOpen] = useState(false);
	const [deleteSettingItemOpen, setDeleteSettingItemOpen] = useState(false);
	const [cardChangeModalFlag, setCardChangeModalFlag] = useState(false);
	const open = Boolean(anchorEl);
	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
		event.stopPropagation();
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setCardChangeModalFlag(!cardChangeModalFlag);
	}, [settingItem]);

	return (
		<Draggable draggableId={'dragable-' + index} index={index}>
			{(provided) => (
				<Card
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					sx={{ p: 0, maxWidth: '140px' }}
				>
					<div style={{ position: 'relative' }}>
						{cardChangeModalFlag && (
							<ChangeSettingItem
								setCardsToRenderFunc={setCardsToRenderFunc}
								open={changeSettingItemOpen}
								setOpen={setChangeSettingItemOpen}
								propsSettingItem={settingItem}
								settingItemId={settingItemId}
								epizodeIndex={epizodeIndex}
							/>
						)}
						{!cardChangeModalFlag && (
							<ChangeSettingItem
								setCardsToRenderFunc={setCardsToRenderFunc}
								open={changeSettingItemOpen}
								setOpen={setChangeSettingItemOpen}
								propsSettingItem={settingItem}
								settingItemId={settingItemId}
								epizodeIndex={epizodeIndex}
							/>
						)}
						<DeleteSettingItem
							setCardsToRenderFunc={setCardsToRenderFunc}
							open={deleteSettingItemOpen}
							setOpen={setDeleteSettingItemOpen}
							propsSettingItem={settingItem}
							settingItemId={settingItemId}
							epizodeIndex={epizodeIndex}
						/>

						{/* <CardActionArea> */}
						
						{(settingItem?.image !== 'default') && (
							 <Box
								onClick={onCardHandleClick}
								sx={(theme) => ({
									position: 'relative',
									height: 140,
									width: 140,
									background: `linear-gradient(to bottom left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
									cursor: 'pointer',
									overflow: 'hidden',
								})}
							>
								<CardMedia
									component='div'
									sx={{
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									zIndex: 2,
									}}
									image={
										constants.IMAGE_URL +
										settingItem?.image +
										'?time=' +
										settingItem?.lastImageUpdate
									}
									alt={settingItem?.alt}
									onClick={() => onCardHandleClick()}
								/>
								<Typography
								sx={{
									position: 'absolute',
									top: '30%',
									//left: '35%',
									left: '0',
									right: '0',
									textAlign: 'center',
									color: 'black',
									fontSize: '40px',
									textTransform: 'uppercase',
									color: 'white',
									zIndex: 1,
								}}
							>
									{settingItem?.title?.split(/\s/).slice(0, 3).map(word => word[0]).join('')}
							</Typography>
							</Box>						
						)}
						{(settingItem?.image == 'default') && (
							<Box onClick={() => onCardHandleClick()} sx={{
								position: 'relative',
							}}>
								<CardMedia
									component='div'
									sx={(theme) => ({
										height: 140,
										width: 140,
										background: `linear-gradient(to bottom left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
									})}
									alt={settingItem?.alt}
									onClick={() => onCardHandleClick()}
								
								/>	
								<Typography
									sx={{
										position: 'absolute',
										top: '30%',
										//left: '35%',
										left: '0',
										right: '0',
										textAlign: 'center',
										color: 'black',
										fontSize: '40px',
										textTransform: 'uppercase',
										color: 'white'
									}}
								>
										{settingItem?.title?.split(/\s/).slice(0, 3).map(word => word[0]).join('')}
								</Typography>
							</Box>
						)}

						<Menu
							anchorEl={anchorEl}
							id='account-menu'
							open={open}
							onClose={handleClose}
							onClick={handleClose}
							transformOrigin={{
								horizontal: 'right',
								vertical: 'top',
							}}
							anchorOrigin={{
								horizontal: 'right',
								vertical: 'bottom',
							}}
						>
							<MenuItem
								onClick={() => setChangeSettingItemOpen(true)}
							>
								<Typography textAlign='center'>
									Change photo
								</Typography>
							</MenuItem>
							<MenuItem
								onClick={() => setDeleteSettingItemOpen(true)}
							>
								<Typography textAlign='center'>
									Delete
								</Typography>
							</MenuItem>
						</Menu>

						<CardActions>
							<Typography
								//gutterBottom
								variant='h5'
								component='div'
								sx={{
									textAlign: 'left',
									width: '100%',
									fontSize: '16px',
									paddingTop: '0px',
									paddingLeft: "2px",
									maxWidth: 'calc(100% - 18px)',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
								}}
								onClick={() => onCardHandleClick()}
							>
								{settingItem?.title}
							</Typography>
						</CardActions>
						{/* </CardActionArea> */}
						<Tooltip>
							<IconButton
								onClick={(event) => handleOpen(event)}
								size='small'
								sx={{
									ml: 2,
									position: 'absolute',
									right: '0px',
									bottom: '0px',
								}}
								aria-controls={
									open ? 'account-menu' : undefined
								}
								aria-haspopup='true'
								aria-expanded={open ? 'true' : undefined}
							>
								<Avatar
									sx={{
										width: 24,
										height: 24,
										bgcolor: 'transparent',
									}}
								>
									<MoreVertIcon style={{ color: '#fff' }} />
								</Avatar>
							</IconButton>
						</Tooltip>
					</div>
				</Card>
			)}
		</Draggable>
	);
}
