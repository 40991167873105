import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import * as constants from '../../../helpers/constants';
import { logout } from '../../../store/slices/authSlice';


const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '100%',
		sm: '400px',
	},
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export default function ChangePassword({
	open,
	setOpen,
}) {
	const handleClose = () => setOpen(false);
	const stateLoading = useSelector((state) => state.loading.loading);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [oldPassword,setOldPassword] = useState('')
	const [newPassword,setNewPassword] = useState('')
	const [newRepeatePassword,setNewRepeatePassword] = useState('')
	const submitActive = useRef(false)
	const userMail = localStorage.getItem('email')
	
	const alert = useAlert();

	useEffect(() => {
		setOldPassword('')
		setNewPassword('')
		setNewRepeatePassword('')
	}, [open])

	const handleSubmit = async (e) => {
		if (stateLoading || submitActive.current) {
			return
		}
		e.preventDefault();
		if (newPassword !== newRepeatePassword) {
			alert.show(
				`New Password and Confirm Password Field do not match`,
				{ type: 'error' }
			);
		} else if (newPassword.length < 6) {
			alert.show(
				`New Password is too short`,
				{ type: 'error' }
			);
		} else if (oldPassword.length < 6) {
			alert.show(
				`Old Password is too short`,
				{ type: 'error' }
			);
		} else {
			submitActive.current = true
			dispatch(addLoadingFlag('update-password'));
			await axios
					.post(
						constants.BASE_URL +
							'api/auth/change-password',
						{
							newPassword,
							oldPassword,
							email: userMail
						}
					)
					.then(function (response) {
						dispatch(removeLoadingFlag('update-password'));
						handleClose();
						alert.show(
							`Password was successfully changed!`,
							{ type: 'success' }
						);
						setOldPassword('')
						setNewPassword('')
						setNewRepeatePassword('')
						submitActive.current = false
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('update-password'));
						if (error.response?.data === 'Non existing user.') {
							navigate('login');
							dispatch(logout());
						}
						alert.show(
							error.response?.data,
							{ type: 'error' }
						);
						submitActive.current = false
					});
		}
		
	};

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					component='form'
					onSubmit={handleSubmit}
					noValidate
					sx={style}
				>
					<Typography sx={{ m: 0, p: 2 }}>
						CHANGE PASSWORD
					</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
					<Box
						sx={{
							maxHeight: '60vh',
							overflow: 'scroll',
							paddingRight: '8px',
							position: 'relative',
							'&::-webkit-scrollbar': {
								width: '2px',
								height: 0,
							},
							'&::-webkit-scrollbar-thumb': {
								borderRadius: '6px',
								backgroundColor: constants.ORANGE,
							},
						}}
					>
						
						<TextField
							margin='normal'
							required
							fullWidth
							type={'password'}
							id='oldPassword'
							label='Old password'
							name='oldPassword'
							autoFocus
							placeholder='Your current password'
							value={oldPassword}
							InputLabelProps={{ shrink: true }}
							onChange={(e) => setOldPassword(e.target.value)}
						/>
						<TextField
							margin='normal'
							required
							fullWidth
							type={'password'}
							id='newPassword'
							label='New password'
							name='newPassword'
							autoFocus
							placeholder='Your new password'
							value={newPassword}
							InputLabelProps={{ shrink: true }}
							onChange={(e) => setNewPassword(e.target.value)}
						/>
						<TextField
							margin='normal'
							required
							fullWidth
							type={'password'}
							id='newRepeatPassword'
							label='Confirm your new password'
							name='newRepeatPassword'
							autoFocus
							placeholder='Repeat your new password'
							value={newRepeatePassword}
							InputLabelProps={{ shrink: true }}
							onChange={(e) => setNewRepeatePassword(e.target.value)}
						/>
						
					</Box>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
					>
						Confirm
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
