import axios from 'axios';
import { useState, useRef } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import {Box,TextField,} from '@mui/material';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import { logout } from '../../../store/slices/authSlice';
import * as constants from '../../../helpers/constants';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import deepObjectCompare from '../../../helpers/deepObjectCompare';
import CircularProgress from '@mui/material/CircularProgress';
import StopIcon from '@mui/icons-material/Stop';
import { setTokens } from '../../../store/slices/authSlice';
import { styled } from '@mui/material/styles';

const style = {
	width: '100%',
	display: 'flex',
	'flex-direction': 'column',
};

const fieldContainerStyles = {
	display: 'grid',
	gridTemplateColumns: 'auto 1fr',
	justifyContent: 'space-between',
	gap: '0.5rem',
};

const GenerationButton = styled(Button)({
	height: '46px',
	width: '46px',
	marginTop: '22px',
	marginBottom: '8px',
	fontSize: '36px',
	});

export default function EditSetting({
	propsSettingItem,
	handleProjectHeaderVisibility,
	setCardsToRenderFunc,
	handleExportPdf,
	doesCardHaveUnsavedData,
	currentProject,
	setTokensFillModal
}) {
	const [loading, setLoading] = useState(false);
	const stateLoading = useSelector((state) => state.loading.loading);
	const dispatch = useDispatch();
	const alert = useAlert();
	const token = useSelector((state) => state.auth.token);
	const [characterName, setCharacterName] = useState('');
	const navigate = useNavigate();

	const [title, setTitle] = useState(propsSettingItem?.title);
	const [environment, setEnvironment] = useState(
		propsSettingItem?.environment
	);
	const [area, setArea] = useState(propsSettingItem?.area);
	const [time_of_year, setTime_of_year] = useState(
		propsSettingItem?.time_of_year
	);
	const [unique_characteristics, setUnique_characteristics] = useState(
		propsSettingItem?.unique_characteristics
	);
	const [atmosphere, setAtmosphere] = useState(propsSettingItem?.atmosphere);
	const [climate, setClimate] = useState(propsSettingItem?.climate);
	const currentSocket = useRef()
	const currentWebSocketObject = useRef();
	const currentGenerationSetFunc = useRef()
	const [currentGenerationFieldKey,setCurrentGenerationFieldKey] = useState()
	const [isGenerationButtonAllowed, setIsGenerationButtonAllowed] = useState(true);
	const hideTokensFillModal = useSelector((state) => state.auth.hideTokensFillModal);

	function checkIfCardHasUnsavedData () {
		if (!propsSettingItem) return
		
		console.log(propsSettingItem)
		const newCardData = {
			title,
		}

		if (environment)  newCardData.environment = environment
		if (area) newCardData.area = area
		if (time_of_year) newCardData.time_of_year = time_of_year
		if (unique_characteristics) newCardData.unique_characteristics = unique_characteristics
		if (atmosphere) newCardData.atmosphere = atmosphere
		if (climate) newCardData.climate = climate

		const isEqual = deepObjectCompare(newCardData,propsSettingItem)

		console.log(newCardData)
		console.log(propsSettingItem)

		if (!isEqual) {
			doesCardHaveUnsavedData.current = true
		} else {
			doesCardHaveUnsavedData.current = false
		}
	}

	checkIfCardHasUnsavedData()

	const getUserTokens = () => {
		axios
		.get(
		  constants.BASE_URL + 'api/auth/get-user-info',
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  console.log(response.data)
		  dispatch(setTokens(response.data?.tokens))
		}).catch(function (error) {
		  console.log(error);
		});
	  }

	useEffect( () => {
		webSocketConnect();
	
		return () => {
			if (currentWebSocketObject.current) {
				currentWebSocketObject.current?.close();
				currentSocket.current = null;
				currentWebSocketObject.current = null
			}
		
		};
	 }, []); 

	 async function webSocketConnect () {
		return new Promise((resolve, reject) => {
			const socket = new WebSocket(constants.BASE_WS_URL); // WebSocket server address
			socket.onopen = () => {
				console.log('WebSocket connection established');
			};

			socket.onmessage = (event) => {
				console.log('Received message:', event.data);
				let data = event.data
				try {
					data = JSON.parse(event.data); // Parse the JSON string into an object
				} catch (error) {
					console.error('Error parsing JSON:', error);
				}
				console.log('Received message obj:', data);

				if (data?.type === 'clientId') {
					console.log('clientID set')
					currentSocket.current = data.clientId
					currentWebSocketObject.current= socket
					resolve(socket)
				}

				if (data?.type === 'content') {
					console.log('got new images')
					currentGenerationSetFunc.current(prev => prev + data.content)
				}
				if (data?.type === 'message' && data?.message === 'first generation') {
					setLoading(false)
					currentGenerationSetFunc.current('')
				}
				if (data?.type === 'message' && data?.message === 'last generation') {
					alert.show(`Text was successfully generated`, {
						type: 'success',
					});
					setLoading(false)
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
					getUserTokens()
				}
			
            // Handle incoming messages from the WebSocket server
			};

			socket.onerror = (error) => {
				console.error('WebSocket error:', error);
				// Handle WebSocket connection errors
			};

			socket.onclose = () => {
				console.log('WebSocket connection closed');
				currentSocket.current = null
				setIsGenerationButtonAllowed(true)
				setCurrentGenerationFieldKey('');
				// Handle WebSocket connection closure
			};

		});
	}

	useEffect(() => {
		setTitle(propsSettingItem?.title ?? '');
		setEnvironment(propsSettingItem?.environment ?? '');
		setArea(propsSettingItem?.area ?? '');
		setTime_of_year(propsSettingItem?.time_of_year ?? '');
		setUnique_characteristics(
			propsSettingItem?.unique_characteristics ?? ''
		);
		setAtmosphere(propsSettingItem?.atmosphere ?? '');
		setClimate(propsSettingItem?.climate ?? '');
	}, [propsSettingItem]);

	const handleFillFieldWithAI = async (fieldKey) => {
		if (!currentSocket.current) {
			try {
				await webSocketConnect();
				if (currentWebSocketObject.current.readyState === WebSocket.OPEN) {
					generationFunction();
				}
			} catch (error) {
				console.error('Error connecting to WebSocket:', error);
			}
		} else {
			generationFunction();
		}
		async function generationFunction () {
			setIsGenerationButtonAllowed(false)
			const allFields = {
				title,
				environment,
				area,
				time_of_year,
				unique_characteristics,
				atmosphere,
				climate,
			};
			const allSetFieldFunctions = [
				setTitle,
				setEnvironment,
				setArea,
				setTime_of_year,
				setUnique_characteristics,
				setAtmosphere,
				setClimate,
			];
			const currentFieldSetFunctionIndex = Object.keys(allFields).findIndex(
				(key) => key === fieldKey
			);

			const objectWithoutCurrentField = Object.keys(allFields)
				.filter((key) => key !== fieldKey && allFields[key])
				.reduce((obj, key) => {
					return Object.assign(obj, {
						[key]: allFields[key],
					});
				}, {});
			currentGenerationSetFunc.current = allSetFieldFunctions[currentFieldSetFunctionIndex];
			setCurrentGenerationFieldKey(fieldKey);
			setLoading(true);
			axios
				.post(
					constants.BASE_URL + 'api/ai/generate-setting-field',
					{
						baseFields: { ...objectWithoutCurrentField },
						fieldToFill: fieldKey,
						projectTitle: currentProject?.title,
						projectDescription: currentProject?.description,
						clientId: currentSocket.current
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					// allSetFieldFunctions[currentFieldSetFunctionIndex](
					// 	response.data
					// );
					// setLoading(false);
					// console.log(response);
					// alert.show(
					// 	`Text was successfully generated`,
					// 	{ type: 'success' }
					// );
				})
				.catch(function (error) {
					if (error.response?.data === 'error with openAi') {
						alert.show(
						  `Something went wrong with AI server`,
						  { type: 'error' }
						);
					}  else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
						if (localStorage.getItem('role')=== 'corporate user') {
						  alert.show(
							`Something wrong with subscription, please note your corporate admin`,
							{ type: 'error' }
						  );
						} else {
						  alert.show(
							`Subsctiption is required for generation`,
							{ type: 'error' }
						  );
						}
									
					  } else if (error.response?.data === 'Not enought tokens') {
						if (localStorage.getItem('role')=== 'corporate user') {
						  alert.show(
							`Something wrong with token count, please note your corporate admin`,
							{ type: 'error' }
						  );
						} else {
							if (!hideTokensFillModal){
								setTokensFillModal(true)
							  }
						  alert.show(
							`You don't have enough tokens to perform this action`,
							{ type: 'error' }
						  );
						}
								
					} else {
						alert.show(
							`Something went wrong with text generation`,
							{ type: 'error' }
						);
						console.log(error);
					}
					setLoading(false);
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
				});
		}
	};

	function sendMessageToServer(message) {
		console.log('ready state',currentWebSocketObject.current)
		if (currentWebSocketObject.current.readyState === WebSocket.OPEN) {
			currentWebSocketObject.current.send(message);
			console.log('Message sent to server:', message);
		} else {
			console.error('WebSocket connection is not open or established.');
		}
	}

	function stopGeneration () {
		sendMessageToServer('stop generation')
	}

	const handleSubmit = async (e) => {
		if (stateLoading) {
			return
		}
		e.preventDefault();
		const data = new FormData(e.currentTarget);

		if (data.get('title')) {
			setCharacterName(data.get('title'));
			dispatch(addLoadingFlag('update-setting'));
			await axios
				.post(
					constants.BASE_URL +
						'api/projects/update-setting-by-id/' +
						propsSettingItem.id,
					data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					alert.show(
						`'${
							data.get('title')
						}' was successfully changed!`,
						{ type: 'success' }
					);
					dispatch(removeLoadingFlag('update-setting'));
					setCardsToRenderFunc();
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('update-setting'));
					if (error.response?.data === 'Non existing user.') {
						navigate('login');
						dispatch(logout());
					}
					
				
						alert.show(
							`Something went wrong, could not add illustrations.`,
							{ type: 'error' }
						);
					
				});
		}
	};

	return (
		<Box component='form' onSubmit={handleSubmit} noValidate sx={style}>
				<>
					<Box
						sx={{
							overflow: 'scroll',
							paddingRight: '8px',
							'&::-webkit-scrollbar': {
								width: '2px',
								height: 0,
							},
							'&::-webkit-scrollbar-thumb': {
								borderRadius: '6px',
								backgroundColor: constants.PRIMARY,
							},
						}}
					>
						<Box sx={fieldContainerStyles}>
							<GenerationButton
								onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('title')}
								variant='contained'
								component='label'
								sx={{
									
								}}
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'title') : false}
								>
									{(loading && currentGenerationFieldKey === 'title') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'title') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							<TextField
								margin='normal'
								required
								fullWidth
								id='title'
								label='Title'
								name='title'
								autoFocus
								placeholder='Name this Setting or Location'
								value={title}
								InputLabelProps={{ shrink: true }}
								onChange={(e) => setTitle(e.target.value)}
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
								disabled={currentGenerationFieldKey === 'title' ? true : false}
							/>
						</Box>

						<Box sx={fieldContainerStyles}>
							<GenerationButton
								onClick={() =>
									!isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('environment')
								}
								variant='contained'
								component='label'
								sx={{
									
								}}
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'environment') : false}
								>
									{(loading && currentGenerationFieldKey === 'environment') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'environment') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							<TextField
								margin='normal'
								fullWidth
								multiline
								minRows={4}
								name='environment'
								label='What is it?'
								InputLabelProps={{ shrink: true }}
								id='environment'
								value={environment}
								onChange={(e) => setEnvironment(e.target.value)}
								placeholder='Write a brief description of what this is. Is it a HQ for your characters, a time period, a season or holiday?'
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
								disabled={currentGenerationFieldKey === 'environment' ? true : false}
							/>
						</Box>

						<Box sx={fieldContainerStyles}>
							<GenerationButton
								onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('area')}
								variant='contained'
								component='label'
								sx={{
									
								}}
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'area') : false}
								>
									{(loading && currentGenerationFieldKey === 'area') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'area') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							<TextField
								margin='normal'			
								fullWidth
								multiline
								minRows={4}
								InputLabelProps={{ shrink: true }}
								name='area'
								label='Where is it?'
								id='area'
								value={area}
								onChange={(e) => setArea(e.target.value)}
								placeholder='Where does this Setting or Location exist?'
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
								disabled={currentGenerationFieldKey === 'area' ? true : false}
							/>
						</Box>

						<Box sx={fieldContainerStyles}>
							<GenerationButton
								onClick={() =>
									!isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI(
										'unique_characteristics'
									)
								}
								variant='contained'
								component='label'
								sx={{
									
								}}
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'unique_characteristics') : false}
								>
									{(loading && currentGenerationFieldKey === 'unique_characteristics') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'unique_characteristics') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							<TextField
								margin='normal'
								fullWidth
								multiline
								minRows={4}
								InputLabelProps={{ shrink: true }}
								name='unique_characteristics'
								label='What is unique about it?'
								id='unique_characteristics'
								value={unique_characteristics}
								onChange={(e) =>
									setUnique_characteristics(e.target.value)
								}
								placeholder="List some unique things about this Setting."
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
								disabled={currentGenerationFieldKey === 'unique_characteristics' ? true : false}
							/>
						</Box>

						<Box sx={fieldContainerStyles}>
							<GenerationButton
								onClick={() =>
									!isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('time_of_year')
								}
								variant='contained'
								component='label'
								sx={{
									
								}}
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'time_of_year') : false}
								>
									{(loading && currentGenerationFieldKey === 'time_of_year') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'time_of_year') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							<TextField
								margin='normal'
								fullWidth
								name='time_of_year'
								label='What time, year, or season is it?'
								id='time_of_year'
								placeholder='For example: 1990s or Christmas time'
								InputLabelProps={{ shrink: true }}
								value={time_of_year}
								onChange={(e) =>
									setTime_of_year(e.target.value)
								}
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
								disabled={currentGenerationFieldKey === 'time_of_year' ? true : false}
							/>
						</Box>

						<Box sx={fieldContainerStyles}>
							<GenerationButton
								onClick={() =>
									!isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('atmosphere')
								}
								variant='contained'
								component='label'
								sx={{
									
								}}
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'atmosphere') : false}
								>
									{(loading && currentGenerationFieldKey === 'atmosphere') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'atmosphere') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							<TextField
								margin='normal'
								fullWidth
								multiline
								minRows={4}
								InputLabelProps={{ shrink: true }}
								name='atmosphere'
								label='Is there a mood or atmosphere about this specific setting?'
								id='atmosphere'
								value={atmosphere}
								onChange={(e) => setAtmosphere(e.target.value)}
								placeholder='This can help to control tone and style in your project.'
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
								disabled={currentGenerationFieldKey === 'atmosphere' ? true : false}
							/>
						</Box>
						<Box sx={fieldContainerStyles}>
							<GenerationButton
								onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('climate')}
								variant='contained'
								component='label'
								sx={{
									
								}}
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'climate') : false}
								>
									{(loading && currentGenerationFieldKey === 'climate') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'climate') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							<TextField
								margin='normal'
								fullWidth
								multiline
								minRows={4}
								InputLabelProps={{ shrink: true }}
								name='climate'
								label='Does the climate play a role in the story?'
								id='climate'
								value={climate}
								onChange={(e) => setClimate(e.target.value)}
								placeholder='For example, are your characters snowed in?'
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
								disabled={currentGenerationFieldKey === 'climate' ? true : false}
							/>
						</Box>
					</Box>
					<div
						style={{
							display: 'flex',
							'justify-content': 'flex-end',
							position: 'fixed',
							bottom: '0',
							right: '0',
							width: '100%',
							paddingRight: '30px',
							'background-color': '#2f3944',
							'z-index': '1',
						}}
					>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{
								mt: 1,
								mb: 1,
								color: '#fff',
								width: 'fit-content',
								'align-self': 'self-end',
								marginRight: '10px'
							}}
							disabled={!isGenerationButtonAllowed}
						>
							Save
						</Button>
						<Button
							fullWidth
							variant='contained'
							sx={{
								mt: 1,
								mb: 1,
								color: '#fff',
								width: 'fit-content',
								'align-self': 'self-end',
								marginRight: '30px'
							}}
							onClick={() =>
								handleExportPdf()
							}
						>
								Export PDF
						</Button>
					</div>
				</>
		</Box>
	);
}
