import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingFlags: []
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    removeLoadingFlag: (state, action) => {      
      const newArr = [...state.loadingFlags]
      newArr.filter(flag => flag !== action.payload)
      if (newArr.length === 0) state.loading = false
    },
    addLoadingFlag: (state, action) => {      
      const newArr = [...state.loadingFlags]
      newArr.push(action.payload)
      if (!state.loading) state.loading = true
    },
  },
});

export const { addLoadingFlag,removeLoadingFlag } = loadingSlice.actions;
export default loadingSlice.reducer;
